import * as React from 'react';
function SvgArrowLeft(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <g fill='none' fillRule='evenodd'>
        <path d='M-1132-758H308V42h-1440z' />
        <path
          d='M4.5 11.097c2.793-2.257 5.167-3.98 8.611-6.237l.559.814c-2.7 1.723-4.771 3.166-7.61 5.33H20v1H6.106c2.84 2.165 4.91 3.608 7.61 5.33l-.605.815c-3.444-2.258-5.818-3.98-8.611-6.237v-.815z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
export default SvgArrowLeft;
