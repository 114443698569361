import { ApiCore } from '../_utilities/core';

const models = new ApiCore({
  url: '/vehicle/models',
});

const model = new ApiCore({
  url: '/vehicle/models/:id',
});

const modelNames = new ApiCore({
  url: '/vehicle/models/names',
});

const modelVersions = new ApiCore({
  url: '/vehicle/models/versions',
});

const modelDetails = new ApiCore({
  url: '/vehicle/models/:code/:versionCode',
});

const modelDetailsCreate = new ApiCore({
  url: '/vehicle/models/init',
});

const modelsAllNames = new ApiCore({
  url: '/vehicle/models/all-names',
});

export default {
  getModels: (queryString) => models.query({ queryString }).read(),
  getModel: (id) => model.query({ urlParams: { id } }).read(),
  createModel: (data) => models.create(data),
  updateModel: (id, data) => model.query({ urlParams: { id } }).update(data),
  deleteModel: (id) => model.query({ urlParams: { id } }).delete(),
  getNames: (queryString) => modelNames.query({ queryString }).read(),
  getAllNames: () => modelsAllNames.query().read(),
  getVersions: (queryString) => modelVersions.query({ queryString }).read(),
  getModelCsbNames: (code, versionCode) =>
    modelDetails.query({ urlParams: { code, versionCode } }).read(),
  createModelCsbNames: (data) => modelDetailsCreate.create(data),
};
