import { ApiCore } from '../_utilities/core';

export default {
  getCurrentUserViews: () =>
    new ApiCore({
      url: '/auth/views',
    })
      .query()
      .read(),
  createView: (data) =>
    new ApiCore({
      url: '/auth/views',
    }).create(data),
  switchView: (id) =>
    new ApiCore({
      url: '/auth/views/:id',
    })
      .query({ urlParams: { id } })
      .create(),
  deleteView: (id) =>
    new ApiCore({
      url: '/auth/views/:id',
    })
      .query({ urlParams: { id } })
      .delete(),
};
