import * as React from 'react';
function SvgSearch(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M10.456 5.008c-2.952 0-5.448 2.184-5.448 5.353 0 3.216 2.52 5.64 5.544 5.64 2.977 0 5.449-2.208 5.449-5.352 0-3.24-2.496-5.64-5.545-5.64m6.553 5.616c0 1.608-.552 3-1.464 4.104l4.68 4.68-.696.72-4.704-4.704c-1.152 1.008-2.664 1.584-4.297 1.584C6.976 17.01 4 14.13 4 10.385 4 6.688 6.976 4 10.48 4c3.625 0 6.529 2.809 6.529 6.625'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgSearch;
