import * as actionsWithTypes from '../actions';

const initialState = {
  userId: null,
  userFullName: null,
  userName: null,
  profileName: null,
  permissions: null,
};

export function userDataReducer(state = initialState, action) {
  switch (action.type) {
    case actionsWithTypes.REFRESH_TOKEN_SUCCESS:
    case actionsWithTypes.LOGIN_SUCCESS: {
      if (!action.payload.tokenData) {
        return state;
      }
      const {
        userName,
        userId,
        userFullName,
        profileName,
        permission: permissions,
      } = action.payload.tokenData;
      return {
        ...state,
        userId,
        userName,
        userFullName,
        profileName,
        permissions,
        tokenData: action.payload.tokenData,
      };
    }
    case actionsWithTypes.LOGIN_ERROR:
    case actionsWithTypes.LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
