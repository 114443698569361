import * as React from 'react';
import { toast } from 'react-toastify';

import ToastUiComponent from '../components/shared/toast-ui/toast-ui';

const giveMeToast = (message = '', type = 'info', options) =>
  toast(
    ({ closeToast }) => (
      <ToastUiComponent message={message} type={type} onClose={closeToast} />
    ),
    {
      toastId: message,
      ...options,
    }
  );

export const ToastUi = {
  success: (message, options) => giveMeToast(message, 'success', options),
  error: (message, options) => giveMeToast(message, 'error', options),
  warning: (message, options) => giveMeToast(message, 'warning', options),
  info: (message, options) => giveMeToast(message, 'info', options),
};
