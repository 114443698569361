import * as React from 'react';
function SvgSettings(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M12.012 14C13.266 14 14 13.275 14 11.988 14 10.725 13.266 10 12.012 10 10.757 10 10 10.725 10 11.988c0 1.287.757 2.012 2.012 2.012m0-5.002c1.888 0 2.988 1.1 2.988 2.987 0 1.889-1.1 3.013-2.988 3.013S9 13.874 9 11.985c0-1.888 1.124-2.987 3.012-2.987m2.65 9.715a13.301 13.301 0 001.896-1.152c-.168-.72-.217-1.103-.217-1.51 0-1.368.697-2.448 2.664-2.903V10.82c-1.967-.48-2.664-1.512-2.664-2.879 0-.408.049-.816.217-1.511-.672-.48-1.223-.815-1.92-1.127-.959.983-1.703 1.295-2.638 1.295-.96 0-1.679-.312-2.638-1.32-.697.337-1.248.648-1.92 1.152.169.695.217 1.103.217 1.511 0 1.367-.697 2.399-2.663 2.879v2.327c1.966.455 2.663 1.535 2.663 2.902 0 .408-.048.792-.217 1.511.672.48 1.223.816 1.896 1.152.959-1.008 1.703-1.32 2.662-1.32.96 0 1.703.312 2.662 1.32M14.207 4.44l.288-.36a19.615 19.615 0 013.214 1.919l-.12.456c-.168.552-.264 1.056-.264 1.487 0 1.056.624 1.655 2.16 2.063l.48.144c.047 1.32.047 2.4 0 3.694l-.48.144c-1.536.408-2.16 1.008-2.16 2.063 0 .432.096.936.265 1.511l.12.432a19.651 19.651 0 01-3.215 1.92l-.288-.336c-.696-.816-1.367-1.224-2.207-1.224-.84 0-1.511.408-2.207 1.224l-.288.335a19.694 19.694 0 01-3.214-1.919l.12-.432c.168-.575.264-1.08.264-1.51 0-1.056-.624-1.656-2.16-2.064l-.48-.144a47.92 47.92 0 010-3.694l.48-.144c1.536-.408 2.16-1.007 2.16-2.063 0-.431-.096-.935-.264-1.487l-.12-.456A19.657 19.657 0 019.505 4.08l.288.36c.696.791 1.367 1.2 2.207 1.2.84 0 1.511-.409 2.207-1.2'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgSettings;
