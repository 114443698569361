import { createBrowserHistory } from 'history';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import join from 'lodash/join';
import noop from 'lodash/noop';

import { ToastUi } from './toast-ui';

/**
 * * Service for handling page errors
 * @param err object returned from .catch
 * @param action [default noop] action to be performed (e.g this.setState), receives errMsg as parameter (action(errMsg, data)), 'data' can be null!
 * @param toast [default true] whether Toast.error should appear */

export default async function ErrorHandler(err, action = noop, toast = true) {
  const h = createBrowserHistory();
  let errMsg = '';

  if (
    !isEqual(h.location.pathname, '/login') &&
    !isEqual(h.location.pathname, '/logout') &&
    isEqual(err?.response?.status, 401)
  ) {
    h.push({
      pathname: '/logout',
      search: `?redirectUrl=${h.location.pathname}`,
    });
    h.go(0);
  }

  let data = null;
  if (isArray(err?.errors)) {
    errMsg = join(err.errors, '. ');
    console.error('YUP:', errMsg);
  } else if (isObject(err?.response?.data)) {
    const { status } = err.response;
    data = err.response.data;

    if (data instanceof Blob) {
      try {
        // fix for: files.api.js getCsbImg() 404 error, it returns JSON in Blob
        const blob = new Blob([data], { type: data.type });
        data = JSON.parse(await blob.text());
      } catch (ex) {
        if (ex instanceof SyntaxError) {
          // fix for: files.api.js handle case when Blob is empty (size: 0), standard 404 error
          data = {
            code: status,
            message: `${status} - ${err?.message}`,
          };
        }
      }
    }

    errMsg = data.message;
    console.error(
      `API CODE: '${data.code}', MESSAGE: '${data.message}', STATUS: ${status}`
    );
  } else {
    const { status } = err;
    errMsg = err?.response?.data || err?.message || err;
    console.error(`API CODE '${status}', MESSAGE: '${errMsg}'`);
    data = err?.response?.data;
  }

  if (toast) ToastUi.error(errMsg);

  action(errMsg, data);

  return undefined; // previously there was return err; but I'm not sure if it is used anywere at all. (I couldn't find the place)
  // I changed it to return undefined; because err contains 'name' property
  // and 'name' field in model.form.js and organisation.form.js was highlighted if there was some error (for ex.: 422)
  // returned from BackEnd side. The error didn't have to be connected with 'name' field!
}
