import * as React from 'react';
function SvgChevronRight(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M7 17.335a76.933 76.933 0 008.076-5.819C12.283 9.212 9.979 7.56 7 5.675l.559-.815c3.235 2.118 5.632 3.84 8.611 6.237v.815c-2.979 2.398-5.376 4.12-8.611 6.238L7 17.335z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgChevronRight;
