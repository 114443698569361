import * as React from 'react';
function SvgUnlock(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M11.994 12.529c-.698 0-.987.36-.987.937 0 .648.29.96.987.96.626 0 .988-.312.988-.96 0-.577-.362-.937-.988-.937zm0 3.891v-1.009h-.024c-1.227 0-1.998-.744-1.998-1.945 0-1.177.77-1.922 1.998-1.922 1.204 0 1.999.745 1.999 1.922 0 .816-.361 1.417-.987 1.73v2.21h-2.168v-.986h1.18zM8.118 9.982c-.746 0-1.13.264-1.13 1.177v7.832h10.014V11.16c0-.913-.386-1.177-1.132-1.177H8.118zm9.895 1.01V20H6v-8.865c0-1.273.433-2.161 2.142-2.161h4.84V7.027C12.982 5.106 14.065 4 15.918 4 17.796 4 19 4.985 19 6.931v.336h-1.011V7.1c0-1.441-.867-2.114-2.07-2.114-1.18 0-1.927.697-1.927 2.114v1.875h1.854c1.469 0 2.167.744 2.167 2.017z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgUnlock;
