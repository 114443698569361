import { routesMap } from '../routes-map';

export const routing = [
  {
    path: '/auth/organisations',
    component: routesMap.AuthOrganisationsPage,
    allowedPermissions: ['[AUTH.ORG:Read]'],
    routes: [
      {
        path: '/auth/organisations/create-copy-of/:copyId',
        component: routesMap.AuthOrganisationsCreatePage,
        allowedPermissions: ['[AUTH.ORG:Change]'],
      },
      {
        path: '/auth/organisations/create',
        component: routesMap.AuthOrganisationsCreatePage,
        allowedPermissions: ['[AUTH.ORG:Change]'],
      },
      {
        path: '/auth/organisations/update/:id',
        component: routesMap.AuthOrganisationsUpdatePage,
        allowedPermissions: ['[AUTH.ORG:Change]', '[AUTH.ORG:Delete]'],
      },
    ],
  },
  {
    path: '/auth/profiles',
    component: routesMap.AuthProfilesPage,
    allowedPermissions: ['[AUTH.PRO:Read]'],
    routes: [
      {
        path: '/auth/profiles/create',
        component: routesMap.AuthProfilesCreatePage,
        allowedPermissions: ['[AUTH.PRO:Change]'],
      },
      {
        path: '/auth/profiles/update/:id',
        component: routesMap.AuthProfilesUpdatePage,
        allowedPermissions: ['[AUTH.PRO:Change]', '[AUTH.PRO:Delete]'],
      },
    ],
  },
  {
    path: '/auth/users',
    component: routesMap.AuthUsersPage,
    allowedPermissions: ['[AUTH.USR:Read]'],
    routes: [
      {
        path: '/auth/users/create',
        component: routesMap.AuthUsersCreatePage,
        allowedPermissions: ['[AUTH.USR:Change]'],
      },
      {
        path: '/auth/users/update/:id',
        component: routesMap.AuthUsersUpdatePage,
        allowedPermissions: ['[AUTH.USR:Change]', '[AUTH.USR:Delete]'],
      },
    ],
  },
  {
    path: '/auth/off-days',
    component: routesMap.AuthOffDaysPage,
    allowedPermissions: ['[AUTH.OFD:Read]'],
    routes: [
      {
        path: '/auth/off-days/create-copy-of/:copyId',
        component: routesMap.AuthOffDaysCreatePage,
        allowedPermissions: ['[AUTH.OFD:Change]'],
      },
      {
        path: '/auth/off-days/create',
        component: routesMap.AuthOffDaysCreatePage,
        allowedPermissions: ['[AUTH.OFD:Change]'],
      },
      {
        path: '/auth/off-days/update/:id',
        component: routesMap.AuthOffDaysUpdatePage,
        allowedPermissions: ['[AUTH.OFD:Change]', '[AUTH.OFD:Delete]'],
      },
    ],
  },
  {
    path: '/inspection/vehicle-inspections',
    component: routesMap.InspectionVehicleInspectionsPage,
    allowedPermissions: ['[INSPECTION.VEI:Read]'],
    routes: [
      {
        path: '/inspection/vehicle-inspections/update/:idList',
        component: routesMap.InspectionVehicleInspectionsUpdatePage,
        allowedPermissions: [
          '[INSPECTION.VEI:Change]',
          '[INSPECTION.VEI:Delete]',
        ],
      },
    ],
  },
  {
    path: '/inspection/services',
    component: routesMap.InspectionServicesPage,
    allowedPermissions: ['[INSPECTION.SRV:Read]'],
    routes: [
      {
        path: '/inspection/services/create-copy-of/:copyId',
        component: routesMap.InspectionServicesCreatePage,
        allowedPermissions: ['[INSPECTION.SRV:Change]'],
      },
      {
        path: '/inspection/services/create',
        component: routesMap.InspectionServicesCreatePage,
        allowedPermissions: ['[INSPECTION.SRV:Change]'],
      },
      {
        path: '/inspection/services/update/:id',
        component: routesMap.InspectionServicesUpdatePage,
        allowedPermissions: [
          '[INSPECTION.SRV:Change]',
          '[INSPECTION.SRV:Delete]',
        ],
      },
    ],
  },
  {
    path: '/registration/statement-buybackeds',
    component: routesMap.RegistrationStatementBuybackedsPage,
    allowedPermissions: ['[REGISTRATION.STA:Read]'],
    routes: [],
  },
  {
    path: '/registration/statement-resolds',
    component: routesMap.RegistrationStatementResoldsPage,
    allowedPermissions: ['[REGISTRATION.STR:Read]'],
    routes: [],
  },
  {
    path: '/registration/registration-offices',
    component: routesMap.RegistrationRegistrationOfficesPage,
    allowedPermissions: ['[REGISTRATION.OFF:Read]'],
    routes: [
      {
        path: '/registration/registration-offices/create-copy-of/:copyId',
        component: routesMap.RegistrationRegistrationOfficesCreatePage,
        allowedPermissions: ['[REGISTRATION.OFF:Change]'],
      },
      {
        path: '/registration/registration-offices/create',
        component: routesMap.RegistrationRegistrationOfficesCreatePage,
        allowedPermissions: ['[REGISTRATION.OFF:Change]'],
      },
      {
        path: '/registration/registration-offices/update/:id',
        component: routesMap.RegistrationRegistrationOfficesUpdatePage,
        allowedPermissions: [
          '[REGISTRATION.OFF:Change]',
          '[REGISTRATION.OFF:Delete]',
        ],
      },
    ],
  },
  {
    path: '/offer/organisation-groups',
    component: routesMap.OfferOrganisationGroupsPage,
    allowedPermissions: ['[OFFER.OGR:Read]'],
    routes: [
      {
        path: '/offer/organisation-groups/create',
        component: routesMap.OfferOrganisationGroupsCreatePage,
        allowedPermissions: ['[OFFER.OGR:Change]'],
      },
      {
        path: '/offer/organisation-groups/update/:id',
        component: routesMap.OfferOrganisationGroupsUpdatePage,
        allowedPermissions: ['[OFFER.OGR:Change]', '[OFFER.OGR:Delete]'],
      },
    ],
  },
  {
    path: '/offer/sales-actions',
    component: routesMap.OfferSalesActionsPage,
    allowedPermissions: ['[OFFER.ACT:Read]'],
    routes: [
      {
        path: '/offer/sales-actions/create',
        component: routesMap.OfferSalesActionsCreatePage,
        allowedPermissions: ['[OFFER.ACT:Change]'],
      },
      {
        path: '/offer/sales-actions/update/:id',
        component: routesMap.OfferSalesActionsUpdatePage,
        allowedPermissions: ['[OFFER.ACT:Change]'],
      },
    ],
  },
  {
    path: '/offer/vehicle-packages',
    component: routesMap.OfferVehiclePackagesPage,
    allowedPermissions: ['[OFFER.PAC:Read]'],
    routes: [
      {
        path: '/offer/vehicle-packages/update/:id',
        component: routesMap.OfferVehiclePackagesUpdatePage,
        allowedPermissions: ['[OFFER.PAC:Change]'],
      },
    ],
  },
  {
    path: '/login',
    exact: true,
    component: routesMap.LoginPage,
    allowedPermissions: [],
    noLoginRequired: true,
  },
  {
    path: '/logout',
    exact: true,
    component: routesMap.LogoutPage,
    allowedPermissions: [],
  },
  {
    path: '/logout-purposely',
    exact: true,
    component: routesMap.LogoutPurposelyPage,
    allowedPermissions: [],
  },
  {
    path: '/settings',
    exact: true,
    component: routesMap.SettingsUpdatePage,
    allowedPermissions: [],
  },
  {
    path: '/no-access',
    component: routesMap.NoAccessPage,
    allowedPermissions: [],
    noLoginRequired: true,
  },
  {
    path: '/forgot-password',
    exact: true,
    component: routesMap.ForgotPasswordPage,
    allowedPermissions: [],
    noLoginRequired: true,
  },
  {
    path: '/reset-password/:key',
    exact: true,
    component: routesMap.ResetPasswordPage,
    allowedPermissions: [],
    noLoginRequired: true,
  },
  {
    path: '/auth/test',
    component: routesMap.TestPerformancePage,
    allowedPermissions: ['[PERF:Read]'],
    routes: [
      {
        path: '/auth/test/create',
        component: routesMap.TestPerformanceCreatePage,
        allowedPermissions: [],
      },
    ],
  },
  {
    path: '/vehicle/active-vehicles',
    component: routesMap.VehicleActiveVehiclesPage,
    allowedPermissions: ['[VEHICLE.ACT:Read]'],
    routes: [
      {
        path: '/vehicle/active-vehicles/create-copy-of/:copyId',
        component: routesMap.VehicleActiveVehiclesCreatePage,
        allowedPermissions: ['[VEHICLE.ACT:Change]'],
      },
      {
        path: '/vehicle/active-vehicles/create',
        component: routesMap.VehicleActiveVehiclesCreatePage,
        allowedPermissions: ['[VEHICLE.ACT:Change]'],
      },
      {
        path: '/vehicle/active-vehicles/update/:id',
        component: routesMap.VehicleActiveVehiclesUpdatePage,
        allowedPermissions: ['[VEHICLE.ACT:Change]', '[VEHICLE.ACT:Delete]'],
      },
      {
        path: '/vehicle/active-vehicles/group-edit/:idList',
        component: routesMap.VehicleActiveVehiclesGroupEditPage,
        allowedPermissions: ['[VEHICLE.ACT:Change]', '[VEHICLE.ACT:Delete]'],
      },
    ],
  },
  {
    path: '/vehicle/additional-equipments',
    component: routesMap.VehicleAdditionalEquipmentsPage,
    allowedPermissions: ['[VEHICLE.AEQ:Read]'],
    routes: [
      {
        path: '/vehicle/additional-equipments/create-copy-of/:copyId',
        component: routesMap.VehicleAdditionalEquipmentsCreatePage,
        allowedPermissions: ['[VEHICLE.AEQ:Change]'],
      },
      {
        path: '/vehicle/additional-equipments/create',
        component: routesMap.VehicleAdditionalEquipmentsCreatePage,
        allowedPermissions: ['[VEHICLE.AEQ:Change]'],
      },
      {
        path: '/vehicle/additional-equipments/update/:id',
        component: routesMap.VehicleAdditionalEquipmentsUpdatePage,
        allowedPermissions: ['[VEHICLE.AEQ:Change]', '[VEHICLE.AEQ:Delete]'],
      },
    ],
  },
  {
    path: '/vehicle/additional-costs',
    component: routesMap.VehicleAdditionalCostsPage,
    allowedPermissions: ['[VEHICLE.ACO:Read]'],
    routes: [
      {
        path: '/vehicle/additional-costs/create-copy-of/:copyId',
        component: routesMap.VehicleAdditionalCostsCreatePage,
        allowedPermissions: ['[VEHICLE.ACO:Change]'],
      },
      {
        path: '/vehicle/additional-costs/create',
        component: routesMap.VehicleAdditionalCostsCreatePage,
        allowedPermissions: ['[VEHICLE.ACO:Change]'],
      },
      {
        path: '/vehicle/additional-costs/update/:id',
        component: routesMap.VehicleAdditionalCostsUpdatePage,
        allowedPermissions: ['[VEHICLE.ACO:Change]', '[VEHICLE.ACO:Delete]'],
      },
    ],
  },
  {
    path: '/vehicle/common-contents',
    component: routesMap.VehicleCommonContentsPage,
    allowedPermissions: ['[VEHICLE.CCT:Read]'],
    routes: [
      {
        path: '/vehicle/common-contents/create',
        component: routesMap.VehicleCommonContentsCreatePage,
        allowedPermissions: ['[VEHICLE.CCT:Change]'],
      },
      {
        path: '/vehicle/common-contents/update/:id',
        component: routesMap.VehicleCommonContentsUpdatePage,
        allowedPermissions: ['[VEHICLE.CCT:Change]', '[VEHICLE.CCT:Delete]'],
      },
    ],
  },
  {
    path: '/vehicle/depreciations',
    component: routesMap.VehicleDepreciationsPage,
    allowedPermissions: ['[VEHICLE.DEP:Read]'],
    routes: [
      {
        path: '/vehicle/depreciations/create-copy-of/:copyId',
        component: routesMap.VehicleDepreciationsCreatePage,
        allowedPermissions: ['[VEHICLE.DEP:Change]'],
      },
      {
        path: '/vehicle/depreciations/create',
        component: routesMap.VehicleDepreciationsCreatePage,
        allowedPermissions: ['[VEHICLE.DEP:Change]'],
      },
      {
        path: '/vehicle/depreciations/update/:id',
        component: routesMap.VehicleDepreciationsUpdatePage,
        allowedPermissions: ['[VEHICLE.DEP:Change]', '[VEHICLE.DEP:Delete]'],
      },
    ],
  },
  {
    path: '/vehicle/designed-vehicles',
    component: routesMap.VehicleDesignedVehiclesPage,
    allowedPermissions: ['[VEHICLE.DSG:Read]'],
    routes: [
      {
        path: '/vehicle/designed-vehicles/create-copy-of/:copyId',
        component: routesMap.VehicleDesignedVehiclesCreatePage,
        allowedPermissions: ['[VEHICLE.DSG:Change]'],
      },
      {
        path: '/vehicle/designed-vehicles/create',
        component: routesMap.VehicleDesignedVehiclesCreatePage,
        allowedPermissions: ['[VEHICLE.DSG:Change]'],
      },
      {
        path: '/vehicle/designed-vehicles/update/:id',
        component: routesMap.VehicleDesignedVehiclesUpdatePage,
        allowedPermissions: ['[VEHICLE.DSG:Change]', '[VEHICLE.DSG:Delete]'],
      },
    ],
  },
  {
    path: '/vehicle/models',
    component: routesMap.VehicleModelsPage,
    allowedPermissions: ['[VEHICLE.MOD:Read]'],
    routes: [
      {
        path: '/vehicle/models/create-copy-of/:copyId',
        component: routesMap.VehicleModelsCreatePage,
        allowedPermissions: ['[VEHICLE.MOD:Change]'],
      },
      {
        path: '/vehicle/models/create',
        component: routesMap.VehicleModelsCreatePage,
        allowedPermissions: ['[VEHICLE.MOD:Change]'],
      },
      {
        path: '/vehicle/models/update/:id',
        component: routesMap.VehicleModelsUpdatePage,
        allowedPermissions: ['[VEHICLE.MOD:Change]', '[VEHICLE.MOD:Delete]'],
      },
    ],
  },
  {
    path: '/vehicle/offers',
    component: routesMap.VehicleOffersPage,
    allowedPermissions: ['[VEHICLE.OFF:Read]'],
    routes: [
      {
        path: '/vehicle/offers/create-copy-of/:copyId',
        component: routesMap.VehicleOffersCreatePage,
        allowedPermissions: ['[VEHICLE.OFF:Change]'],
      },
      {
        path: '/vehicle/offers/create/:offerType?',
        component: routesMap.VehicleOffersCreatePage,
        allowedPermissions: ['[VEHICLE.OFF:Change]'],
      },
      {
        path: '/vehicle/offers/update/:id',
        component: routesMap.VehicleOffersUpdatePage,
        allowedPermissions: ['[VEHICLE.OFF:Change]', '[VEHICLE.OFF:Delete]'],
      },
    ],
  },
  {
    path: '/vehicle/segments',
    component: routesMap.VehicleSegmentsPage,
    allowedPermissions: ['[VEHICLE.SEG:Read]'],
    routes: [
      {
        path: '/vehicle/segments/create-copy-of/:copyId',
        component: routesMap.VehicleSegmentsCreatePage,
        allowedPermissions: ['[VEHICLE.SEG:Change]'],
      },
      {
        path: '/vehicle/segments/create',
        component: routesMap.VehicleSegmentsCreatePage,
        allowedPermissions: ['[VEHICLE.SEG:Change]'],
      },
      {
        path: '/vehicle/segments/update/:id',
        component: routesMap.VehicleSegmentsUpdatePage,
        allowedPermissions: ['[VEHICLE.SEG:Change]', '[VEHICLE.SEG:Delete]'],
      },
    ],
  },
  {
    path: '/expertise/parameters',
    component: routesMap.ExpertiseParametersPage,
    allowedPermissions: ['[EXPERTISE.PAR:Read]'],
    routes: [
      {
        path: '/expertise/parameters/create',
        component: routesMap.ExpertiseParametersCreatePage,
        allowedPermissions: ['[EXPERTISE.PAR:Change]'],
      },
      {
        path: '/expertise/parameters/update/:id',
        component: routesMap.ExpertiseParametersUpdatePage,
        allowedPermissions: [
          '[EXPERTISE.PAR:Change]',
          '[EXPERTISE.PAR:Delete]',
        ],
      },
    ],
  },
  {
    path: '/expertise/damages',
    component: routesMap.ExpertiseDamagesPage,
    allowedPermissions: ['[EXPERTISE.DAM:Read]'],
    routes: [
      {
        path: '/expertise/damages/create',
        component: routesMap.ExpertiseDamagesCreatePage,
        allowedPermissions: ['[EXPERTISE.DAM:Change]'],
      },
      {
        path: '/expertise/damages/update/:id',
        component: routesMap.ExpertiseDamagesUpdatePage,
        allowedPermissions: [
          '[EXPERTISE.DAM:Change]',
          '[EXPERTISE.DAM:Delete]',
        ],
      },
    ],
  },
  {
    path: '/expertise/price-lists',
    component: routesMap.ExpertisePriceListsPage,
    allowedPermissions: ['[EXPERTISE.PRL:Read]'],
    routes: [
      {
        path: '/expertise/price-lists/create',
        component: routesMap.ExpertisePriceListsCreatePage,
        allowedPermissions: ['[EXPERTISE.PRL:Change]'],
      },
      {
        path: '/expertise/price-lists/update/:id',
        component: routesMap.ExpertisePriceListsUpdatePage,
        allowedPermissions: [
          '[EXPERTISE.PRL:Change]',
          '[EXPERTISE.PRL:Delete]',
        ],
      },
    ],
  },
  {
    path: '/expertise/repairs',
    component: routesMap.ExpertiseRepairsPage,
    allowedPermissions: ['[EXPERTISE.REP:Read]'],
    routes: [
      {
        path: '/expertise/repairs/create',
        component: routesMap.ExpertiseRepairsCreatePage,
        allowedPermissions: ['[EXPERTISE.REP:Change]'],
      },
      {
        path: '/expertise/repairs/update/:id',
        component: routesMap.ExpertiseRepairsUpdatePage,
        allowedPermissions: [
          '[EXPERTISE.REP:Change]',
          '[EXPERTISE.REP:Delete]',
        ],
      },
    ],
  },
  {
    path: '/expertise/vehicle-expertises',
    component: routesMap.ExpertiseVehicleExpertisesPage,
    allowedPermissions: ['[EXPERTISE.EXT:Read]'],
    routes: [
      {
        path: '/expertise/vehicle-expertises/take/:id',
        component: routesMap.ExpertiseVehicleExpertisesTakeCreatePage,
        allowedPermissions: ['[EXPERTISE.EXT:Change]'],
      },
      {
        path: '/expertise/vehicle-expertises/update/:id',
        component: routesMap.ExpertiseVehicleExpertisesUpdatePage,
        allowedPermissions: ['[EXPERTISE.EXT:Change]'],
      },
    ],
  },
  {
    path: '/finance/tax-rates',
    component: routesMap.FinanceTaxRatesPage,
    allowedPermissions: ['[FINANCE.TXR:Read]'],
    routes: [
      {
        path: '/finance/tax-rates/create-copy-of/:copyId',
        component: routesMap.FinanceTaxRatesCreatePage,
        allowedPermissions: ['[FINANCE.TXR:Change]'],
      },
      {
        path: '/finance/tax-rates/create',
        component: routesMap.FinanceTaxRatesCreatePage,
        allowedPermissions: ['[FINANCE.TXR:Change]'],
      },
      {
        path: '/finance/tax-rates/update/:id',
        component: routesMap.FinanceTaxRatesUpdatePage,
        allowedPermissions: ['[FINANCE.TXR:Change]', '[FINANCE.TXR:Delete]'],
      },
    ],
  },
  {
    path: '/finance/payment-methods',
    component: routesMap.FinancePaymentMethodsPage,
    allowedPermissions: ['[FINANCE.PME:Read]'],
    routes: [
      {
        path: '/finance/payment-methods/create-copy-of/:copyId',
        component: routesMap.FinancePaymentMethodsCreatePage,
        allowedPermissions: ['[FINANCE.PME:Change]'],
      },
      {
        path: '/finance/payment-methods/create',
        component: routesMap.FinancePaymentMethodsCreatePage,
        allowedPermissions: ['[FINANCE.PME:Change]'],
      },
      {
        path: '/finance/payment-methods/update/:id',
        component: routesMap.FinancePaymentMethodsUpdatePage,
        allowedPermissions: ['[FINANCE.PME:Change]', '[FINANCE.PME:Delete]'],
      },
    ],
  },
  {
    path: '/finance/payment-terms',
    component: routesMap.FinancePaymentTermsPage,
    allowedPermissions: ['[FINANCE.PTR:Read]'],
    routes: [
      {
        path: '/finance/payment-terms/create-copy-of/:copyId',
        component: routesMap.FinancePaymentTermsCreatePage,
        allowedPermissions: ['[FINANCE.PTR:Change]'],
      },
      {
        path: '/finance/payment-terms/create',
        component: routesMap.FinancePaymentTermsCreatePage,
        allowedPermissions: ['[FINANCE.PTR:Change]'],
      },
      {
        path: '/finance/payment-terms/update/:id',
        component: routesMap.FinancePaymentTermsUpdatePage,
        allowedPermissions: ['[FINANCE.PTR:Change]', '[FINANCE.PTR:Delete]'],
      },
    ],
  },
  {
    path: '/finance/currencies',
    component: routesMap.FinanceCurrenciesPage,
    allowedPermissions: ['[FINANCE.CUR:Read]'],
    routes: [
      {
        path: '/finance/currencies/create-copy-of/:copyId',
        component: routesMap.FinanceCurrenciesCreatePage,
        allowedPermissions: ['[FINANCE.CUR:Change]'],
      },
      {
        path: '/finance/currencies/create',
        component: routesMap.FinanceCurrenciesCreatePage,
        allowedPermissions: ['[FINANCE.CUR:Change]'],
      },
      {
        path: '/finance/currencies/update/:id',
        component: routesMap.FinanceCurrenciesUpdatePage,
        allowedPermissions: ['[FINANCE.CUR:Change]', '[FINANCE.CUR:Delete]'],
      },
    ],
  },
  {
    path: '/finance/currency-rates',
    component: routesMap.FinanceCurrencyRatesPage,
    allowedPermissions: ['[FINANCE.CRT:Read]'],
    routes: [
      {
        path: '/finance/currency-rates/create-copy-of/:copyId',
        component: routesMap.FinanceCurrencyRatesCreatePage,
        allowedPermissions: ['[FINANCE.CRT:Change]'],
      },
      {
        path: '/finance/currency-rates/create',
        component: routesMap.FinanceCurrencyRatesCreatePage,
        allowedPermissions: ['[FINANCE.CRT:Change]'],
      },
      {
        path: '/finance/currency-rates/update/:id',
        component: routesMap.FinanceCurrencyRatesUpdatePage,
        allowedPermissions: ['[FINANCE.CRT:Change]', '[FINANCE.CRT:Delete]'],
      },
    ],
  },
  {
    path: '/finance/numbering-schemas',
    component: routesMap.FinanceNumberingSchemasPage,
    allowedPermissions: ['[FINANCE.NSM:Read]'],
    routes: [
      {
        path: '/finance/numbering-schemas/create-copy-of/:copyId',
        component: routesMap.FinanceNumberingSchemasCreatePage,
        allowedPermissions: ['[FINANCE.NSM:Change]'],
      },
      {
        path: '/finance/numbering-schemas/create',
        component: routesMap.FinanceNumberingSchemasCreatePage,
        allowedPermissions: ['[FINANCE.NSM:Change]'],
      },
      {
        path: '/finance/numbering-schemas/update/:id',
        component: routesMap.FinanceNumberingSchemasUpdatePage,
        allowedPermissions: ['[FINANCE.NSM:Change]', '[FINANCE.NSM:Delete]'],
      },
    ],
  },
  {
    path: '/finance/document-templates',
    component: routesMap.FinanceDocumentTemplatesPage,
    allowedPermissions: ['[FINANCE.DTE:Read]'],
    routes: [
      {
        path: '/finance/document-templates/create-copy-of/:copyId',
        component: routesMap.FinanceDocumentTemplatesCreatePage,
        allowedPermissions: ['[FINANCE.DTE:Change]'],
      },
      {
        path: '/finance/document-templates/create',
        component: routesMap.FinanceDocumentTemplatesCreatePage,
        allowedPermissions: ['[FINANCE.DTE:Change]'],
      },
      {
        path: '/finance/document-templates/update/:id',
        component: routesMap.FinanceDocumentTemplatesUpdatePage,
        allowedPermissions: ['[FINANCE.DTE:Change]', '[FINANCE.DTE:Delete]'],
      },
    ],
  },
  {
    path: '/finance/documents',
    component: routesMap.FinanceDocumentsPage,
    allowedPermissions: ['[FINANCE.DOC:Read]'],
    routes: [
      {
        path: '/finance/documents/create/:vehicleId/:actionCode',
        component: routesMap.FinanceDocumentsCreatePage,
        allowedPermissions: ['[FINANCE.DOC:Change]'],
      },
      {
        path: '/finance/documents/create/:vehicleId',
        component: routesMap.FinanceDocumentsCreatePage,
        allowedPermissions: ['[FINANCE.DOC:Change]'],
      },
      {
        path: '/finance/documents/buyback/update/:id',
        component: routesMap.FinanceDocumentsBuybackUpdatePage,
        allowedPermissions: ['[FINANCE.DOC:Change]'],
      },
      {
        path: '/finance/documents/resale/update/:id',
        component: routesMap.FinanceDocumentsResaleUpdatePage,
        allowedPermissions: ['[FINANCE.DOC:Change]'],
      },
      {
        path: '/finance/documents/correction/:correctionId',
        component: routesMap.FinanceDocumentsCorrectionPage,
        allowedPermissions: ['[FINANCE.DOC:Change]'],
      },
    ],
  },
  {
    path: '/finance/settlements',
    component: routesMap.FinanceSettlementsPage,
    allowedPermissions: ['[FINANCE.STL:Read]'],
    routes: [
      {
        path: '/finance/settlements/create-copy-of/:copyId',
        component: routesMap.FinanceSettlementsCreatePage,
        allowedPermissions: ['[FINANCE.STL:Change]'],
      },
      {
        path: '/finance/settlements/create',
        component: routesMap.FinanceSettlementsCreatePage,
        allowedPermissions: ['[FINANCE.STL:Change]'],
      },
      {
        path: '/finance/settlements/update/:id',
        component: routesMap.FinanceSettlementsUpdatePage,
        allowedPermissions: ['[FINANCE.STL:Change]', '[FINANCE.STL:Delete]'],
      },
    ],
  },
  {
    path: '/finance/vehicles',
    component: routesMap.FinanceVehiclesPage,
    allowedPermissions: ['[FINANCE.VEH:Read]'],
    routes: [
      {
        path: '/finance/vehicles/update/:id',
        component: routesMap.FinanceVehiclesUpdatePage,
        allowedPermissions: ['[FINANCE.VEH:Change]'],
      },
    ],
  },
  {
    path: '/finance/products',
    component: routesMap.FinanceProductsPage,
    allowedPermissions: ['[FINANCE.PRD:Read]'],
    routes: [
      {
        path: '/finance/products/create-copy-of/:copyId',
        component: routesMap.FinanceProductsCreatePage,
        allowedPermissions: ['[FINANCE.PRD:Change]'],
      },
      {
        path: '/finance/products/create',
        component: routesMap.FinanceProductsCreatePage,
        allowedPermissions: ['[FINANCE.PRD:Change]'],
      },
      {
        path: '/finance/products/update/:id',
        component: routesMap.FinanceProductsUpdatePage,
        allowedPermissions: ['[FINANCE.PRD:Change]'],
      },
    ],
  },
  {
    path: '/insurance/policies',
    component: routesMap.InsurancePoliciesPage,
    allowedPermissions: ['[INSURANCE.POL:Read]'],
  },
  {
    path: '/insurance/insurers',
    component: routesMap.InsuranceInsurersPage,
    allowedPermissions: ['[INSURANCE.INS:Read]'],
    routes: [
      {
        path: '/insurance/insurers/create',
        component: routesMap.InsuranceInsurersCreatePage,
        allowedPermissions: ['[INSURANCE.INS:Change]'],
      },
      {
        path: '/insurance/insurers/update/:id',
        component: routesMap.InsuranceInsurersUpdatePage,
        allowedPermissions: [
          '[INSURANCE.INS:Change]',
          '[INSURANCE.INS:Delete]',
        ],
      },
    ],
  },
  {
    path: '/insurance/exclusions',
    component: routesMap.InsuranceExclusionsPage,
    allowedPermissions: ['[INSURANCE.EXC:Read]'],
    routes: [
      {
        path: '/insurance/exclusions/create',
        component: routesMap.InsuranceExclusionsCreatePage,
        allowedPermissions: ['[INSURANCE.EXC:Change]'],
      },
      {
        path: '/insurance/exclusions/update/:id',
        component: routesMap.InsuranceExclusionsUpdatePage,
        allowedPermissions: [
          '[INSURANCE.EXC:Change]',
          '[INSURANCE.EXC:Delete]',
        ],
      },
    ],
  },
  {
    path: '/interop/invoice-imports',
    component: routesMap.InteropInvoiceImportsPage,
    allowedPermissions: ['[INTEROP.IIM:Read]'],
    routes: [
      {
        path: '/interop/invoice-imports/create',
        component: routesMap.InteropInvoiceImportsCreatePage,
        allowedPermissions: ['[INTEROP.IIM:Change]'],
      },
      {
        path: '/interop/invoice-imports/update/:id',
        component: routesMap.InteropInvoiceImportsEditPage,
        allowedPermissions: ['[INTEROP.IIM:Change]'],
      },
      {
        path: '/interop/invoice-imports/details/:id',
        component: routesMap.InteropInvoiceImportsDetailsPage,
        allowedPermissions: ['[INTEROP.IIM:Read]'],
      },
    ],
  },
  {
    path: '/interop/document-imports',
    component: routesMap.InteropDocumentImportsPage,
    allowedPermissions: ['[INTEROP.DIM:Read]'],
    routes: [
      {
        path: '/interop/document-imports/create',
        component: routesMap.InteropDocumentImportsCreatePage,
        allowedPermissions: ['[INTEROP.DIM:Change]'],
      },
      {
        path: '/interop/document-imports/update/:id',
        component: routesMap.InteropDocumentImportsEditPage,
        allowedPermissions: ['[INTEROP.DIM:Change]'],
      },
      {
        path: '/interop/document-imports/details/:id',
        component: routesMap.InteropDocumentImportsDetailsPage,
        allowedPermissions: ['[INTEROP.DIM:Read]'],
      },
    ],
  },
  {
    path: '/interop/annual-policy-imports',
    component: routesMap.InteropAnnualPolicyImportsPage,
    allowedPermissions: ['[INTEROP.API:Read]'],
    routes: [
      {
        path: '/interop/annual-policy-imports/create',
        component: routesMap.InteropAnnualPolicyImportsCreatePage,
        allowedPermissions: ['[INTEROP.API:Change]'],
      },
      {
        path: '/interop/annual-policy-imports/update/:id',
        component: routesMap.InteropAnnualPolicyImportsEditPage,
        allowedPermissions: ['[INTEROP.API:Change]'],
      },
      {
        path: '/interop/annual-policy-imports/details/:id',
        component: routesMap.InteropAnnualPolicyImportsDetailsPage,
        allowedPermissions: ['[INTEROP.API:Read]'],
      },
    ],
  },
  {
    path: '/interop/registration-certificate-imports',
    component: routesMap.InteropRegistrationCertificateImportsPage,
    allowedPermissions: ['[INTEROP.RCI:Read]'],
    routes: [
      {
        path: '/interop/registration-certificate-imports/create',
        component: routesMap.InteropRegistrationCertificateImportsCreatePage,
        allowedPermissions: ['[INTEROP.RCI:Change]'],
      },
      {
        path: '/interop/registration-certificate-imports/update/:id',
        component: routesMap.InteropRegistrationCertificateImportsEditPage,
        allowedPermissions: ['[INTEROP.RCI:Change]'],
      },
      {
        path: '/interop/registration-certificate-imports/details/:id',
        component: routesMap.InteropRegistrationCertificateImportsDetailsPage,
        allowedPermissions: ['[INTEROP.RCI:Read]'],
      },
    ],
  },
  {
    path: '/interop/registration-data-imports',
    component: routesMap.InteropRegistrationDataImportsPage,
    allowedPermissions: ['[INTEROP.RDI:Read]'],
    routes: [
      {
        path: '/interop/registration-data-imports/create',
        component: routesMap.InteropRegistrationDataImportsCreatePage,
        allowedPermissions: ['[INTEROP.RDI:Change]'],
      },
      {
        path: '/interop/registration-data-imports/update/:id',
        component: routesMap.InteropRegistrationDataImportsEditPage,
        allowedPermissions: ['[INTEROP.RDI:Change]'],
      },
      {
        path: '/interop/registration-data-imports/details/:id',
        component: routesMap.InteropRegistrationDataImportsDetailsPage,
        allowedPermissions: ['[INTEROP.RDI:Read]'],
      },
    ],
  },
  {
    path: '/interop/stock-vehicles',
    component: routesMap.InteropStockVehiclesPage,
    allowedPermissions: ['[INTEROP.STV:Read]'],
  },
  {
    path: '/report/storage-days',
    component: routesMap.ReportStorageDaysPage,
    allowedPermissions: ['[REPORT.STD:Read]'],
  },
  {
    path: '/report/storage-details',
    component: routesMap.ReportStorageDetailsPage,
    allowedPermissions: ['[REPORT.STO:Read]'],
  },
  {
    path: '/report/repair-costs',
    component: routesMap.ReportRepairCostsPage,
    allowedPermissions: ['[REPORT.REC:Read]'],
  },
  {
    path: '/report/stored-vehicles',
    component: routesMap.ReportStoredVehiclesPage,
    allowedPermissions: ['[REPORT.VIC:Read]'],
  },
  {
    path: '/report/expertise-and-repair-costs',
    component: routesMap.ReportExpertiseAndRepairCostsPage,
    allowedPermissions: ['[REPORT.ERC:Read]'],
  },
  {
    path: '/report/expertise-costs',
    component: routesMap.ReportExpertiseCostsPage,
    allowedPermissions: ['[REPORT.EXC:Read]'],
  },
  {
    path: '/report/taken-vehicles',
    component: routesMap.ReportTakenVehiclesPage,
    allowedPermissions: ['[REPORT.DEL:Read]'],
  },
  {
    path: '/',
    exact: true,
    component: routesMap.DashboardPage,
    allowedPermissions: ['[DASH:Read]'],
  },
  {
    component: routesMap.NotFoundPage,
    allowedPermissions: [],
  },
];
