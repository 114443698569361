import { ApiCore } from '../_utilities/core';

export default {
  createFicheExport: (idList) =>
    new ApiCore({
      url: '/vehicle/fleetraports/fiche',
    }).create(idList),
  createGoalsExport: () =>
    new ApiCore({
      url: '/vehicle/fleetraports/goals',
    }).create(),
  createGoalsMonthlyExport: () =>
    new ApiCore({
      url: '/vehicle/fleetraports/goalsmonthly',
    }).create(),
  createFleetBoxExport: (idList) =>
    new ApiCore({
      url: '/vehicle/fleetraports/fleetbox',
    }).create(idList),
  createLcdRaportExport: (idList) =>
    new ApiCore({
      url: '/vehicle/fleetraports/lcdraport',
    }).create(idList),
  createReleaseExport: (idList) =>
    new ApiCore({
      url: '/vehicle/fleetraports/release',
    }).create(idList),
};
