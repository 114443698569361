import { ApiCore } from '../_utilities/core';

const additionalCosts = new ApiCore({
  url: '/vehicle/additional-costs',
});

const additionalCostsItems = new ApiCore({
  url: '/vehicle/additional-costs/items',
});

const additionalCost = new ApiCore({
  url: '/vehicle/additional-costs/:id',
});

export default {
  getAdditionalCosts: (queryString) =>
    additionalCosts.query({ queryString }).read(),
  getAdditionalCost: (id) => additionalCost.query({ urlParams: { id } }).read(),
  createAdditionalCost: (data) => additionalCosts.create(data),
  updateAdditionalCost: (id, data) =>
    additionalCost.query({ urlParams: { id } }).update(data),
  deleteAdditionalCost: (id) =>
    additionalCost.query({ urlParams: { id } }).delete(),
  getAdditionalCostsItems: () => additionalCostsItems.query().read(),
};
