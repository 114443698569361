import { Alert } from '@mui/material';
import { withStyles } from '@mui/styles';
import flow from 'lodash/flow';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './toast-ui.jss';

function ToastUi({ classes, message, onClose, type }) {
  return (
    <Alert
      classes={classes}
      severity={type}
      variant='outlined'
      onClose={onClose}
      elevation={3}
    >
      {message}
    </Alert>
  );
}

ToastUi.propTypes = {
  classes: PropTypes.object,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  type: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
};

ToastUi.defaultProps = {
  classes: {},
  onClose: noop,
  type: 'info',
};

export default flow([withStyles(styles)])(ToastUi);
