import { ApiCore } from '../_utilities/core';

const products = new ApiCore({
  url: '/finance/products',
});

const product = new ApiCore({
  url: '/finance/products/:id',
});

export default {
  createProduct: (data) => products.create(data),
  updateProduct: (id, data) =>
    product.query({ urlParams: { id } }).update(data),
  deleteProduct: (id) => product.query({ urlParams: { id } }).delete(),
  getProducts: (queryString) =>
    new ApiCore({
      url: '/finance/products',
    })
      .query({ queryString })
      .read(),
  getProduct: (id) => product.query({ urlParams: { id } }).read(),
};
