import * as React from 'react';
function SvgArrowUpDown(props) {
  return (
    <svg
      width={24}
      height={24}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12.007 20h-1.058a29.32 29.32 0 01-2.307-3.15l.893-.514c.679 1.085 1.035 1.572 1.465 2.171V14h1.007v4.422c.407-.565.757-1.057 1.413-2.086l.894.514A29.317 29.317 0 0112.007 20zM12 5.493V10H11V5.578c-.407.565-.763 1.057-1.414 2.086l-.893-.514A28.568 28.568 0 0111 4h1.057c.839.997 1.61 2.05 2.308 3.15l-.9.514a31.759 31.759 0 00-1.464-2.171z'
        fill='currentColor'
      />
    </svg>
  );
}
export default SvgArrowUpDown;
