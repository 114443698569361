import { ApiCore } from '../_utilities/core';

const damages = new ApiCore({
  url: '/expertise/damages',
});
const damage = new ApiCore({
  url: '/expertise/damages/:id',
});

export default {
  getDamages: (queryString) => damages.query({ queryString }).read(),
  getDamage: (id) => damage.query({ urlParams: { id } }).read(),
  createDamage: (data) => damages.create(data),
  updateDamage: (id, data) => damage.query({ urlParams: { id } }).update(data),
  deleteDamage: (id) => damage.query({ urlParams: { id } }).delete(),
};
