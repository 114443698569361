import { ApiCore } from '../_utilities/core';

const statementResolds = new ApiCore({
  url: '/registration/resold-statements',
});

const statementResoldGrouped = new ApiCore({
  url: '/registration/resold-statements/grouped',
});

const statementResoldPdf = new ApiCore({
  url: '/registration/resold-statements/pdf',
});

export default {
  getStatementResolds: (queryString) =>
    statementResolds.query({ queryString }).read(),
  statementResoldGrouped: (data) => statementResoldGrouped.update(data),
  createBuybackedPdf: (data) => statementResoldPdf.create(data),
};
