import find from 'lodash/find';
import flow from 'lodash/flow';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { renderRoutes } from 'react-router-config';
import { matchPath, withRouter } from 'react-router-dom';

import AppSpinner from './components/shared/app-spinner/app-spinner';
import MainSidebar from './components/shared/main-sidebar/main-sidebar';
import { mapACLRouting } from './core/acl.utils';
import ErrorHandler from './core/error-handler';
import { withAuth } from './core/with-auth';
import { mainMenu, userMenu } from './navigations';
import { routing } from './routes';

class AppRoutingContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTokenChecked: false,
    };
  }

  componentDidMount() {
    const { authService, history } = this.props;

    authService
      .validateAccessToken()
      .then((res) => {
        this.setState({ isTokenChecked: true });
        return res;
      })
      .catch((err) => {
        const action = () => {
          const currentRoute = find(routing, (route) =>
            matchPath(history.location.pathname, route)
          );
          if (!currentRoute?.noLoginRequired) {
            const parsedParams = queryString.parse(history.location.search);

            history.push({
              pathname: '/login',
              search: isNil(parsedParams.redirectUrl)
                ? `?redirectUrl=${history.location.pathname}`
                : `?redirectUrl=${parsedParams.redirectUrl}`,
            });
          }
          this.setState({ isTokenChecked: true });
        };

        if (err?.message === 'Unauthorized') {
          // err comes from auth.actions.js
          action();
          return noop;
        }
        // unknown error, I suspect it will never get here,
        // but just in case I'm wrong handle in common way...
        // NOTE: it entered here on JS Error, like: Module build failed JSX element must be wrapped....
        return ErrorHandler(err, action);
      });
  }

  render() {
    const { isTokenChecked } = this.state;

    if (!isTokenChecked) {
      return <AppSpinner />;
    }

    return (
      <>
        {this.props.authService.isLoggedIn && (
          <MainSidebar mainMenu={mainMenu} userMenu={userMenu} />
        )}

        {renderRoutes(mapACLRouting(routing))}
      </>
    );
  }
}

AppRoutingContainer.propTypes = {
  authService: PropTypes.object,
  history: PropTypes.object,
};

AppRoutingContainer.defaultProps = {
  authService: {},
  history: null,
};

export default flow([withRouter, withAuth])(AppRoutingContainer);
