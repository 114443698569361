import { ApiCore } from '../_utilities/core';

const offers = new ApiCore({
  url: '/vehicle/offers',
});

const offer = new ApiCore({
  url: '/vehicle/offers/:id',
});

const offerDocs = new ApiCore({
  url: '/vehicle/offers/:id/generate-xlsx',
});

export default {
  getOffers: (queryString) => offers.query({ queryString }).read(),
  getOffer: (id) => offer.query({ urlParams: { id } }).read(),
  createOffer: (data) => offers.create(data),
  updateOffer: (id, data) => offer.query({ urlParams: { id } }).update(data),
  deleteOffer: (id) => offer.query({ urlParams: { id } }).delete(),
  generateOfferDocuments: (id) =>
    offerDocs.query({ urlParams: { id } }).create(),
};
