import { ApiCore, useDoRequest, useRequest } from '../_utilities/core';

const calculation = new ApiCore({
  url: '/vehicle/vehicles/calculation',
});

const periodOfUses = new ApiCore({
  url: '/vehicle/vehicles/periodOfUses',
});

const vehicleCategories = new ApiCore({
  url: '/vehicle/vehicles/categories',
});

const schedules = new ApiCore({
  url: '/vehicle/schedules/:scheduleType',
});

const statusesGroups = new ApiCore({
  url: '/vehicle/statuses/groups/:apiNamespace',
});

export default {
  getCalculation: (data) => calculation.create(data),

  /**
   *
   * @returns {Array} Returns following array:
   * ```
   * [
   *    data: response, // the response object
   *    isLoading: true, // indicates if it is still loading
   *    error: err, // err object from .catch(err),
   * ]
   * ```
   */
  usePeriodOfUsesDoRequest: () => useDoRequest(() => periodOfUses.read()),

  /**
   *
   * @returns {Array} Returns following array:
   * ```
   * [
   *    data: response, // the response object
   *    isLoading: true, // indicates if it is still loading
   *    error: err, // err object from .catch(err),
   * ]
   * ```
   */
  useVehicleCategoriesDoRequest: () =>
    useDoRequest(() => vehicleCategories.read()),

  /**
   *
   * @returns {Array} Returns following array:
   * ```
   * [
   *    {
   *       data: response, // the response object
   *       isLoading: true, // indicates if it is still loading
   *       error: err, // err object from .catch(err),
   *    },
   *    doRequest(queryString, scheduleType) // method that executes the request
   *    // scheduleType - the type of shedule to "ask" for
   * ]
   * ```
   */
  useSchedulesRequest: () =>
    useRequest((queryString, scheduleType) =>
      schedules
        .query({
          queryString,
          urlParams: { scheduleType },
        })
        .read()
    ),

  /**
   *
   * @returns {Array} Returns following array:
   * ```
   * [
   *    {
   *       data: response, // the response object
   *       isLoading: true, // indicates if it is still loading
   *       error: err, // err object from .catch(err),
   *    },
   *    doRequest(queryString, scheduleType) // method that executes the request
   *    // scheduleType - the type of shedule to "ask" for
   * ]
   * ```
   */
  useGetStatusesGroupsRequest: (apiNamespace) =>
    useRequest(() =>
      statusesGroups
        .query({
          urlParams: { apiNamespace },
        })
        .read()
    ),
};
