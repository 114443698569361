import { grey } from '@mui/material/colors';
import { adaptV4Theme, createTheme } from '@mui/material/styles';

import commonStyles from './theme/common-styles';
import { read, readBold, renaultLife, renaultLifeBold } from './theme/fonts';
import mixins from './theme/mixins';
import overrides from './theme/overrides';
import palette from './theme/palette';
import props from './theme/props';
import scrolls from './theme/scrolls';
import shadows from './theme/shadows';
import sizes from './theme/sizes';
import typography from './theme/typography';

const adaptedTheme = adaptV4Theme({
  palette,
  shadows: shadows({ palette }),
  scrolls: scrolls({ palette }),
  overrides: overrides({ palette, scrolls, sizes }),
  mixins,
  sizes,
  spacing: 5,
  typography,
  props,
  commonStyles: commonStyles({ palette, scrolls, sizes }),
});
adaptedTheme.components = {
  ...adaptedTheme.components,
};

const theme = createTheme(adaptedTheme);

theme.components = {
  ...theme.components,
  MuiCssBaseline: {
    ...theme.components.MuiCssBaseline,
    styleOverrides: {
      '#root': {
        minHeight: '100vh',
        display: 'flex',
      },
      body: {
        fontSize: '.8125rem',
        lineHeight: 1.38,
      },
      a: {
        textDecoration: 'none',
      },

      '*::-webkit-scrollbar': {
        backgroundColor: palette.gray[100],
      },
      '*::-webkit-scrollbar:horizontal': {
        height: 15,
        backgroundColor: palette.gray[100],
      },
      '*::-webkit-scrollbar:vertical': {
        width: 15,
      },
      '*::-webkit-scrollbar-thumb': {
        width: 15,
        backgroundColor: palette.gray[250],
      },
      ...read, // fonts fallback based on: https://github.com/mui/material-ui/issues/24966
      fallbacks: [readBold, renaultLife, renaultLifeBold],
    },
  },
  MuiTableCell: {
    ...theme.components.MuiTableCell,
    styleOverrides: {
      root: {
        padding: 8,
        border: `1px solid ${theme.palette.gray[50]}`,
        borderBottom: `1px solid ${theme.palette.gray[50]}`,
        borderLeft: 0,
        backgroundColor: theme.palette.common.white,
        minWidth: 130,
        maxWidth: 200,
        position: 'relative',
      },
      stickyHeader: {
        color: theme.palette.gray[300],
        border: `1px solid ${theme.palette.gray[50]}`,
        backgroundColor: theme.palette.common.white,
        borderBottom: '1px solid rgba(0,0,0,25%)',
        borderLeft: 'none',
      },
    },
  },
  MuiAutocomplete: {
    ...theme.components.MuiAutocomplete,
    styleOverrides: {
      option: {
        '& .MuiButtonBase-root.MuiCheckbox-root.Mui-checked': {
          color: theme.palette.common.black,
        },
      },
      root: {
        '& .MuiInput-root.MuiInputBase-sizeSmall .MuiInput-input': {
          paddingTop: '3px',
          paddingBottom: '6px',
        },
      },
      paper: {
        fontSize: '1rem',
        '& .MuiListSubheader-root.MuiAutocomplete-groupLabel': {
          color: 'rgba(0,0,0,0.6)',
        },
      },
    },
  },
  MuiIconButton: {
    ...theme.components.MuiIconButton,
    styleOverrides: {
      root: {
        padding: 0,

        '&.Mui-disabled': {
          color: theme.palette.gray[300],
        },
      },
    },
  },
  MuiButton: {
    ...theme.components.MuiButton,
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
        maxHeight: 40,
        padding: `11px 12px 6px 12px`,
        borderRadius: 0,
        '&.Mui-disabled': {
          pointerEvents: 'auto',
        },
      },
      text: {
        padding: `13px 12px 8px 12px`,
      },
      textSizeSmall: {
        maxHeight: 30,
        paddingRight: '6px', // Zarządzaj kolumnami, size='small' buttons
        fontSize: '.875rem',
      },
      startIcon: {
        marginTop: -4,
      },
      endIcon: {
        marginTop: -4,
      },
    },
    variants: [
      {
        props: { variant: 'contained', color: 'secondary' },
        style: {
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.secondary.main,
          '&:hover': {
            backgroundColor: theme.palette.secondary.light,
          },
          '&.Mui-disabled': {
            color: theme.palette.gray[300],
            backgroundColor: theme.palette.secondary.disabled,
          },
          '&.Mui-disabled:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
          },
        },
      },
      {
        props: { variant: 'contained', color: 'primary' },
        style: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: theme.palette.primary.light,
          },

          '&.Mui-disabled': {
            color: theme.palette.gray[300],
            backgroundColor: theme.palette.primary.disabled,

            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.12)',
            },
          },
        },
      },
      {
        props: { variant: 'text', color: 'primary' },
        style: {
          '&:hover': {
            backgroundColor: 'rgba(51, 51, 51, 0.04)',
          },
          '&.Mui-disabled:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    ],
  },
  MuiInputBase: {
    ...theme.components.MuiInputBase,
    styleOverrides: {
      root: {
        lineHeight: '1.2em',

        '&.Mui-disabled': {
          backgroundColor: theme.palette.gray.disabledInputBackground,
        },
      },
      input: {
        padding: 0,
        paddingTop: 3,
        paddingBottom: 7,
        height: '1.2em',
        '&::-webkit-calendar-picker-indicator': {
          display: 'none',
          WebkitAppearance: 'none',
        },
      },
      multiline: {
        padding: '6px 0 7px',
      },
      inputMultiline: {
        ...theme.scrolls.tableScroll,
        padding: 0,
      },
    },
  },
  MuiInput: {
    ...theme.components.MuiInput,
    styleOverrides: {
      input: {
        '&.Mui-disabled': {
          color: theme.palette.gray[300],
          WebkitTextFillColor: theme.palette.gray[300], //  to make disabled TextField text color darker, continues commit: 9c0cf7d36f2ee816279e95e194e55d00f7d29d6c
        },
      },

      underline: {
        '&:before': {
          borderBottom: `1px solid ${theme.palette.gray[200]}`,
        },
        '&:after': {
          borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: `1px solid ${theme.palette.gray[300]}`,
        },
      },
    },
  },
  MuiInputLabel: {
    ...theme.components.MuiInputLabel,
    styleOverrides: {
      root: {
        top: '1px',
      },
    },
  },
  MuiOutlinedInput: {
    ...theme.components.MuiOutlinedInput,
    styleOverrides: {
      multiline: {
        padding: '18.5px 14px',
      },
    },
  },
  MuiMenuItem: {
    ...theme.components.MuiMenuItem,
    styleOverrides: {
      gutters: {
        paddingLeft: 10,
      },
    },
  },
  MuiListItem: {
    ...theme.components.MuiListItem,
    styleOverrides: {
      root: {
        '& .MuiListItemButton-root': {
          '&.Mui-disabled': {
            opacity: 1,
            backgroundColor: theme.palette.gray.disabledInputBackground,
          },
        },
      },
    },
  },
  MuiSelect: {
    ...theme.components.MuiSelect,
    styleOverrides: {
      nativeInput: {
        paddingTop: 3,
        paddingBottom: 7,
      },
      select: {
        minHeight: 'initial',
        backgroundColor: 'transparent',
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  MuiSwitch: {
    ...theme.components.MuiSwitch,
    styleOverrides: {
      root: {
        padding: 2,
        width: 44,
        height: 24,
      },
      switchBase: {
        padding: 4,
        '&.Mui-checked': {
          transform: 'translateX(21px)',
          '&+.MuiSwitch-track': {
            opacity: 1,
          },
          '&.Mui-disabled': {
            color: grey[400],
          },
        },
        '&.Mui-disabled': {
          color: grey[400],
          '&+.MuiSwitch-track': {
            backgroundColor: theme.palette.common.black,
          },
        },
      },
      colorPrimary: {
        '&.Mui-checked': {
          color: theme.palette.common.white,
        },
      },
      thumb: {
        width: 16,
        height: 16,
        boxShadow: 'none',
      },
      track: {
        borderRadius: 24 / 2,
        opacity: 1,
        backgroundColor: theme.palette.gray[200],
        transition: theme.transitions.create(['background-color']),
      },
    },
  },
  MuiTooltip: {
    ...theme.components.MuiTooltip,
    styleOverrides: {
      tooltip: {
        color: theme.palette.gray[300],
        backgroundColor: theme.palette.common.white,
        boxShadow: `0 1px 5px 0 rgba(0,0,0,0.2)`,
        borderRadius: 0,
        lineHeight: '14px',
        fontSize: '0.625rem',
      },
    },
  },
  MuiDayPicker: {
    styleOverrides: {
      weekDayLabel: {
        color: theme.palette.gray[300],
        fontSize: 'xx-small',
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      root: {
        '&.MuiPickersDay-today': {
          color: theme.palette.primary.main,
          '&.Mui-selected': {
            color: theme.palette.primary.mainDarker,
          },
        },
        '&.Mui-selected': {
          '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.light,
          },
        },
      },
    },
  },
  PrivatePickersYear: {
    styleOverrides: {
      button: {
        '&.Mui-selected': {
          '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.light,
          },
        },
      },
    },
  },
};

export default theme;
