import { ApiCore } from '../_utilities/core';

const taxRates = new ApiCore({
  url: '/finance/tax-rates',
});

const taxRate = new ApiCore({
  url: '/finance/tax-rates/:id',
});

export default {
  getTaxRates: (queryString) => taxRates.query({ queryString }).read(),
  getTaxRate: (id) => taxRate.query({ urlParams: { id } }).read(),
  createTaxRate: (data) => taxRates.create(data),
  updateTaxRate: (id, data) =>
    taxRate.query({ urlParams: { id } }).update(data),
  deleteTaxRate: (id) => taxRate.query({ urlParams: { id } }).delete(),
  getTypes: (queryString) =>
    new ApiCore({
      url: '/finance/tax-rates/types',
    })
      .query({ queryString })
      .read(),
  getTypesByType: (type) =>
    new ApiCore({
      url: '/finance/tax-rates/:type/items',
    })
      .query({ urlParams: { type } })
      .read(),
  getTaxRatesByVat: () =>
    new ApiCore({
      url: '/finance/tax-rates/VAT/items',
    }).read(),
};
