import { ApiCore } from '../_utilities/core';

const configuration = new ApiCore({
  url: '/interop/csb/configuration/:sessionId',
});

const image = new ApiCore({
  url: '/interop/csb/configuration/image/:sessionId',
  responseType: 'blob',
});

export default {
  getConfiguration: (sessionId) =>
    configuration.query({ urlParams: { sessionId } }).read(),
  getImage: (sessionId) => image.query({ urlParams: { sessionId } }).read(),
};
