import { ApiCore } from '../_utilities/core';

const policies = new ApiCore({
  url: '/insurance/policies',
});

const policy = new ApiCore({
  url: '/insurance/policies/:id',
});

export default {
  getPolicies: (queryString) => policies.query({ queryString }).read(),
  getPolicy: (id) => policy.query({ urlParams: { id } }).read(),
  createPolicy: (data) => policies.create(data),
  updatePolicy: (id, data) => policy.query({ urlParams: { id } }).update(data),
  deletePolicy: (id) => policy.query({ urlParams: { id } }).delete(),
};
