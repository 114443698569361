import { ApiCore } from '../_utilities/core';

const documentTemplates = new ApiCore({
  url: '/finance/document-templates',
});

const documentTemplate = new ApiCore({
  url: '/finance/document-templates/:id',
});

export default {
  getDocumentTemplates: (queryString) =>
    documentTemplates.query({ queryString }).read(),
  getDocumentTemplate: (id) =>
    documentTemplate.query({ urlParams: { id } }).read(),
  createDocumentTemplate: (data) => documentTemplates.create(data),
  updateDocumentTemplate: (id, data) =>
    documentTemplate.query({ urlParams: { id } }).update(data),
  deleteDocumentTemplate: (id) =>
    documentTemplate.query({ urlParams: { id } }).delete(),
  getDocumentTypes: (documentClass) => (queryString) =>
    new ApiCore({
      url: '/finance/documents/:class/types',
    })
      .query({ queryString, urlParams: { class: documentClass } })
      .read(),
};
