import { ApiCore } from '../_utilities/core';

const organisationGroups = new ApiCore({
  url: '/offer/organisation-groups',
});

const organisationGroup = new ApiCore({
  url: '/offer/organisation-groups/:id',
});

export default {
  getOrganisationGroups: (queryString) =>
    organisationGroups.query({ queryString }).read(),
  getOrganisationGroup: (id) =>
    organisationGroup.query({ urlParams: { id } }).read(),
  createOrganisationGroup: (data) => organisationGroups.create(data),
  updateOrganisationGroup: (id, data) =>
    organisationGroup.query({ urlParams: { id } }).update(data),
  deleteOrganisationGroup: (id) =>
    organisationGroup.query({ urlParams: { id } }).delete(),
};
