import { ApiCore } from '../_utilities/core';

const currencies = new ApiCore({
  url: '/finance/currencies',
});

const currency = new ApiCore({
  url: '/finance/currencies/:id',
});

export default {
  getCurrencies: (queryString) => currencies.query({ queryString }).read(),
  getCurrency: (id) => currency.query({ urlParams: { id } }).read(),
  createCurrency: (data) => currencies.create(data),
  updateCurrency: (id, data) =>
    currency.query({ urlParams: { id } }).update(data),
  deleteCurrency: (id) => currency.query({ urlParams: { id } }).delete(),
};
