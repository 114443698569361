import { ApiCore } from '../_utilities/core';

const offdays = new ApiCore({
  url: '/auth/off-days',
});

const offday = new ApiCore({
  url: '/auth/off-days/:id',
});

export default {
  getOffdays: (queryString) => offdays.query({ queryString }).read(),
  getOffday: (id) => offday.query({ urlParams: { id } }).read(),
  createOffday: (data) => offdays.create(data),
  updateOffday: (id, data) => offday.query({ urlParams: { id } }).update(data),
  deleteOffday: (id) => offday.query({ urlParams: { id } }).delete(),
};
