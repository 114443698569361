import * as React from 'react';
function SvgVisibilityMixed(props) {
  return (
    <svg width={25} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <g fill='none' fillRule='evenodd'>
        <path fill='#FFF' d='M-527-268H913v800H-527z' />
        <path fill='#F3F5F6' d='M-527-68H913v540H-527z' />
        <path fill='#FFF' d='M-9-8h404v40H-9z' />
        <path
          d='M12.5 7.5c2.951 0 5.73 1.383 8.333 4.15v.8c-2.604 2.708-5.382 4.06-8.333 4.056-2.951-.004-5.73-1.356-8.333-4.056v-.8C6.77 8.883 9.549 7.5 12.5 7.5zm3.609 5H8.89c.253 1.697 1.773 3 3.609 3s3.356-1.303 3.609-3zm3.216.001h-2.166a4.37 4.37 0 01-.836 2.104c.904-.439 1.794-1.04 2.673-1.808l.329-.296zm-11.484 0l-2.162.001c.981.904 1.978 1.6 2.993 2.094a4.36 4.36 0 01-.83-2.095zM12.5 8.5c-2.334 0-4.573.984-6.738 3h13.476c-2.075-1.932-4.217-2.916-6.446-2.995L12.5 8.5z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
export default SvgVisibilityMixed;
