import * as React from 'react';
function SvgPicture(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M21 6v12.999H3V6h18zm-1 1H4v11h16V7zm-9.272 3.005h.84l1.013 1.997.279.559.517 1.056.513 1.08.13.279.71-1.43.918-1.814h.84l1.48 2.932.656 1.337h-1.08l-.279-.602-.521-1.052-.68-1.338-.748 1.465-.454.915-.294.612h-1.152l-.373-.82-.483-1.034-.527-1.09-.64-1.282-.235-.465-.677 1.34-.53 1.07-.332.694-.382.826-.253.574a42.25 42.25 0 00-.08.187H7.8l.486-1.068.444-.936.511-1.048.76-1.52.727-1.424zM7.2 8.015c1.08 0 1.872.791 1.872 1.894 0 1.055-.792 1.87-1.872 1.87a1.844 1.844 0 01-1.872-1.87c0-1.103.816-1.894 1.872-1.894zm0 .96c-.6 0-.936.31-.936.934 0 .6.336.936.936.936.624 0 .936-.336.936-.936 0-.623-.312-.935-.936-.935z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgPicture;
