import { randomId } from './core/utils';

export const mainMenu = [
  // temporary hide this
  // {
  //   id: randomId(),
  //   text: 'mainMenu_dashboard',
  //   path: '/',
  //   Icon: 'Home',
  //   allowedPermissions: ['[DASH:Read]'],
  // },
  {
    id: randomId(),
    text: 'mainMenu_vehicle',
    Icon: 'CarMarket',
    allowedPermissions: ['[VEHICLE:Read]'],
    children: [
      {
        id: randomId(),
        text: 'mainMenu_vehicle_activeVehicles',
        path: '/vehicle/active-vehicles',
        allowedPermissions: ['[VEHICLE.ACT:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_vehicle_designedVehicles',
        path: '/vehicle/designed-vehicles',
        allowedPermissions: ['[VEHICLE.DSG:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_vehicle_imports',
        path: '/imports',
        allowedPermissions: ['[VEHICLE.IMP:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_vehicle_depreciations',
        path: '/vehicle/depreciations',
        allowedPermissions: ['[VEHICLE.DEP:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_vehicle_segments',
        path: '/vehicle/segments',
        allowedPermissions: ['[VEHICLE.SEG:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_vehicle_models',
        path: '/vehicle/models',
        allowedPermissions: ['[VEHICLE.MOD:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_vehicle_additionalEquipments',
        path: '/vehicle/additional-equipments',
        allowedPermissions: ['[VEHICLE.AEQ:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_vehicle_additionalCosts',
        path: '/vehicle/additional-costs',
        allowedPermissions: ['[VEHICLE.ACO:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_vehicle_commonContents',
        path: '/vehicle/common-contents',
        allowedPermissions: ['[VEHICLE.CCT:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_vehicle_offers',
        path: '/vehicle/offers',
        allowedPermissions: ['[VEHICLE.OFF:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_vehicle_stockVehicles',
        path: '/interop/stock-vehicles',
        allowedPermissions: ['[INTEROP.STV:Read]'],
      },
    ],
  },
  {
    id: randomId(),
    text: 'mainMenu_expertise',
    Icon: 'SpareParts',
    allowedPermissions: ['[EXPERTISE:Read]'],
    children: [
      {
        id: randomId(),
        text: 'mainMenu_expertise_parameters',
        path: '/expertise/parameters',
        allowedPermissions: ['[EXPERTISE.PAR:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_expertise_repairs',
        path: '/expertise/repairs',
        allowedPermissions: ['[EXPERTISE.REP:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_expertise_damages',
        path: '/expertise/damages',
        allowedPermissions: ['[EXPERTISE.DAM:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_expertise_priceLists',
        path: '/expertise/price-lists',
        allowedPermissions: ['[EXPERTISE.PRL:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_expertise_vehicleExpertises',
        path: '/expertise/vehicle-expertises',
        allowedPermissions: ['[EXPERTISE.EXT:Read]'],
      },
    ],
  },
  {
    id: randomId(),
    text: 'mainMenu_finance',
    Icon: 'SpareParts',
    allowedPermissions: ['[FINANCE:Read]'],
    children: [
      {
        id: randomId(),
        text: 'mainMenu_finance_taxRates',
        path: '/finance/tax-rates',
        allowedPermissions: ['[FINANCE.TXR:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_finance_paymentMethods',
        path: '/finance/payment-methods',
        allowedPermissions: ['[FINANCE.PME:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_finance_paymentTerms',
        path: '/finance/payment-terms',
        allowedPermissions: ['[FINANCE.PTR:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_finance_currencies',
        path: '/finance/currencies',
        allowedPermissions: ['[FINANCE.CUR:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_finance_currencyRates',
        path: '/finance/currency-rates',
        allowedPermissions: ['[FINANCE.CRT:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_finance_numberingSchemes',
        path: '/finance/numbering-schemas',
        allowedPermissions: ['[FINANCE.NSM:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_finance_documentTemplates',
        path: '/finance/document-templates',
        allowedPermissions: ['[FINANCE.DTE:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_finance_settlements',
        path: '/finance/settlements',
        allowedPermissions: ['[FINANCE.STL:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_finance_products',
        path: '/finance/products',
        allowedPermissions: ['[FINANCE.PRD:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_finance_vehicles',
        path: '/finance/vehicles',
        allowedPermissions: ['[FINANCE.VEH:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_finance_documents',
        path: '/finance/documents',
        allowedPermissions: ['[FINANCE.DOC:Read]'],
      },
    ],
  },
  {
    id: randomId(),
    text: 'mainMenu_insurance',
    Icon: 'Safety',
    allowedPermissions: ['[INSURANCE:Read]'],
    children: [
      {
        id: randomId(),
        text: 'mainMenu_insurance_insurers',
        path: '/insurance/insurers',
        allowedPermissions: ['[INSURANCE.INS:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_insurance_exclusions',
        path: '/insurance/exclusions',
        allowedPermissions: ['[INSURANCE.EXC:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_insurance_policies',
        path: '/insurance/policies',
        allowedPermissions: ['[INSURANCE.POL:Read]'],
      },
    ],
  },
  {
    id: randomId(),
    text: 'mainMenu_inspection',
    Icon: 'CarServices',
    allowedPermissions: ['[INSPECTION:Read]'],
    children: [
      {
        id: randomId(),
        text: 'mainMenu_inspection_services',
        path: '/inspection/services',
        allowedPermissions: ['[INSPECTION.SRV:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_inspection_vehicleInspections',
        path: '/inspection/vehicle-inspections',
        allowedPermissions: ['[INSPECTION.VEI:Read]'],
      },
    ],
  },
  {
    id: randomId(),
    text: 'mainMenu_registration',
    Icon: 'Office',
    allowedPermissions: ['[REGISTRATION:Read]'],
    children: [
      {
        id: randomId(),
        text: 'mainMenu_registration_statementBuyBacked',
        path: '/registration/statement-buybackeds',
        allowedPermissions: ['[REGISTRATION.STA:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_registration_statementResold',
        path: '/registration/statement-resolds',
        allowedPermissions: ['[REGISTRATION.STR:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_registration_registrationOffice',
        path: '/registration/registration-offices',
        allowedPermissions: ['[REGISTRATION.OFF:Read]'],
      },
    ],
  },
  {
    id: randomId(),
    text: 'mainMenu_offer',
    Icon: 'Office',
    allowedPermissions: ['[OFFER:Read]'],
    children: [
      {
        id: randomId(),
        text: 'mainMenu_offer_organisationGroups',
        path: '/offer/organisation-groups',
        allowedPermissions: ['[OFFER.OGR:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_offer_salesActions',
        path: '/offer/sales-actions',
        allowedPermissions: ['[OFFER.ACT:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_offer_vehiclePackages',
        path: '/offer/vehicle-packages',
        allowedPermissions: ['[OFFER.PAC:Read]'],
      },
    ],
  },
  {
    id: randomId(),
    text: 'mainMenu_expertises',
    Icon: 'SpareParts',
    children: [
      {
        id: randomId(),
        text: 'mainMenu_expertises_table',
        path: '/expertises',
      },
      {
        id: randomId(),
        text: 'mainMenu_price_list',
        path: '/repair-pricelist',
      },
      {
        id: randomId(),
        text: 'mainMenu_repairs_matrix',
        path: '/repairs',
      },
      {
        id: randomId(),
        text: 'mainMenu_damages_matrix',
        path: '/damages',
      },
    ],
  },
  {
    id: randomId(),
    text: 'mainMenu_policy_service',
    path: '/policy-service',
    Icon: 'Safety',
  },
  {
    id: randomId(),
    text: 'mainMenu_bundle_offers',
    path: '/bundle-offers',
    Icon: 'Box',
  },
  {
    id: randomId(),
    text: 'mainMenu_vehicle_register_service',
    path: '/register-service',
    Icon: 'Office',
  },
  {
    id: randomId(),
    text: 'mainMenu_interop',
    Icon: 'Import',
    allowedPermissions: ['[INTEROP:Read]'],
    children: [
      {
        id: randomId(),
        text: 'mainMenu_interop_invoiceImports',
        path: '/interop/invoice-imports',
        allowedPermissions: ['[INTEROP.IIM:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_interop_documentImports',
        path: '/interop/document-imports',
        allowedPermissions: ['[INTEROP.DIM:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_interop_registrationDataImports',
        path: '/interop/registration-data-imports',
        allowedPermissions: ['[INTEROP.RDI:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_interop_annualPolicyImports',
        path: '/interop/annual-policy-imports',
        allowedPermissions: ['[INTEROP.API:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_interop_registrationCertificateImports',
        path: '/interop/registration-certificate-imports',
        allowedPermissions: ['[INTEROP.RCI:Read]'],
      },
    ],
  },
  {
    id: randomId(),
    text: 'mainMenu_report',
    Icon: 'ChartPie',
    allowedPermissions: ['[REPORT:Read]'],
    children: [
      {
        id: randomId(),
        text: 'mainMenu_report_storageDays',
        path: '/report/storage-days',
        allowedPermissions: ['[REPORT.STD:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_report_storageDetails',
        path: '/report/storage-details',
        allowedPermissions: ['[REPORT.STO:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_report_takenVehicles',
        path: '/report/taken-vehicles',
        allowedPermissions: ['[REPORT.DEL:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_report_expertiseCosts',
        path: '/report/expertise-costs',
        allowedPermissions: ['[REPORT.EXC:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_report_repairCosts',
        path: '/report/repair-costs',
        allowedPermissions: ['[REPORT.REC:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_report_expertiseAndRepairCosts',
        path: '/report/expertise-and-repair-costs',
        allowedPermissions: ['[REPORT.ERC:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_report_storedVehicles',
        path: '/report/stored-vehicles',
        allowedPermissions: ['[REPORT.VIC:Read]'],
      },
    ],
  },
  {
    id: randomId(),
    text: 'mainMenu_auth',
    Icon: 'UserGroup',
    allowedPermissions: ['[AUTH:Read]'],
    children: [
      {
        id: randomId(),
        text: 'mainMenu_auth_users',
        path: '/auth/users',
        allowedPermissions: ['[AUTH.USR:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_auth_organisations',
        path: '/auth/organisations',
        allowedPermissions: ['[AUTH.ORG:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_auth_profiles',
        path: '/auth/profiles',
        allowedPermissions: ['[AUTH.PRO:Read]'],
      },
      {
        id: randomId(),
        text: 'mainMenu_auth_offDays',
        path: '/auth/off-days',
        allowedPermissions: ['[AUTH.OFD:Read]'],
      },
    ],
  },
];

export const userMenu = [
  {
    id: randomId(),
    text: 'userMenu_name',
    subtext: 'userMenu_role',
    acronym: true,
    allowedPermissions: [],
    children: [
      // temporary hide this
      // {
      //   id: randomId(),
      //   text: 'userMenu_notifications',
      //   path: '/notifications',
      //   Icon: 'Bell',
      //   allowedPermissions: [],
      // },
      {
        id: randomId(),
        text: 'userMenu_settings',
        path: '/settings',
        Icon: 'Settings',
        allowedPermissions: [],
      },
      {
        id: randomId(),
        text: 'userMenu_logout',
        path: '/logout-purposely',
        Icon: 'Power',
        allowedPermissions: [],
      },
    ],
  },
];
