export default (theme) => ({
  root: {},
  view: {},
  track: {
    backgroundColor: theme.palette.gray[100],
  },
  thumb: {
    backgroundColor: theme.palette.gray[250],
  },
});
