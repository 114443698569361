import { ApiCore } from '../_utilities/core';

const vehiclePackages = new ApiCore({
  url: '/offer/vehicle-packages',
});

const vehiclePackage = new ApiCore({
  url: '/offer/vehicle-packages/:id',
});

const makeReservation = new ApiCore({
  url: '/offer/vehicle-packages/:id/reservation/make',
});

export default {
  getVehiclePackages: (queryString) =>
    vehiclePackages.query({ queryString }).read(),
  getVehiclePackage: (id) => vehiclePackage.query({ urlParams: { id } }).read(),
  createMakeReservation: (id) =>
    makeReservation.query({ urlParams: { id } }).create(),
  createVehiclePackagePrintoutPdf: (id) =>
    new ApiCore({
      url: '/offer/vehicle-packages/:id/printout',
    })
      .query({ urlParams: { id } })
      .create(),
};
