import { ApiCore } from '../_utilities/core';

const stockVehicles = new ApiCore({
  url: '/interop/stock-vehicles',
});

export default {
  getStockVehicles: (queryString) =>
    stockVehicles.query({ queryString }).read(),
};
