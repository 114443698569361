import { ApiCore } from '../_utilities/core';

const storageDays = new ApiCore({
  url: '/report/storage-days',
});

const storageDaysExport = new ApiCore({
  url: '/report/storage-days/export',
});

const storageDaysSummary = new ApiCore({
  url: '/report/storage-days/summary',
});

export default {
  getStorageDays: (queryString) => storageDays.query({ queryString }).read(),
  getStorageDaysExport: (queryString) =>
    storageDaysExport.query({ queryString }).read(),
  getStorageDaysSummary: (queryString) =>
    storageDaysSummary.query({ queryString }).read(),
};
