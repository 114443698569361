import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import { authDataReducer } from './auth.reducer';
import { selectedRowsReducer } from './selected-rows.reducer';
import { userDataReducer } from './user.reducer';

export const createReducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    authData: authDataReducer,
    userData: userDataReducer,
    selectedRows: selectedRowsReducer,
  });
