import ErrorHandler from 'app/core/error-handler';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import join from 'lodash/join';
import keys from 'lodash/keys';
import map from 'lodash/map';
import split from 'lodash/split';
import { compile } from 'path-to-regexp';
import { useEffect, useState } from 'react';

import { apiProvider } from './provider';

export class ApiCore {
  constructor(options, queryValue) {
    this._url = options.url || '';
    this._responseType = options.responseType || 'json';
    this._queryValue = queryValue || null;
    this._createVerb = options.createVerb || 'post';
    this._readVerb = options.readVerb || 'get';
    this._updateVerb = options.updateVerb || 'put';
    this._patchVerb = options.patchVerb || 'patch';
    this._deleteVerb = options.deleteVerb || 'delete';
  }

  _getQueryString = (queryString) => {
    if (isNil(queryString) || isEmpty(queryString)) {
      return '';
    }

    return `?${filter(
      map(keys(queryString), (key) => {
        if (isNil(queryString[key])) {
          return '';
        }

        if (isArray(queryString[key])) {
          if (queryString[key].length > 0) {
            if (isEqual(key, 'id')) {
              return `${key}=${join(split(queryString[key], ','), `&${key}=`)}`;
            }
            const res = map(queryString[key], (f) => {
              const [attribute, value] = split(f, '|');

              if (isNil(value)) return attribute;

              return `${attribute}|${encodeURIComponent(value)}`;
            });

            return `${key}=${join(res, `&${key}=`)}`;
          }
          return '';
        }

        return `${key}=${queryString[key]}`;
      }),
      (x) => x.length > 0 // above functin return empty strings, so it is to filter out empty ones and avoid && in queryString
    ).join('&')}`;
  };

  _getUrl = () => {
    const queryString = get(this._queryValue, 'queryString', null);
    const urlParams = get(this._queryValue, 'urlParams', null);

    if (isNil(urlParams) && isEmpty(urlParams)) {
      return `${this._url}${this._getQueryString(queryString)}`;
    }

    const toPath = compile(this._url, { encode: encodeURIComponent });
    return `${toPath(urlParams)}${this._getQueryString(queryString)}`;
  };

  _createRequest = (url, data) =>
    apiProvider.request({
      url,
      data,
      method: this._createVerb,
      responseType: this._responseType,
    });

  _readRequest = (url) =>
    apiProvider.request({
      url,
      method: this._readVerb,
      responseType: this._responseType,
    });

  _updateRequest = (url, data) =>
    apiProvider.request({
      url,
      data,
      method: this._updateVerb,
      responseType: this._responseType,
    });

  _deleteRequest = (url) =>
    apiProvider.request({
      url,
      method: this._deleteVerb,
      responseType: this._responseType,
    });

  _patchRequest = (url, data) =>
    apiProvider.request({
      url,
      data,
      method: this._patchVerb,
      responseType: this._responseType,
    });

  query = (queryValue) => {
    if (!isNil(queryValue)) {
      this._queryValue = queryValue;
    }
    return this;
  };

  create = (data) => this._createRequest(this._getUrl(), data);

  read = () => this._readRequest(this._getUrl());

  update = (data) => this._updateRequest(this._getUrl(), data);

  patch = (data) => this._patchRequest(this._getUrl(), data);

  delete = () => this._deleteRequest(this._getUrl());
}

/**
 *
 * @param {Function} getRequestDef Function that returns request Promise
 * @returns {Array} Returns following array:
 * ```
 * [
 *    data: response, // the response object
 *    isLoading: true, // indicates if it is still loading
 *    error: err, // err object from .catch(err),
 * ]
 * ```
 */
export const useDoRequest = (getRequestDef) => {
  const [state, setState] = useState({
    data: null,
    isLoading: true,
    error: null,
  });

  useEffect(() => {
    getRequestDef()
      .then((res) => {
        setState({
          ...state,
          data: res,
          isLoading: false,
        });
        return res;
      })
      .catch((err) => {
        setState({
          ...state,
          error: err,
          isLoading: false,
        });
        return ErrorHandler(err);
      });
  }, []);

  return [state.data, state.isLoading, state.error];
};

/**
 *
 * @param {Function} getRequestDef Function that returns request Promise, it receives args passed to doRequest()
 * @returns {Array} Returns following array:
 * ```
 * [
 *    {
 *       data: response, // the response object
 *       isLoading: true, // indicates if it is still loading
 *       error: err, // err object from .catch(err),
 *    },
 *    doRequest(argsPassedTo_getRequestDef) // method that executes the request
 * ]
 * ```
 */
export const useRequest = (getRequestDef) => {
  const [state, setState] = useState({
    data: null,
    isLoading: false,
    error: null,
  });

  const doRequest = (...args) => {
    setState({
      ...state,
      isLoading: true,
    });
    return getRequestDef(...args)
      .then((res) => {
        setState({
          ...state,
          data: res,
          isLoading: false,
        });
        return res;
      })
      .catch((err) => {
        setState({
          ...state,
          data: null,
          error: err,
          isLoading: false,
        });
        return ErrorHandler(err);
      });
  };

  return [state, doRequest];
};
