import * as React from 'react';
function SvgFilter(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M11.014 18.729l1.996-1.607V12.18c1.708-2.279 3.127-4.51 4.763-7.22H6.25c1.636 2.71 3.055 4.941 4.763 7.22v6.549zM19 4.84c-1.515 2.542-2.983 4.893-5.003 7.604v5.157L11.014 20l-.987-.456v-7.076C8.007 9.733 6.515 7.382 5 4.84V4h14v.84z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgFilter;
