import { ListItem, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import cx from 'classnames';
import flow from 'lodash/flow';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './sidebar-menu.jss';

function MenuItemContent({ classes, children, tooltip, id, path, onClick }) {
  if (!path) {
    return (
      <Tooltip title={tooltip}>
        <ListItem
          aria-describedby={id}
          button
          className={cx(classes.item)}
          disableGutters
          onClick={onClick}
        >
          {children}
        </ListItem>
      </Tooltip>
    );
  }

  return (
    <ListItem
      button
      className={cx(classes.item)}
      disableGutters
      // eslint-disable-next-line react/display-name
      component={forwardRef((props, ref) => (
        <NavLink
          activeClassName={cx(classes.active)}
          exact
          {...props}
          innerRef={ref}
        />
      ))}
      to={path}
    >
      <Tooltip title={tooltip}>{children}</Tooltip>
    </ListItem>
  );
}

MenuItemContent.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  id: PropTypes.string,
  path: PropTypes.string,
  tooltip: PropTypes.string,
  onClick: PropTypes.func,
};

MenuItemContent.defaultProps = {
  classes: {},
  children: '',
  id: null,
  path: null,
  tooltip: null,
  onClick: noop,
};

export default flow(withStyles(styles))(MenuItemContent);
