import * as React from 'react';
function SvgListCheck(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M10.004 17.995h8v-1h-8v1zm0-4.996h8.038v-1h-8.038v1zm0-4.997h8v-1h-8v1zM6.16 17.997a17.575 17.575 0 01-1.13-1.706l.817-.503c.264.431.529.84.817 1.272.505-.793.961-1.561 1.466-2.57l.84.456c-.72 1.321-1.128 2.018-1.801 3.051h-1.01zm0-5a17.454 17.454 0 01-1.13-1.706l.817-.503c.264.431.529.84.817 1.272.505-.792.961-1.561 1.466-2.57l.84.456c-.72 1.322-1.128 2.018-1.801 3.051h-1.01zm0-5a17.509 17.509 0 01-1.13-1.705l.817-.504c.264.432.529.84.817 1.272.505-.792.961-1.56 1.466-2.57l.84.457c-.72 1.32-1.128 2.017-1.801 3.05h-1.01z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgListCheck;
