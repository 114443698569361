export default {
  MuiButton: {
    disableElevation: true,
    disableRipple: true,
  },
  MuiButtonBase: {
    disableRipple: true,
    disableTouchRipple: true,
  },
  MuiIconButton: {
    disableRipple: true,
    disableFocusRipple: true,
  },
  MuiInputLabel: {
    variant: 'standard',
  },
  MuiSelect: {
    variant: 'standard',
  },
  MuiSwitch: {
    disableRipple: true,
  },
  MuiTextField: {
    variant: 'standard',
    fullWidth: true,
    size: 'small',
    InputProps: {
      margin: 'none',
    },
  },
  MuiPaper: {
    elevation: 0,
    square: true,
  },
  MuiTooltip: {
    disableInteractive: true,
  },
};
