import { LOCAL_STORAGE_BASE_URL } from 'app/core/constants';
import { setLocalStorage } from 'app/core/local-storage-handler';
import getAvailablePath from 'app/helpers/get-available-path.helpers';
import { getLocalStorageAttributeTitle } from 'app/helpers/table.helpers';
import { routing } from 'app/routes';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as authActions from '../actions/auth.actions';

export const withAuth = (WrappedComponent) => {
  class WithAuth extends React.Component {
    componentDidMount() {
      const { userData } = this.props;

      let availablePaths = [{ path: '/login', availablePaths: [] }];

      if (!isNil(userData) && !isNil(userData.permissions)) {
        // filter by permissions
        const avPaths = [];
        const addAvPaths = (routes) => {
          forEach(routes, (r) => {
            if (
              !isEmpty(r.allowedPermissions) &&
              find(userData.permissions, (p) =>
                includes(r.allowedPermissions, p)
              ) !== undefined
            ) {
              avPaths.push(r);
              if (r.routes) {
                addAvPaths(r.routes);
              }
            }
          });
        };
        addAvPaths(routing);
        availablePaths = avPaths;
      }

      if (!isNil(userData.userId)) {
        setLocalStorage(
          getLocalStorageAttributeTitle(
            '',
            userData.userId,
            LOCAL_STORAGE_BASE_URL
          ),
          getAvailablePath(availablePaths)
        );
      }
    }

    render() {
      const { actions, authData, userData, ...rest } = this.props;
      const { isLoggedIn } = authData;

      const authService = {
        isLoggedIn,
        authData,
        login: actions.login,
        logout: actions.logout,
        logoutPurposely: actions.logoutPurposely,
        validateAccessToken: actions.validateAccessToken,
        refreshAccessToken: actions.refreshAccessToken,
        updateSwitchViewToken: actions.updateSwitchViewToken,
      };

      const userDataService = isLoggedIn ? userData : undefined;

      return (
        <WrappedComponent
          authService={authService}
          userDataService={userDataService}
          {...rest}
        />
      );
    }
  }

  WithAuth.propTypes = {
    actions: PropTypes.object,
    authData: PropTypes.object,
    userData: PropTypes.object,
  };

  WithAuth.defaultProps = {
    actions: null,
    authData: null,
    userData: null,
  };

  const mapStateToProps = ({ authData, userData }) => ({ authData, userData });

  const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
      {
        ...authActions,
      },
      dispatch
    ),
  });

  return connect(mapStateToProps, mapDispatchToProps)(WithAuth);
};
