import { ApiCore } from '../_utilities/core';

const expertiseCosts = new ApiCore({
  url: '/report/expertise-costs',
});

const expertiseCostsExport = new ApiCore({
  url: '/report/expertise-costs/export',
});

const expertiseCostsSummary = new ApiCore({
  url: '/report/expertise-costs/summary',
});

export default {
  getExpertiseCosts: (queryString) =>
    expertiseCosts.query({ queryString }).read(),
  getExpertiseCostsExport: (queryString) =>
    expertiseCostsExport.query({ queryString }).read(),
  getExpertiseCostsSummary: (queryString) =>
    expertiseCostsSummary.query({ queryString }).read(),
};
