import {
  DEFAULT_PAGE_SIZE,
  VEHICLE_OFFERS_OFFER_TYPES,
} from 'app/core/constants';
import camelCase from 'lodash/camelCase';
import split from 'lodash/split';
import trimEnd from 'lodash/trimEnd';
import trimStart from 'lodash/trimStart';
import React from 'react';

import { Add, Calendar, Cancel, Download, Sidebar } from '../components/icons';

export const initialState = {
  isLoading: true,
  columns: null,
  rows: [],
  rowsCount: 0,
  pagesCount: null,
  sort: null,
  filter: [],
  dictionaries: {},
  pageNo: 1,
  pageSize: DEFAULT_PAGE_SIZE,
};

export const getLocalStorageAttributeTitle = (prefix, id, sufix) =>
  `${prefix}_${id}${sufix}`;

const getTranslationKey = (apiNamespace, moduleName, key) =>
  `${camelCase(apiNamespace)}_${camelCase(moduleName)}_${camelCase(key)}`;

export const header = (
  t,
  {
    apiNamespace,
    moduleName,
    filtersAction,
    exportActionIsLoading,
    exportAction,
    sortClearAction,
    showAddButton = true,
    showAddButtonOffers = false,
    showExportActionButton = false,
    showSchedulesButton = false,
    schedulesAction,
    showSummaryButton = false,
    summaryAction,
    showExportFicheButton,
    exportFicheAction,
    exportFicheActionIsLoading,
    showExportGoalsButton,
    exportGoalsAction,
    exportGoalsActionIsLoading,
    showExportGoalsMonthlyButton,
    exportGoalsMonthlyAction,
    exportGoalsMonthlyActionIsLoading,
    showExportFleetBoxButton,
    exportFleetBoxAction,
    exportFleetBoxActionIsLoading,
    showExportLcdRaportButton,
    exportLcdRaportAction,
    exportLcdRaportActionIsLoading,
    showExportReleaseButton,
    exportReleaseAction,
    exportReleaseActionIsLoading,
  }
) => ({
  title: t(getTranslationKey(apiNamespace, moduleName, 'title')),
  buttons: [
    ...(showAddButton
      ? [
          {
            label: t(getTranslationKey(apiNamespace, moduleName, 'add')),
            icon: <Add />,
            type: 'link',
            action: `/${apiNamespace}/${moduleName}/create`,
            allowedPermissions: ['[CurrentModule:Change]'],
          },
        ]
      : []),
    ...(showAddButtonOffers
      ? [
          {
            groupLabel: t(getTranslationKey(apiNamespace, moduleName, 'add')),
            buttons: [
              {
                label: t(
                  `${getTranslationKey(apiNamespace, moduleName, 'add')}_STR`
                ),
                icon: <Add />,
                type: 'link',
                action: `/${apiNamespace}/${moduleName}/create/${VEHICLE_OFFERS_OFFER_TYPES.Str}`,
                allowedPermissions: ['[CurrentModule:Change]'],
              },
              {
                label: t(
                  `${getTranslationKey(apiNamespace, moduleName, 'add')}_Direct`
                ),
                icon: <Add />,
                type: 'link',
                action: `/${apiNamespace}/${moduleName}/create/${VEHICLE_OFFERS_OFFER_TYPES.Direct}`,
                allowedPermissions: ['[CurrentModule:Change]'],
              },
            ],
          },
        ]
      : []),
    ...(filtersAction
      ? [
          {
            label: t('common_buttons_clearFilters'),
            icon: <Cancel />,
            type: 'fn',
            action: filtersAction,
            allowedPermissions: ['[CurrentModule:Read]'],
          },
        ]
      : []),
    ...(sortClearAction
      ? [
          {
            label: t('common_table_toolbar_clearSort'),
            icon: <Cancel />,
            type: 'fn',
            action: sortClearAction,
            allowedPermissions: ['[CurrentModule:Read]'],
          },
        ]
      : []),
    ...(showExportActionButton ||
    showExportFicheButton ||
    showExportGoalsButton ||
    showExportGoalsMonthlyButton ||
    showExportFleetBoxButton ||
    showExportLcdRaportButton ||
    showExportReleaseButton
      ? [
          {
            groupLabel: t('common_buttons_export'),
            buttons: [
              ...(showExportActionButton
                ? [
                    {
                      label: t('common_buttons_exportResults'),
                      icon: <Download />,
                      type: 'fn',
                      action: exportAction,
                      allowedPermissions: ['[CurrentModule:Read]'],
                      isLoading: exportActionIsLoading,
                    },
                  ]
                : []),
              ...(showExportFicheButton
                ? [
                    {
                      label: t('common_buttons_exportFiche'),
                      icon: <Download />,
                      type: 'fn',
                      action: exportFicheAction,
                      allowedPermissions: ['[VEHICLE.FLR:Read]'],
                      isLoading: exportFicheActionIsLoading,
                    },
                  ]
                : []),
              ...(showExportGoalsButton
                ? [
                    {
                      label: t('common_buttons_exportGoals'),
                      icon: <Download />,
                      type: 'fn',
                      action: exportGoalsAction,
                      allowedPermissions: ['[VEHICLE.FLR:Read]'],
                      isLoading: exportGoalsActionIsLoading,
                    },
                  ]
                : []),
              ...(showExportGoalsMonthlyButton
                ? [
                    {
                      label: t('common_buttons_exportGoalsMonthly'),
                      icon: <Download />,
                      type: 'fn',
                      action: exportGoalsMonthlyAction,
                      allowedPermissions: ['[VEHICLE.FLR:Read]'],
                      isLoading: exportGoalsMonthlyActionIsLoading,
                    },
                  ]
                : []),
              ...(showExportFleetBoxButton
                ? [
                    {
                      label: t('common_buttons_exportFleetBox'),
                      icon: <Download />,
                      type: 'fn',
                      action: exportFleetBoxAction,
                      allowedPermissions: ['[VEHICLE.FLR:Read]'],
                      isLoading: exportFleetBoxActionIsLoading,
                    },
                  ]
                : []),
              ...(showExportLcdRaportButton
                ? [
                    {
                      label: t('common_buttons_exportLcdRaport'),
                      icon: <Download />,
                      type: 'fn',
                      action: exportLcdRaportAction,
                      allowedPermissions: ['[VEHICLE.FLR:Read]'],
                      isLoading: exportLcdRaportActionIsLoading,
                    },
                  ]
                : []),
              ...(showExportReleaseButton
                ? [
                    {
                      label: t('common_buttons_exportRelease'),
                      icon: <Download />,
                      type: 'fn',
                      action: exportReleaseAction,
                      allowedPermissions: ['[VEHICLE.FLR:Read]'],
                      isLoading: exportReleaseActionIsLoading,
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(showSchedulesButton
      ? [
          {
            label: t('common_buttons_schedules'),
            icon: <Calendar />,
            type: 'fn',
            action: schedulesAction,
            allowedPermissions: ['[VEHICLE.ACT:Read]'],
          },
        ]
      : []),
    ...(showSummaryButton
      ? [
          {
            label: t('common_buttons_summary'),
            icon: <Sidebar />,
            type: 'fn',
            action: summaryAction,
            allowedPermissions: ['[CurrentModule:Read]'],
          },
        ]
      : []),
  ],
});

export const trimStartEnd = (start, end, el) =>
  trimStart(trimEnd(el, end), start);

export const getPermissionShortcut = (permission) =>
  split(trimStartEnd('[', ']', permission), ':')[0];
