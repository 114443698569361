import { SIDEBAR_OPEN_MASK_Z_INDEX } from 'app/components/shared/sidebar/sidebar.jss';

export default ({ palette, sizes, scrolls }) => ({
  form: {
    input: {
      margin: `${sizes.form.inputWrapper.gutter}px 0`,
      paddingBottom: sizes.form.inputWrapper.error,
      '& ::-webkit-outer-spin-button, & ::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },

      /* Firefox */
      '& [type=number]': {
        MozAppearance: 'textfield',
      },
    },
    switchRoot: {
      margin: 0,
      justifyContent: 'space-between',
    },
    switchLabel: {
      marginTop: 4,
    },
    labelError: {
      color: palette.error.main,
    },
  },
  autoComplete: {
    autocompleteRoot: {
      padding: 0,
      paddingRight: sizes.offset.lg,
    },
    autocompleteInput: {
      maxWidth: '100%',
      paddingLeft: sizes.offset.lg,
      boxSizing: 'border-box',
    },
    autocompleteListbox: {
      ...scrolls.tableScroll,
    },
    autocompletePopperOption: {
      '&:hover': {
        backgroundColor: `${palette.gray[50]} !important`,
      },
      '&[aria-selected="true"]': {
        backgroundColor: `${palette.primary.light} !important`,
      },
    },
    autocompleteTag: {
      borderRadius: '0 !important',
    },
    autocompleteCheckbox: {
      color: 'black',
    },
  },
  sidebar: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      maxWidth: 360 + (15 - 6), // 15px is the new scroll width, 6px is the old
      height: '100vh',
      maxHeight: '100vh',
      boxSizing: 'border-box',
    },
    btnRoot: {
      marginLeft: 10,
      marginRight: 10,
    },
  },
  addressesListSidebar: {
    addressBoxContainer: {
      margin: 0,
      padding: `0px ${sizes.offset.lg}px`,
      '&:hover': {
        backgroundColor: palette.gray[50],
      },
      cursor: 'pointer',
    },
    addressBoxContainerSelected: {
      backgroundColor: palette.gray[50],
    },
    searchSectionContainer: {
      overflow: 'hidden',
    },
  },
  mainTable: {
    imgRowContainer: {
      width: '100%',
      height: 30,
      textAlign: 'center',
      overflow: 'hidden',
    },
    imgRow: {
      height: 30,
    },
  },
  formScrollableTable: {
    root: {
      maxHeight: 440,
      backgroundColor: 'white',

      '& .MuiAutocomplete-root': {
        paddingRight: 0,
        '& .MuiAutocomplete-inputRoot': {
          paddingLeft: 0,
          paddingRight: 20,
        },
      },
    },
    table: {
      border: 'none',
      maxWidth: '100vw',
      width: '100%',
      paddingRight: sizes.cell.gutter,

      '& .MuiTableRow-root': {
        height: 'auto',
      },
      '& .MuiTableCell-root': {
        '& button': {
          paddingTop: 5,
          paddingBottom: 5,
        },
      },
      '& .MuiFormControl-root': {
        paddingBottom: 0,
        margin: 0,
      },
      '& .MuiAutocomplete-root': {
        '& .MuiInput-underline:before': {
          borderBottom: 0,
        },
        '& .MuiAutocomplete-input:first-child': {
          padding: '0px!important',
          paddingTop: '5px!important',
        },
      },
    },
    cell: {
      alignItems: 'center',
      minWidth: sizes.cell.width,
      maxHeight: 60,
      color: palette.gray[400],
      borderRight: `1px solid ${palette.transparentBlack[100]}`,
      padding: `0px ${sizes.cell.gutter}px`,

      '&:nth-last-child(1)': {
        borderRight: 'none',
      },

      '& > span > svg': {
        paddingTop: sizes.table.offset,
      },
    },
    cellWidth: {
      width: 40,
    },
    additionalRepairRow: {
      '& td.MuiTableCell-root': {
        backgroundColor: '#FFF9E6',
      },
    },
    redCell: {
      backgroundColor: '#FFACAC!important',
    },
    greenCell: {
      backgroundColor: '#C9F2AC!important',
    },
    sidebarOpenedCell: {
      backgroundColor: `${palette.primary.main}!important`,
    },
    sidebarOpenedCellColorRow: {
      backgroundColor: `#ffce3359!important`,
      '& td.MuiTableCell-root': {
        backgroundColor: 'transparent',
      },
    },
    headerRow: {
      '& .MuiTableCell-root': {
        color: palette.gray[300],
        height: '40px',
        fontSize: '.725rem',

        '&.cellTableTitle': {
          color: '#000',
        },
      },
      '& .MuiTableCell-root.MuiTableCell-head': {
        backgroundColor: '#EEE',
        height: 'initial',
        paddingTop: 2,
        paddingBottom: 2,

        '& button': {
          padding: '0px!important',

          '&.MuiAutocomplete-popupIndicator': {
            paddingTop: '4px!important',

            '&.MuiAutocomplete-popupIndicatorOpen': {
              paddingTop: '0px!important', // revert above setting (because it is rotated)
              paddingBottom: '4px!important', // bottom is acctually at the top (because it is rotated)
            },
          },
        },
      },
    },
    formCell: {
      padding: 0,
      margin: 0,
    },
    addBtn: {
      backgroundColor: palette.primary.main,
      borderRadius: 5,
    },
    btn: {
      borderRadius: 5,
    },
    btnContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 10,
    },
    firstColumn: {
      width: 30,
      minWidth: 30,
      maxWidth: 30,
      paddingLeft: 0,
      paddingRight: 0,
      textAlign: 'center',
    },
    lastColumn: {
      width: 30,
      minWidth: 30,
      maxWidth: 30,
      paddingLeft: 0,
      paddingRight: 0,
    },
    field: {
      paddingLeft: sizes.cell.gutter,
      paddingRight: sizes.cell.gutter,
    },
    decline: {
      color: '#f44336',
    },
    confirm: {
      color: '#4caf50',
    },
    numberField: {
      paddingBottom: 0,
    },
    cellGrouping: {
      borderBottom: 'none',
    },
  },
  formTableContainer: {
    formTableContainer: {
      maxWidth: '100%',
      overflow: 'auto',
      paddingLeft: 10,
      paddingRight: 10,
      margin: sizes.form.gutter,
      marginRight: sizes.form.gutter + sizes.scrollbar,
    },
  },
  summaryTable: {
    summaryTable: {
      width: 'initial',
      margin: '20px auto 0px auto',

      '& .MuiTableCell-head': {
        textAlign: 'left',
        fontSize: '.725rem',
      },
      '& td': {
        textAlign: 'right',
        border: `1px solid ${palette.gray[100]}`,
        backgroundClip: 'padding-box',
      },
      '& td.header': {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '.7rem',
      },
      '& .summary td': {
        fontWeight: 'bold',
        fontSize: '.75rem',
      },
    },
  },
  sidebarOpenMask: {
    sidebarOpenMask: {
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      position: 'absolute',
      inset: 0,
      zIndex: SIDEBAR_OPEN_MASK_Z_INDEX,
    },
  },
  alertCodeSquare: {
    alertCodeSquare: {
      width: '10px',
      height: '10px',
      display: 'inline-block',
      margin: '0px 7px',
    },
  },
});
