import { ApiCore } from '../_utilities/core';

const invoiceImport = new ApiCore({
  url: '/interop/invoice-imports/:id',
});
const invoiceImportItems = new ApiCore({
  url: '/interop/invoice-imports/:importId/items',
});
const handleInvoiceImportItems = new ApiCore({
  url: '/interop/invoice-imports/:importId/items/:id',
});
const startInvoiceImport = new ApiCore({
  url: '/interop/invoice-imports/:id/start',
});
const getInvoiceImportItem = new ApiCore({
  url: '/interop/invoice-imports/:importId/items/:id',
  responseType: 'blob',
});

export default {
  getInvoiceImports: (queryString) =>
    new ApiCore({
      url: '/interop/invoice-imports',
    })
      .query({ queryString })
      .read(),
  createInvoiceImports: () =>
    new ApiCore({
      url: '/interop/invoice-imports',
    }).create(),
  deleteInvoiceImports: (queryString) =>
    new ApiCore({
      url: '/interop/invoice-imports',
    })
      .query({ queryString })
      .delete(),
  getInvoiceImport: (id) => invoiceImport.query({ urlParams: { id } }).read(),
  uploadItem: (importId, data) =>
    invoiceImportItems.query({ urlParams: { importId } }).create(data),
  deleteItem: (importId, id) =>
    handleInvoiceImportItems.query({ urlParams: { importId, id } }).delete(),
  updateItem: (importId, id, data) =>
    handleInvoiceImportItems
      .query({ urlParams: { importId, id } })
      .update(data),
  startInvoiceImport: (id) =>
    startInvoiceImport.query({ urlParams: { id } }).create(),
  getItem: (importId, id) =>
    getInvoiceImportItem.query({ urlParams: { importId, id } }).read(),
  deleteFileToImport: (importId, id) =>
    new ApiCore({
      url: '/interop/invoice-imports/:importId/items/:id',
    })
      .query({ urlParams: { importId, id } })
      .delete(),
};
