import { ApiCore } from '../_utilities/core';

const annualPolicyImport = new ApiCore({
  url: '/interop/annual-policy-imports/:id',
});
const annualPolicyImportItems = new ApiCore({
  url: '/interop/annual-policy-imports/:importId/items',
});
const handleAnnualPolicyImportItems = new ApiCore({
  url: '/interop/annual-policy-imports/:importId/items/:id',
});
const startAnnualPolicyImport = new ApiCore({
  url: '/interop/annual-policy-imports/:id/start',
});
const getAnnualPolicyImportItem = new ApiCore({
  url: '/interop/annual-policy-imports/:importId/items/:id',
  responseType: 'blob',
});

export default {
  getAnnualPolicyImports: (queryString) =>
    new ApiCore({
      url: '/interop/annual-policy-imports',
    })
      .query({ queryString })
      .read(),
  createAnnualPolicyImports: (data) =>
    new ApiCore({
      url: '/interop/annual-policy-imports',
    }).create(data),
  deleteAnnualPolicyImports: (queryString) =>
    new ApiCore({
      url: '/interop/annual-policy-imports',
    })
      .query({ queryString })
      .delete(),
  getAnnualPolicyImport: (id) =>
    annualPolicyImport.query({ urlParams: { id } }).read(),
  uploadItem: (importId, data) =>
    annualPolicyImportItems.query({ urlParams: { importId } }).create(data),
  deleteItem: (importId, id) =>
    handleAnnualPolicyImportItems
      .query({ urlParams: { importId, id } })
      .delete(),
  updateItem: (importId, id, data) =>
    handleAnnualPolicyImportItems
      .query({ urlParams: { importId, id } })
      .update(data),
  startAnnualPolicyImport: (id) =>
    startAnnualPolicyImport.query({ urlParams: { id } }).create(),
  getItem: (importId, id) =>
    getAnnualPolicyImportItem.query({ urlParams: { importId, id } }).read(),
  deleteFileToImport: (importId, id) =>
    new ApiCore({
      url: '/interop/annual-policy-imports/:importId/items/:id',
    })
      .query({ urlParams: { importId, id } })
      .delete(),
};
