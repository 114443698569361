import { REACT_APP_VERSION } from 'app/core/constants';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import ErrorHandler from './app/core/error-handler';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en-US',
    debug: process.env.NODE_ENV !== 'production',
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json?v=${REACT_APP_VERSION}`, // v= is to prevent caching
    },
    // keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: true,
    },
  })
  .catch((err) => ErrorHandler(err));

export default i18n;
