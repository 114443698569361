/* eslint-disable no-unused-vars */
import { withStyles } from '@mui/styles';
import cx from 'classnames';
import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import styles from './custom-scrollbars.jss';

class CustomScrollbars extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // temporary switched it off, because for ex. in /organisations/update page
      // there was horizontal scroll visible, while not needed
      // isTrackVerticalVisible: false,
      forceHideHorizontalScroll: false,
    };
  }

  renderThumb = ({ style, ...props }) => {
    const { classes } = this.props;
    return (
      <div className={cx(classes.thumb)} style={{ ...style }} {...props} />
    );
  };

  renderTrackHorizontal = ({ style, ...props }) => {
    const { classes, hideHorizontalScroll } = this.props;
    return (
      <div
        className={cx(classes.track, 'CustomScrollbarsTrack')}
        style={{
          ...style,
          ...(style.width ? { width: '15px' } : { height: '15px' }),
          right: 0,
          bottom: 0,
          left: 0,
          borderRadius: 0,
          ...(hideHorizontalScroll && { display: 'none' }),
          ...(this.state.forceHideHorizontalScroll && { display: 'none' }),
        }}
        {...props}
      />
    );
  };

  renderTrackVertical = ({ style, ...props }) => {
    const { classes, hideVerticalScroll } = this.props;
    return (
      <div
        className={cx(classes.track, 'CustomScrollbarsTrack')}
        style={{
          ...style,
          ...(style.width ? { width: '15px' } : { height: '15px' }),
          right: 0,
          bottom: 0,
          top: 0,
          borderRadius: 0,
          ...(hideVerticalScroll && { display: 'none' }),
        }}
        {...props}
      />
    );
  };

  renderView = (props) => {
    const { classes } = this.props;
    // temporary switched it off, because for ex. in /organisations/update page
    // there was horizontal scroll visible, while not needed
    // if (this.state.isTrackVerticalVisible) {
    //   delete props.style.marginRight; // there was no padding on the right side if track was visible (natural browser behaviour is that scrolled content "jumps" to the left if scroll appears)
    // }
    return <div className={cx(classes.view)} {...props} />;
  };

  onUpdate = (props) => {
    const newForceHideHorizontalScroll =
      props.scrollWidth === props.clientWidth + 1; // sometimes while rendering browser rounds width from float to integer values (for ex.: 1116.2px & 1116.6px), this "+ 1" avoids to show scroll when there is 1px to scroll or rounding issue exists

    if (
      // this IF is to prevent infinite update loop
      this.state.forceHideHorizontalScroll !== newForceHideHorizontalScroll ||
      this.state.scrollHeight !== props.scrollHeight ||
      this.state.clientHeight !== props.clientHeight
    ) {
      this.setState({
        // temporary switched it off, because for ex. in /organisations/update page
        // there was horizontal scroll visible, while not needed
        // isTrackVerticalVisible: props.scrollHeight > props.clientHeight,
        forceHideHorizontalScroll: newForceHideHorizontalScroll,
        scrollHeight: props.scrollHeight,
        clientHeight: props.clientHeight,
      });
    }
  };

  render() {
    const {
      children,
      disableScrollbars,
      classes,
      className,
      hideVerticalScroll, // this is here to remove this property from props object
      hideHorizontalScroll, // this is here to remove this property from props object
      refToScrollbars,
      ...props
    } = this.props;

    return disableScrollbars ? (
      children
    ) : (
      <Scrollbars
        renderTrackHorizontal={this.renderTrackHorizontal}
        renderThumbHorizontal={this.renderThumb}
        renderTrackVertical={this.renderTrackVertical}
        renderThumbVertical={this.renderThumb}
        renderView={this.renderView}
        onUpdate={this.onUpdate}
        className={cx(classes.root, className)}
        {...props}
        ref={refToScrollbars}
      >
        {children}
      </Scrollbars>
    );
  }
}

CustomScrollbars.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
  className: PropTypes.string,
  disableScrollbars: PropTypes.bool,
  hideVerticalScroll: PropTypes.bool,
  hideHorizontalScroll: PropTypes.bool,
  refToScrollbars: PropTypes.object,
};

CustomScrollbars.defaultProps = {
  classes: {},
  className: null,
  disableScrollbars: false,
  hideVerticalScroll: false,
  hideHorizontalScroll: false,
  refToScrollbars: null,
};

export default flow([withStyles(styles)])(CustomScrollbars);
