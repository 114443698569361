import * as React from 'react';
function SvgDecline(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M12 5c4.267 0 7 3.045 7 7.024 0 3.98-2.733 7.024-7 7.024-4.267 0-7-3.045-7-7.024C5 8.044 7.733 5 12 5zm4.522 2.892l-8.286 8.966c.984.748 2.261 1.183 3.764 1.183 3.668 0 5.993-2.59 5.993-6.017 0-1.528-.462-2.889-1.299-3.928l-.172-.204zM12 6.007c-3.668 0-5.993 2.589-5.993 6.017 0 1.66.546 3.124 1.525 4.192l8.276-8.992c-.99-.77-2.283-1.217-3.808-1.217z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgDecline;
