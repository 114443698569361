import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import AppSpinner from '../components/shared/app-spinner/app-spinner';
import { checkPermissions } from './acl.utils';
import { withAuth } from './with-auth';

export const withAccess = (WrappedComponent) => {
  class WithAccess extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: true,
        isAccessDenied: true,
      };
    }

    componentDidMount() {
      const { userDataService, route } = this.props;

      const userPermissions = userDataService?.permissions || [];

      const permitted = checkPermissions(
        userPermissions,
        route.allowedPermissions
      );

      if (permitted) {
        this.setState({ isLoading: false, isAccessDenied: false });
      } else {
        this.setState({ isLoading: false, isAccessDenied: true });
      }
    }

    render() {
      const { isLoading, isAccessDenied } = this.state;

      if (isLoading) {
        return <AppSpinner />;
      }

      if (
        isAccessDenied &&
        this.props.location.pathname !== '/login' // don't redirect to "no-access" if we are trying to render login page, in this case of course access is denied, probably we are after redirect this.props.location.search should contain ?redirectUrl=
        // TODO: it is possible above condition is not needed after changes in acl.utils.js line: 25
      ) {
        return (
          <Redirect
            to={`/no-access?redirectedFromUrl=${this.props.location.pathname}`}
          />
        );
      }

      return <WrappedComponent {...this.props} />;
    }
  }

  WithAccess.propTypes = {
    userDataService: PropTypes.object,
    route: PropTypes.object,
    location: PropTypes.object.isRequired,
  };

  WithAccess.defaultProps = {
    userDataService: null,
    route: null,
  };

  return flow([withAuth, withRouter])(WithAccess);
};
