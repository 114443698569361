import * as React from 'react';
function SvgSafety(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M12.072 15.172h-.385c-.914-1.202-1.876-2.501-2.766-4.017l.842-.53c.65 1.108 1.37 2.118 2.092 3.104a42.728 42.728 0 002.911-5.388l.866.432c-1.083 2.382-2.14 4.259-3.56 6.4zM12 19.238c3.849-1.395 6.014-3.873 6.014-7.65V6.512C15.56 6.416 13.78 5.718 12 4.155c-1.828 1.563-3.536 2.261-6.014 2.357v5.076c0 3.777 2.165 6.255 6.014 7.65zm-.216.938C7.526 18.781 5 15.99 5 11.588V5.574c3.223 0 4.763-.674 6.76-2.574h.48c1.997 1.9 3.537 2.574 6.76 2.574v6.014c0 4.402-2.526 7.193-6.784 8.588h-.432z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgSafety;
