import * as React from 'react';
function SvgUserGroup(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M20.67 8.277c0-.815-.481-1.319-1.298-1.319-.817 0-1.298.504-1.298 1.319s.48 1.318 1.298 1.318c.817 0 1.298-.503 1.298-1.318zm-3.845 4.241a3.56 3.56 0 00-.793-.814c.312-.91.913-1.51 1.778-1.821-.504-.384-.72-.935-.72-1.606 0-1.39.912-2.277 2.282-2.277s2.283.887 2.283 2.277c0 .67-.217 1.222-.722 1.606 1.37.503 2.067 1.749 2.067 3.713v.408h-.985v-.383c0-2.061-.865-3.067-2.643-3.067-1.49 0-2.355.718-2.547 1.964zm-2.523-2.803c0-1.078-.649-1.75-1.802-1.75s-1.802.672-1.802 1.75c0 1.174.649 1.845 1.802 1.845s1.802-.671 1.802-1.845zM8.992 17h-.985v-.504c0-2.564.913-4.073 2.787-4.649-.697-.455-1.057-1.198-1.057-2.132 0-1.654 1.057-2.708 2.763-2.708s2.763 1.054 2.763 2.708c0 .91-.36 1.677-1.033 2.132 1.874.576 2.787 2.085 2.787 4.65V17h-.985v-.504c0-2.683-1.129-3.978-3.532-3.978-2.354 0-3.508 1.319-3.508 3.978V17zM6.926 8.277c0-.815-.48-1.319-1.298-1.319-.817 0-1.298.504-1.298 1.319s.48 1.318 1.298 1.318c.817 0 1.298-.503 1.298-1.318zm-3.94 5.344v.383H2v-.408c0-1.964.697-3.21 2.067-3.713-.505-.384-.721-.935-.721-1.606C3.346 6.887 4.259 6 5.628 6c1.37 0 2.283.887 2.283 2.277 0 .67-.217 1.222-.721 1.606.865.31 1.465.91 1.778 1.82-.36.265-.625.576-.793.815-.192-1.246-1.057-1.964-2.547-1.964-1.778 0-2.642 1.006-2.642 3.067z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgUserGroup;
