import filter from 'lodash/filter';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import keys from 'lodash/keys';

export const setLocalStorage = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
  return value;
};

/**
 *
 * @param {string} key
 * @param {any} defaultValue Optional, if some defaultValue will be provided it will be stored in storage and returned
 * @returns Value from storage or NULL if not found
 */
export const getLocalStorage = (key, defaultValue = undefined) => {
  let storage;
  try {
    storage = JSON.parse(window.localStorage.getItem(key));
  } catch (e) {
    window.localStorage.removeItem(key);
    return getLocalStorage(key, defaultValue);
  }

  if (isNil(storage)) {
    if (!isNil(defaultValue)) {
      setLocalStorage(key, defaultValue);
      return defaultValue;
    }
    return null;
  }

  return storage;
};

export const removeLocalStorage = (key) => {
  const storage = getLocalStorage(key);
  window.localStorage.removeItem(key);
  return storage;
};

export const getUserAllLocalStorageKeys = (userId) => {
  const res = filter(keys(localStorage), (l) => includes(l, userId));

  return res;
};

const LocalStorageHandler = {
  setLocalStorage,
  getLocalStorage,
  removeLocalStorage,
  getUserAllLocalStorageKeys,
};

export default LocalStorageHandler;
