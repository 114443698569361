import forOwn from 'lodash/forOwn';
import isFunction from 'lodash/isFunction';

import * as Icons from '../components/icons';

const icons = {};

forOwn(Icons, (value, key) => {
  if (isFunction(value)) {
    icons[key] = value;
  }
});

export default icons;
