import { ApiCore } from '../_utilities/core';

export default {
  resetPassword: (data) =>
    new ApiCore({
      url: '/auth/users/password/reset',
    }).create(data),
  updateResetPassword: (key, data) =>
    new ApiCore({
      url: '/auth/users/password/reset/:key',
    })
      .query({ urlParams: { key } })
      .update(data),
};
