import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import some from 'lodash/some';

import { withAccess } from './with-access';

export const checkPermissions = (userPermissions, allowedPermissions) => {
  if (isNil(allowedPermissions)) {
    return false;
  }
  if (allowedPermissions.length === 0) {
    return true;
  }
  return some(userPermissions, (permission) =>
    includes(allowedPermissions, permission)
  );
};

export const mapACLRouting = (routing) =>
  map(routing, (route) => {
    const newRoute = { ...route };

    if (!newRoute.noLoginRequired) {
      newRoute.component = withAccess(newRoute.component);
    }

    if (isArray(newRoute.routes)) {
      newRoute.routes = mapACLRouting(newRoute.routes);
    }

    return newRoute;
  });
