import * as React from 'react';
function SvgUpload(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M6.5 17H12V7.49c-.67.983-1.02 1.654-1.811 2.926l-.814-.576c1.077-1.68 1.82-2.712 2.706-3.84h.886c.886 1.128 1.63 2.16 2.707 3.84l-.791.6c-.79-1.296-1.212-1.968-1.883-2.952V17h5.5v1h-12v-1z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgUpload;
