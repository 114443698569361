import { ApiCore } from '../_utilities/core';

const priceLists = new ApiCore({
  url: '/expertise/price-lists',
});

const priceList = new ApiCore({
  url: '/expertise/price-lists/:id',
});

export default {
  getPriceLists: (queryString) => priceLists.query({ queryString }).read(),
  getPriceList: (id) => priceList.query({ urlParams: { id } }).read(),
  createPriceList: (data) => priceLists.create(data),
  updatePriceList: (id, data) =>
    priceList.query({ urlParams: { id } }).update(data),
  deletePriceList: (id) => priceList.query({ urlParams: { id } }).delete(),
};
