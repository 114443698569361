import * as React from 'react';
function SvgOffice(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M19 16v1h-2v-1h2zm-3 0v1h-2v-1h2zm-3 0v1h-2v-1h2zm-3.003 0v1h-2v-1h2zm-3 0v1h-2v-1h2zM17 14h2v-1h-2v1zm-3 0h2v-1h-2v1zm-3 0h2v-1h-2v1zm-3 0h2v-1H8v1zm-1-1v1H5v-1h2zm12-3v1h-2v-1h2zm-3 0v1h-2v-1h2zm-3 0v1h-2v-1h2zm-3 0v1H8v-1h2zm-3 0v1H5v-1h2zm9-3v1h-2V7h2zm3 0v1h-2V7h2zm2 12V5h-9v3H3v11h18zM2 7.003L11 7V4h11v16H2V7.003z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgOffice;
