import { ApiCore } from '../_utilities/core';

const storedVehicles = new ApiCore({
  url: '/report/stored-vehicles',
});

const storedVehiclesExport = new ApiCore({
  url: '/report/stored-vehicles/export',
});

const storedVehiclesSummary = new ApiCore({
  url: '/report/stored-vehicles/summary',
});

export default {
  getStoredVehicles: (queryString) =>
    storedVehicles.query({ queryString }).read(),
  getStoredVehiclesExport: (queryString) =>
    storedVehiclesExport.query({ queryString }).read(),
  getStoredVehiclesSummary: (queryString) =>
    storedVehiclesSummary.query({ queryString }).read(),
};
