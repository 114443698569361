import { ApiCore } from '../_utilities/core';

const users = new ApiCore({
  url: '/auth/users',
});

const user = new ApiCore({
  url: '/auth/users/:id',
});

const types = new ApiCore({
  url: '/auth/users/types',
});

const csb = new ApiCore({
  url: '/auth/users/csbSettings',
});

export default {
  getUsers: (queryString) => users.query({ queryString }).read(),
  getUser: (id) => user.query({ urlParams: { id } }).read(),
  createUser: (data) => users.create(data),
  updateUser: (id, data) => user.query({ urlParams: { id } }).update(data),
  deleteUser: (id) => user.query({ urlParams: { id } }).delete(),
  getTypes: () => types.read(),
  getCsb: () => csb.read(),
};
