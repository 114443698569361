import { ApiCore } from '../_utilities/core';

const exclusions = new ApiCore({
  url: '/insurance/exclusions',
});

const exclusion = new ApiCore({
  url: '/insurance/exclusions/:id',
});

export default {
  getExclusions: (queryString) => exclusions.query({ queryString }).read(),
  getExclusion: (id) => exclusion.query({ urlParams: { id } }).read(),
  createExclusion: (data) => exclusions.create(data),
  updateExclusion: (id, data) =>
    exclusion.query({ urlParams: { id } }).update(data),
  deleteExclusion: (id) => exclusion.query({ urlParams: { id } }).delete(),
};
