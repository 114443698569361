import * as React from 'react';
function SvgFileCar(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M17.273 4c1.44 1.2 2.744 2.471 3.727 3.718V20H9v-4h1v3h10V9h-4V5h-6l-.017 2.718c-.311-.024-.6-.048-.983-.072V4h8.273zM8.757 8.63c1.607 0 2.519.168 3.406.503 1.128.433 1.8 1.176 2.28 1.32l1.63.456c.744.216 1.2.528 1.415.887.336.576.504 1.511.504 1.895 0 .864-.743 1.32-1.727 1.32h-.288c-.192.768-.863 1.151-1.51 1.151-.6 0-1.296-.383-1.488-1.151H7.99c-.191.768-.863 1.151-1.511 1.151-.6 0-1.295-.408-1.511-1.176h-.048c-.936 0-1.919-.575-1.919-1.798v-1.2c0-.503.144-.96 1.127-1.943l.84.456c-.864.936-1.032 1.127-1.032 1.607v.984c0 .6.288.935.984.935h.072c.216-.648.816-1.103 1.487-1.103a1.6 1.6 0 011.512 1.103h4.99c.215-.648.815-1.103 1.486-1.103a1.6 1.6 0 011.511 1.103h.384c.528 0 .648-.096.648-.504 0-.335-.216-1.007-.36-1.223-.144-.216-.336-.384-.744-.48l-1.679-.431c-.623-.168-1.439-.936-2.47-1.344-.792-.311-1.512-.432-3.143-.432-.863 0-1.92.097-2.855.216l-.911-.552-.024-.24c.024 0 2.351-.407 3.934-.407zm5.71 5.181c-.385 0-.672.336-.672.72 0 .408.287.72.671.72.432 0 .72-.312.72-.72a.706.706 0 00-.72-.72zm-7.99 0c-.383 0-.67.336-.67.72 0 .408.287.72.67.72.433 0 .721-.312.721-.72a.707.707 0 00-.72-.72zm10.508-8.803v2.998h3.022c-1.031-1.175-1.918-2.063-3.022-2.998z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgFileCar;
