import DatePickerComponent from 'app/components/shared/datepicker/datepicker';
import forEach from 'lodash/forEach';
import invokeMap from 'lodash/invokeMap';
import isEmpty from 'lodash/isEmpty';
import join from 'lodash/join';
import keys from 'lodash/keys';
import map from 'lodash/map';
import noop from 'lodash/noop';
import pickBy from 'lodash/pickBy';
import replace from 'lodash/replace';
import split from 'lodash/split';
import toNumber from 'lodash/toNumber';
import toUpper from 'lodash/toUpper';
import trim from 'lodash/trim';
import trimEnd from 'lodash/trimEnd';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Files } from './api';
import { CHECK_FOR_NEW_FILE_INTERVAL } from './constants';
import ErrorHandler from './error-handler';

export const path = (pathname, withOrigin = false) =>
  `${withOrigin ? window.location.origin : ''}${
    process.env.PUBLIC_URL
  }${pathname}`;

export const randomId = () => Math.random().toString(32).slice(2);

export const getRandomString = (length) => {
  const randomChars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return result;
};

export const getAcronym = (string) =>
  toUpper(join(invokeMap(split(string, ' '), 'charAt', 0), ''));

export const triggerElementEvent = (elementId, event) =>
  document
    .getElementById(elementId)
    .dispatchEvent(new Event(event, { cancelable: true, bubbles: true }));

export const getQueryString = (queryString = {}) => {
  if (isEmpty(queryString)) {
    return null;
  }

  return `?${map(
    keys(pickBy(queryString)),
    (key) =>
      `${encodeURIComponent(key)}=${encodeURIComponent(queryString[key])}`
  ).join('&')}`;
};

export const changeValue = ([name, newValue], state, { changeValue }) => {
  changeValue(state, name, () => newValue);
};

export const setFieldTouched = ([name, touched], state) => {
  const field = state.fields[name];
  if (field) {
    field.touched = !!touched;
  }
};

/**
 * Extends React.useState() functionality by adding optional parameter
 * to setState() with callback function to be executed
 * after state changes.
 *
 * NOTE: Be aware calback will be executed with lastly set value when
 *       more then one setState occures without rerender!
 *
 * @param {*} initialState see React.useState()
 * @param {Boolean} forceCallbackWhenValueDontChange When FALSE callback will be executed only when state value changes
 * @returns It is similar to React.useState(), except returned setState function takes optional 2nd argument which is callback function
 */
export const useStateCallback = (
  initialState,
  forceCallbackWhenValueDontChange = true
) => {
  const [state, setState] = useState(
    forceCallbackWhenValueDontChange
      ? { stateValue: initialState }
      : initialState
  );
  const cbRef = useRef(null); // init mutable ref container for callbacks

  const setStateCallback = useCallback((newStateValue, cb) => {
    cbRef.current = cb; // store current, passed callback in ref
    setState(
      forceCallbackWhenValueDontChange
        ? { stateValue: newStateValue }
        : newStateValue
    );
  }, []); // keep object reference stable, exactly like `useState`

  useEffect(() => {
    // cbRef.current is `null` on initial render,
    // so we only invoke callback on state *updates*
    if (cbRef.current) {
      cbRef.current(
        forceCallbackWhenValueDontChange ? state.stateValue : state
      );
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);

  return [
    forceCallbackWhenValueDontChange ? state.stateValue : state,
    setStateCallback,
  ];
};

export const executeWhenInactiveForInterval = (
  callbackFn,
  checkInactivityInterval = 1000,
  executeCallbackAfterInterval = 5000
) => {
  // The number of mili seconds that have passed
  // since the user was active.
  let intervalSinceLastActivity = 0;

  // Setup the setInterval method to run
  // every inactivityMilliseconds
  const interval = setInterval(() => {
    intervalSinceLastActivity += checkInactivityInterval;
    // if the user has been inactive or idle for longer
    // then the seconds specified in maxInactivity
    if (intervalSinceLastActivity > executeCallbackAfterInterval) {
      clearInterval(interval);
      callbackFn();
    }
  }, checkInactivityInterval);

  // The function that will be called whenever a user is active
  function activity() {
    // reset the intervalSinceLastActivity constiable
    // back to 0
    intervalSinceLastActivity = 0;
  }

  // An array of DOM events that should be interpreted as
  // user activity.
  const activityEvents = [
    'mousedown',
    'mousemove',
    'keydown',
    'scroll',
    'touchstart',
  ];

  // add these events to the document.
  // register the activity function as the listener parameter.
  forEach(activityEvents, (eventName) => {
    document.addEventListener(eventName, activity, true);
  });

  return interval;
};

export const handlePasteFromExcel = (event, extraLineSeparators = [',']) => {
  // Stop data actually being pasted into div
  event.stopPropagation();
  event.preventDefault();

  // Get pasted data via clipboard API
  const clipboardData = event.clipboardData || window.clipboardData;
  let pastedData = clipboardData.getData('Text');

  // Do whatever with pasteddata
  const excelPasteCellSeparator = '|';
  pastedData = replace(pastedData, /\r\n/g, excelPasteCellSeparator);
  pastedData = replace(pastedData, /\n/g, excelPasteCellSeparator);
  pastedData = replace(pastedData, /\t/g, excelPasteCellSeparator);
  forEach(extraLineSeparators, (s) => {
    pastedData = replace(
      pastedData,
      new RegExp(s, 'g'),
      excelPasteCellSeparator
    );
  });
  pastedData = trimEnd(pastedData, excelPasteCellSeparator);

  const output = map(split(pastedData, excelPasteCellSeparator), (str) =>
    trim(str)
  );

  return output;
};

export const setPlannedBuyBackDate = (
  form,
  luxon,
  sourceDateFieldName = 'plannedInvoiceIssueDate',
  sourcePeriodFieldName = 'periodOfUseMonth',
  destinationDateFieldName = 'plannedBuyBackDate'
) => {
  const plannedInvoiceIssueDate = form.getState().values[sourceDateFieldName];
  if (plannedInvoiceIssueDate !== undefined) {
    form.mutators.changeValue(
      destinationDateFieldName,
      luxon
        .date(plannedInvoiceIssueDate)
        .plus({ months: form.getState().values[sourcePeriodFieldName] })
        .toFormat(DatePickerComponent.DATE_FORMAT_TO_POST)
    );
  }
};

export const setPeriodOfUseMonth = (
  newPlannedBuyBackDate,
  form,
  luxon,
  periodOfUses,
  destinationPeriodFieldName = 'periodOfUseMonth'
) => {
  const { plannedInvoiceIssueDate } = form.getState().values;

  if (
    plannedInvoiceIssueDate !== undefined &&
    !luxon.date(plannedInvoiceIssueDate).invalid &&
    periodOfUses?.items?.length &&
    !newPlannedBuyBackDate.invalid
  ) {
    form.mutators.changeValue(
      destinationPeriodFieldName,
      Math.max(
        Math.floor(
          newPlannedBuyBackDate.diff(luxon.date(plannedInvoiceIssueDate), [
            'months',
          ]).values.months
        ),
        periodOfUses?.items[0].value
      )
    );
  }
};

export const downloadFileShortWay = (
  setIsLoadingCallback,
  getGeneratedFilePromiseCallback,
  fallBackFileName,
  fileDownloadedCallback = noop
) => {
  setIsLoadingCallback(true);

  // eslint-disable-next-line promise/no-nesting
  getGeneratedFilePromiseCallback()
    .then((res) => {
      setIsLoadingCallback(false);
      fileDownloadedCallback();
      return Files.handleSaveAs(res, fallBackFileName);
    })
    .catch((err) => {
      setIsLoadingCallback(false);
      return ErrorHandler(err);
    });
};

export const downloadFile = (
  setIsLoadingCallback,
  getCreateFilePromiseCallback,
  getGeneratedFilePromiseCallback,
  fallBackFileName,
  fileDownloadedCallback = noop
) => {
  setIsLoadingCallback(true);
  getCreateFilePromiseCallback()
    .then((res1) => {
      const getPdf = () => {
        // eslint-disable-next-line promise/no-nesting
        getGeneratedFilePromiseCallback(res1.fileId)
          .then((res) => {
            setIsLoadingCallback(false);
            fileDownloadedCallback();
            if (res1?.repairStarted) {
              window.location.reload();
            }
            return Files.handleSaveAs(res, fallBackFileName);
          })
          .catch((err) => {
            if (err?.response?.status === 404) {
              // file not ready
              setTimeout(getPdf, CHECK_FOR_NEW_FILE_INTERVAL);
              return null;
            }
            setIsLoadingCallback(false);
            return ErrorHandler(err);
          });
      };

      setTimeout(getPdf, CHECK_FOR_NEW_FILE_INTERVAL);

      return res1;
    })
    .catch((err) => {
      setIsLoadingCallback(false);
      return ErrorHandler(err);
    });
};

export const mapAlertCodeToColor = (alertCode) => {
  switch (`${alertCode}`) {
    case '1': {
      return 'rgb(80, 80, 226)';
    }
    case '2': {
      return 'rgb(156, 226, 80)';
    }
    case '3': {
      return 'rgb(237, 101, 80)';
    }
    case '4': {
      return 'rgb(255, 204, 47)';
    }
    default: {
      return '#000';
    }
  }
};

export const arrayMove = (arr, oldIndex, newIndex) => {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

/**
 * creates new elementParameter in expertise repairs list "Kosztorys naprawy"
 *
 * @param {*} repairCostRowTemplateFn
 * @param {*} element
 * @param {*} location
 * @returns
 */
export const createNewItemGroupElement = (
  repairCostRowTemplateFn,
  element,
  location = null
) => ({
  elementParameterId: toNumber(element.value),
  elementParameterName: element.name,
  locationParameterId: location ? toNumber(location.value) : null,
  locationParameterName: location ? location.name : null,
  items: [repairCostRowTemplateFn()],
});

export const delayPromise = (msTime = 500) =>
  new Promise((resolve) =>
    setTimeout(() => {
      resolve();
    }, msTime)
  );

export const finalFormArraysRemove = (fields, fieldIndex) => {
  if (
    fields.value.length === 1 && // there is only one element
    fieldIndex === 0 // and removed is the 1st one
  ) {
    // hack, because they changed final-form-arrays that fields.remove() sets undefined if there is no elements after removal
    fields.changeValue([]);
  } else {
    fields.remove(fieldIndex);
  }
};

// checks if prop is used internally in the MUI components, see: https://mui.com/system/styled/
export const isPropUsedInternallyInMui = (prop) =>
  prop === 'ownerState' || prop === 'theme' || prop === 'sx' || prop === 'as';
