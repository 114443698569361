import { ApiCore } from '../_utilities/core';

const services = new ApiCore({
  url: '/inspection/services',
});

const service = new ApiCore({
  url: '/inspection/services/:id',
});

const servicesItems = new ApiCore({
  url: '/inspection/services/items',
});

export default {
  getServices: (queryString) => services.query({ queryString }).read(),
  getService: (id) => service.query({ urlParams: { id } }).read(),
  createService: (data) => services.create(data),
  updateService: (id, data) =>
    service.query({ urlParams: { id } }).update(data),
  deleteService: (id) => service.query({ urlParams: { id } }).delete(),
  getServicesItems: () => servicesItems.query().read(),
};
