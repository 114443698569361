import { ApiCore } from '../_utilities/core';

const insurers = new ApiCore({
  url: '/insurance/insurers',
});

const insurer = new ApiCore({
  url: '/insurance/insurers/:id',
});

const insurersNames = new ApiCore({
  url: '/insurance/insurers/names',
});

export default {
  getInsurers: (queryString) => insurers.query({ queryString }).read(),
  getInsurer: (id) => insurer.query({ urlParams: { id } }).read(),
  getNames: (queryString) => insurersNames.query({ queryString }).read(),
  createInsurer: (data) => insurers.create(data),
  updateInsurer: (id, data) =>
    insurer.query({ urlParams: { id } }).update(data),
  deleteInsurer: (id) => insurer.query({ urlParams: { id } }).delete(),
};
