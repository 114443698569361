export default (theme) => ({
  MuiDivider: {
    root: {
      backgroundColor: theme.palette.gray[200],
    },
  },
  MuiDrawer: {
    paper: {
      backgroundColor: theme.palette.gray[50],
    },
    paperAnchorDockedLeft: {
      borderRight: `1px solid ${theme.palette.gray[200]}`,
    },
  },
  MuiFormLabel: {
    root: {
      color: theme.palette.gray[300],

      '&.Mui-disabled': {
        color: theme.palette.gray[300],
      },
    },
  },
  MuiFormControl: {
    root: {
      '&.inputWithLabelWithTooltip .MuiFormLabel-root': {
        display: 'inline-flex',
      },
    },
  },
  MuiCheckbox: {
    colorPrimary: {
      color: theme.palette.gray[200],
    },
  },
  MuiRadio: {
    colorPrimary: {
      color: theme.palette.gray[200],
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: theme.sizes.icon,
    },
  },
  MuiListItemText: {
    inset: {
      paddingLeft: theme.sizes.icon,
    },
  },
  MuiTableRow: {
    root: {
      height: 55,
    },
  },
  MuiTable: {
    root: {
      borderTop: `none`,
      borderRight: `1px solid ${theme.palette.gray[200]}`,
    },
  },
  MuiTableContainer: {
    root: {
      minWidth: '100%',
      borderTop: 'none',
      borderBottom: `1px solid ${theme.palette.gray[200]}`,
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        WebkitAppearance: 'none',
      },
      '&::-webkit-scrollbar:horizontal': {
        height: 8,
      },
      '&::-webkit-scrollbar:vertical': {
        width: 8,
      },
      '&::-webkit-scrollbar-thumb': {
        // border: `2px solid ${theme.palette.common.white}`, // TODO: poprawić

        borderRadius: 4,
        backgroundColor: theme.palette.gray[200],
      },
    },
  },
  MuiTablePagination: {
    root: {
      height: '60px',
      minHeight: 0,
    },
    toolbar: {
      padding: '6px',
      height: '100%',
      minHeight: 0,
    },
  },
  MuiTypography: {
    body1: {
      '& strong': {
        fontFamily: 'Read-Bold',
      },
    },
    caption: {
      '& strong': {
        fontFamily: 'Read-Bold',
      },
    },
  },
  MuiSvgIcon: {
    root: {
      width: 20,
      height: 20,
    },
  },
  MuiPopover: {
    paper: {
      boxShadow: `0px 6px 6px -6px ${theme.palette.gray[300]}`,
    },
  },
  MuiToolbar: {
    regular: {
      minHeight: theme.sizes.toolbar.regular,
    },
    dense: {
      minHeight: theme.sizes.toolbar.dense,
    },
  },
  MuiPickersToolbar: {
    toolbar: {},
  },
  MuiPickersCalendarHeader: {
    switchHeader: {
      maxWidth: 260,
      margin: 'auto',
    },
    iconButton: { borderRadius: 0, marginBottom: 8 },
  },
  MuiPickersDay: {
    day: {
      borderRadius: 0,
    },
    daySelected: {},
    dayDisabled: {},
    current: {},
  },
  MuiPickersModal: {
    dialogAction: {},
  },
});
