import noop from 'lodash/noop';

export const handleResponse = (response) => {
  if (response.data) {
    // eslint-disable-next-line no-unused-vars
    const { data, ...rest } = response; // cut off "data" property, because it caused:  Converting circular structure to JSON in <Debugger>
    response.data.responseObject = rest; // to provide this object later on (it contains .headers prop)
    return response.data;
  }

  return response;
};

export const handleErrorWithPromise =
  (handler = noop) =>
  (err) => {
    handler(err);
    return Promise.reject(err);
  };
