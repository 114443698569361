import { Divider, Drawer, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { withStyles } from '@mui/styles';
import AutocompletePaper from 'app/components/shared/autocomplete-paper/autocomplete-paper';
import AutocompletePopper from 'app/components/shared/autocomplete-popper/autocomplete-popper';
import { LOCAL_STORAGE_MAIN_SIDEBAR_SUFIX } from 'app/core/constants';
import GlobalContext from 'app/core/global.context';
import {
  getLocalStorage,
  setLocalStorage,
} from 'app/core/local-storage-handler';
import { mapACLNavigation } from 'app/core/menu.utils';
import icons from 'app/core/svg';
import { withAuth } from 'app/core/with-auth';
import { getLocalStorageAttributeTitle } from 'app/helpers/table.helpers';
import cx from 'classnames';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import flow from 'lodash/flow';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import AppSpinner from '../app-spinner/app-spinner';
import SidebarMenu from './_parts/sidebar-menu/sidebar-menu';
import SidebarToolbar from './_parts/sidebar-toolbar/sidebar-toolbar';
import styles from './main-sidebar.jss';
function MainSidebar(props) {
  const h = useHistory();
  const globalContext = useContext(GlobalContext);
  const globalContextIsSidebarRenderedRef = useRef(false);
  globalContextIsSidebarRenderedRef.current = // this ref is set because in debounce() we have outdated globalContext.sidebarViews.isSidebarRendered value
    globalContext.sidebarViews.isSidebarRendered;
  const [isSidebarRenderedDebounced, setIsSidebarRenderedDebounced] =
    useState(false);
  const debounceRef = useRef(
    debounce(() => {
      setIsSidebarRenderedDebounced(globalContextIsSidebarRenderedRef.current);
    }, 1000)
  );
  const selectedView = find(globalContext.sidebarViews.viewsList, [
    'selected',
    true,
  ]);
  const { authService, classes, mainMenu, userMenu, userDataService, t } =
    props;
  const { profileName, userFullName, permissions } = userDataService;

  debounceRef.current();

  useEffect(() => {
    globalContext.sidebarViews.loadData();
  }, []);

  const [open, setOpen] = useState(
    Boolean(
      getLocalStorage(
        getLocalStorageAttributeTitle(
          'app',
          userDataService.userId,
          LOCAL_STORAGE_MAIN_SIDEBAR_SUFIX
        ),
        1
      )
    )
  );

  const handleDrawerOpen = () => {
    setLocalStorage(
      getLocalStorageAttributeTitle(
        'app',
        userDataService.userId,
        LOCAL_STORAGE_MAIN_SIDEBAR_SUFIX
      ),
      1
    );

    setOpen(true);
  };

  const handleDrawerClose = () => {
    setLocalStorage(
      getLocalStorageAttributeTitle(
        'app',
        userDataService.userId,
        LOCAL_STORAGE_MAIN_SIDEBAR_SUFIX
      ),
      0
    );
    setOpen(false);
  };

  if (!authService.isLoggedIn) {
    return null;
  }

  const autocompleteOptions = () => {
    const options = [...mainMenu, ...userMenu];

    const res = mapACLNavigation(
      options,
      { profileName, userFullName },
      icons,
      permissions
    );

    return flatMap(res, (r) => {
      if (!isEmpty(r.children))
        return map(r.children, (c) => ({ ...c, category: r.tooltip }));

      return r;
    });
  };

  const autocompleteHandleChange = (e, newInputValue) => {
    if (!isNil(newInputValue)) {
      h.push(newInputValue.path);
    }
  };

  return (
    <Drawer
      id='mainSidebarRoot'
      variant='permanent'
      className={cx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: cx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <SidebarToolbar
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
        open={open}
      />
      <Divider />
      <SidebarMenu
        disableScrollbars
        menuItems={[
          ...(isSidebarRenderedDebounced
            ? [
                {
                  id: 'viewsMenuOption',
                  text: t('mainMenu_views'),
                  subtext: selectedView?.name,
                  onClick: () => {
                    globalContext.setSidebarViews((prevSidebarViews) => ({
                      ...prevSidebarViews,
                      isOpen: !prevSidebarViews.isOpen,
                    }));
                  },
                  ...(globalContext.sidebarViews.isLoading
                    ? {
                        Icon: (
                          <AppSpinner
                            size={20}
                            classes={{ root: cx(classes.menuAppSpinner) }}
                          />
                        ),
                      }
                    : { Icon: icons.Sidebar }),
                  allowedPermissions: [],
                },
              ]
            : []),
        ]}
        open={open}
      />
      <SidebarMenu
        classes={{ root: cx(classes.mainMenu) }}
        menuItems={mapACLNavigation(mainMenu, {}, icons, permissions)}
        open={open}
      />
      <Autocomplete
        id='combo-box-routes-search'
        classes={{
          root: cx(classes.autocompleteRoot),
          listbox: cx(classes.autocompleteListbox),
          option: cx(classes.autocompletePopperOption),
        }}
        options={autocompleteOptions()}
        getOptionLabel={(option) => {
          if (option.hasOwnProperty('tooltip')) {
            return option.tooltip;
          }
          return option;
        }}
        PopperComponent={AutocompletePopper}
        PaperComponent={AutocompletePaper}
        groupBy={(option) => option.category}
        renderInput={(params) => (
          <TextField
            variant='standard'
            {...params}
            placeholder={t('common_search')}
            label=''
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              classes: { root: cx(classes.autocompleteInput) },
            }}
          />
        )}
        noOptionsText={t('common_lack')}
        clearText={t('common_clear')}
        closeText={t('common_close')}
        onChange={autocompleteHandleChange}
      />
      <Divider />
      <SidebarMenu
        classes={{ root: cx(classes.userMenu) }}
        disableScrollbars
        menuItems={mapACLNavigation(
          userMenu,
          {
            profileName,
            userFullName,
          },
          icons,
          permissions
        )}
        open={open}
      />
    </Drawer>
  );
}

MainSidebar.propTypes = {
  authService: PropTypes.object,
  classes: PropTypes.object,
  mainMenu: PropTypes.array,
  userMenu: PropTypes.array,
  userDataService: PropTypes.object,
  t: PropTypes.func,
};

MainSidebar.defaultProps = {
  authService: {},
  classes: {},
  mainMenu: null,
  userMenu: null,
  userDataService: {},
  t: noop,
};

export default flow([withStyles(styles), withTranslation(), withAuth])(
  MainSidebar
);
