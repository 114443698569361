import { PUBLIC_URL } from 'app/core/constants';

const ReadBoldWoff = `${PUBLIC_URL}/assets/fonts/Read/Read-Bold_V1100.woff`;
const ReadWoff = `${PUBLIC_URL}/assets/fonts/Read/Read-Regular_V1100.woff`;
const RenaultLifeBoldWoff = `${PUBLIC_URL}/assets/fonts/RenaultLife/RenaultLife-Bold.woff`;
const RenaultLifeWoff = `${PUBLIC_URL}/assets/fonts/RenaultLife/RenaultLife-Regular.woff`;

export const renaultLifeBold = {
  '@font-face': {
    fontFamily: 'RenaultLife-Bold',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 700,
    src: `
    local('RenaultLife'),
    local('RenaultLife-Bold'),
    url(${RenaultLifeBoldWoff}) format('woff')
  `,
  },
};

export const renaultLife = {
  '@font-face': {
    fontFamily: 'RenaultLife',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
    local('RenaultLife'),
    local('RenaultLife-Regular'),
    url(${RenaultLifeWoff}) format('woff')
  `,
  },
};

export const readBold = {
  '@font-face': {
    fontFamily: 'Read-Bold',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 700,
    src: `
    local('Read'),
    local('Read-Bold'),
    url(${ReadBoldWoff}) format('woff')
  `,
  },
};

export const read = {
  '@font-face': {
    fontFamily: 'Read',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
    local('Read'),
    local('Read-Regular'),
    url(${ReadWoff}) format('woff')
  `,
  },
};
