/* eslint-disable no-useless-escape */
// from http://emailregex.com/
export const EMAIL_REGEX =
  /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\da-z\-]+\.)+[a-z]{2,}))$/i;

// semicolon separated list of emails
export const EMAIL_MULTI_REGEX =
  /^([a-z0-9,!#\$%&'\*\+/=\?\^_`\{\|}~-]+(\.[a-z0-9,!#\$%&'\*\+/=\?\^_`\{\|}~-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*\.([a-z]{2,})){1}(;[a-z0-9,!#\$%&'\*\+/=\?\^_`\{\|}~-]+(\.[a-z0-9,!#\$%&'\*\+/=\?\^_`\{\|}~-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*\.([a-z]{2,}))*$/g;
// The password requires at least one digit,
// The password requires at least one lower character,
// The password requires at least one non-alphanumeric character,
// The password requires at least one upper character,
// The password requires at least 6 characters
export const PASSWORD_REGEX =
  /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/g;
export const PHONE_NUMBER_REGEX = /^[+]?[0-9\s]*$/;

export const PAGE_SIZES = [10, 25, 50, 100, 200];
export const DEFAULT_PAGE_SIZE = 50;

export const {
  PUBLIC_URL,
  NODE_ENV,
  REACT_APP_ENV,
  REACT_APP_VERSION,
  REACT_APP_CSB_PATH,
  REACT_APP_API_URL,
} = process.env;

export const TABLE_ROW_DARKEN_COLOR_LEVEL = -20;

export const STATUS_FLOW_MODES = {
  Manual: 'Manual',
  Auto: 'Auto',
};

export const PACKAGE_BUYING_METHODS = {
  Auction: 'Auction',
  Reservation: 'Reservation',
};

export const VEHICLE_PACKAGE_STATUSES = {
  Created: 'Created',
  Published: 'Published',
  Active: 'Active',
  Reserved: 'Reserved',
  Sold: 'Sold',
  Unsold: 'Unsold',
};

export const SALES_ACTION_STATUSES = {
  Created: 'Created',
  Published: 'Published',
  Active: 'Active',
};

export const INSPECTION_SERVICES_EXECUTION_STATUS = {
  Yes: 'Yes',
  No: 'No',
};

export const REPAIR_ORDER_PRIORITIES = {
  Low: 'Low',
  Mid: 'Mid',
  High: 'High',
};

export const VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES = {
  Document: 'Document',
  Equipment: 'Equipment',
  TireSet: 'TireSet',
};

export const DEPRECIATION_NAME_NONE_VALUE = 'none';

export const LOCAL_STORAGE_SORT_SUFIX = '#SORT_PlanOdkupow20';
export const LOCAL_STORAGE_PAGE_NO_SUFIX = '#PageNo_PlanOdkupow20';
export const LOCAL_STORAGE_PAGE_SIZE_SUFIX = '#PageSize_PlanOdkupow20';
export const LOCAL_STORAGE_BASE_URL = 'Base_Url_PlanOdkupow20';
export const LOCAL_STORAGE_MAIN_SIDEBAR_SUFIX = '#Main_Sidebar_PlanOdkupow20';
export const APP_VERSION_SUFIX = '#App_Version_PlanOdkupow20';

export const NONE_PERMISSION_SHORTCUT = 'NONE';

export const PERMISSION_CAN_READ = 'Read';
export const PERMISSION_CAN_CHANGE = 'Change';
export const PERMISSION_CAN_DELETE = 'Delete';

export const PERMISSION_FILES_STORAGE = 'STORAGE';
export const PERMISSION_BUYPACK_INVOICE = 'STORAGE.BBK';
export const PERMISSION_INSURANCE = 'STORAGE.POL';
export const PERMISSION_NOTIFICATION = 'STORAGE.NOT';
export const PERMISSION_OTHER_FILES = 'STORAGE.OTH';
export const PERMISSION_PURCHASE_INVOICE = 'STORAGE.PUR';
export const PERMISSION_RESALE_INVOICE = 'STORAGE.RES';
export const PERMISSION_INSURANCE_TERMINATION = 'STORAGE.TER';

export const LOCAL_STORAGE_MAKE_COPY_INITIAL_VALUES = 'copyInitialValuesJSON';

export const LOCAL_STORAGE_SIDEBAR_SCHEDULES_FILTER_TABS_SETTINGS =
  'SIDEBAR_SCHEDULES_filterTabsSettings';

export const LOCAL_STORAGE_SIDEBAR_STORAGE_DETAILS_SUMMARY_FILTER_TABS_SETTINGS =
  'SIDEBAR_STORAGE_DETAILS_SUMMARY_filterTabsSettings';

export const PROLONG_LOCK_VEHICLE_INTERVAL = 30000;

export const CHECK_FOR_NEW_FILE_INTERVAL = 3000;

export const TABLE_TEXT_FILTER_SEPARATOR = ',';
export const TABLE_HEADER_COLUMN_NEW_LINE_SEPARATOR = '\n';

export const VEHICLE_EXPERTISES_TAKEN_COMMENTS_SEPARATOR = '\r\n';

export const TABLE_COLUMN_ALERTS_SEPARATOR = ',';

export const SIDEBARS_LAST_ROW_SEPARATOR = '_';

// details about statuses: https://confluence.makolab.net/pages/viewpage.action?pageId=149722302
export const INVOICE_IMPORTS_ITEM_STATUSES = {
  Pending: 'Pending',
  Complete: 'Complete',
  Incomplete: 'Incomplete',
  Failed: 'Failed',
  Modified: 'Modified',
  CheckNeeded: 'CheckNeeded',
  Imported: 'Imported',
};

// details about statuses: https://confluence.makolab.net/pages/viewpage.action?pageId=149722302
export const INVOICE_IMPORTS_STATUSES = {
  Created: 'Created',
  InProgress: 'InProgress',
  SuccessfullyCompleted: 'SuccessfullyCompleted',
  CompletedWithErrors: 'CompletedWithErrors',
};

export const VEHICLE_OFFERS_OFFER_TYPES = {
  Str: 'Str',
  Direct: 'Direct',
};

export const VEHICLE_EXPERTISES_CONTROL_ELEMENT_TO_REPAIR_MAPPING = {
  uat_aws: [
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 10, // 2 komplety kluczyków / kart
      },
      elementParameter: {
        id: 439,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 11, // Koło zapasowe / zestaw naprawczy
      },
      elementParameter: {
        id: 440,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 12, // Podnośnik
      },
      elementParameter: {
        id: 441,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 13, // Uchwyt holowniczy
      },
      elementParameter: {
        id: 442,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 15, // Klucz do kół
      },
      elementParameter: {
        id: 443,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 16, // Kluczyk do felg lub kołpaków
      },
      elementParameter: {
        id: 444,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 17, // Kołpaki oryginalne 4 szt
      },
      elementParameter: {
        id: 26,
        locationParameterId: 100,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 18, // Apteczka
      },
      elementParameter: {
        id: 2,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 19, // Trójkąt
      },
      elementParameter: {
        id: 446,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 20, // Antena
      },
      elementParameter: {
        id: 1,
        locationParameterId: 100,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 21, // Gaśnica
      },
      elementParameter: {
        id: 19,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 22, // Felgi aluminiowe 4 szt
      },
      elementParameter: {
        id: 18,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 23, // Felgi stalowe
      },
      elementParameter: {
        id: 449,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 24, // Dywaniki
      },
      elementParameter: {
        id: 13,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 25, // Roleta / półka bagażnika
      },
      elementParameter: {
        id: 65,
        locationParameterId: 102,
      },
    },
  ],
  qa_aws: [
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 10, // 2 komplety kluczyków / kart
      },
      elementParameter: {
        id: 433,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 11, // Koło zapasowe / zestaw naprawczy
      },
      elementParameter: {
        id: 434,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 12, // Podnośnik
      },
      elementParameter: {
        id: 435,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 13, // Uchwyt holowniczy
      },
      elementParameter: {
        id: 436,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 15, // Klucz do kół
      },
      elementParameter: {
        id: 437,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 16, // Kluczyk do felg lub kołpaków
      },
      elementParameter: {
        id: 438,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 17, // Kołpaki oryginalne 4 szt
      },
      elementParameter: {
        id: 439,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 18, // Apteczka
      },
      elementParameter: {
        id: 2,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 19, // Trójkąt
      },
      elementParameter: {
        id: 432,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 20, // Antena
      },
      elementParameter: {
        id: 1,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 21, // Gaśnica
      },
      elementParameter: {
        id: 19,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 22, // Felgi aluminiowe 4 szt
      },
      elementParameter: {
        id: 18,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 23, // Felgi stalowe
      },
      elementParameter: {
        id: 440,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 24, // Dywaniki
      },
      elementParameter: {
        id: 13,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 25, // Roleta / półka bagażnika
      },
      elementParameter: {
        id: 65,
        locationParameterId: 101,
      },
    },
  ],
  development: [
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 10, // 2 komplety kluczyków / kart
      },
      elementParameter: {
        id: 433,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 11, // Koło zapasowe / zestaw naprawczy
      },
      elementParameter: {
        id: 434,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 12, // Podnośnik
      },
      elementParameter: {
        id: 435,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 13, // Uchwyt holowniczy
      },
      elementParameter: {
        id: 436,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 15, // Klucz do kół
      },
      elementParameter: {
        id: 437,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 16, // Kluczyk do felg lub kołpaków
      },
      elementParameter: {
        id: 438,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 17, // Kołpaki oryginalne 4 szt
      },
      elementParameter: {
        id: 439,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 18, // Apteczka
      },
      elementParameter: {
        id: 2,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 19, // Trójkąt
      },
      elementParameter: {
        id: 432,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 20, // Antena
      },
      elementParameter: {
        id: 1,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 21, // Gaśnica
      },
      elementParameter: {
        id: 19,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 22, // Felgi aluminiowe 4 szt
      },
      elementParameter: {
        id: 18,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 23, // Felgi stalowe
      },
      elementParameter: {
        id: 440,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 24, // Dywaniki
      },
      elementParameter: {
        id: 13,
        locationParameterId: 101,
      },
    },
    {
      controlElement: {
        type: VEHICLE_EXPERTISES_CONTROL_ELEMENT_TYPES.Equipment,
        id: 25, // Roleta / półka bagażnika
      },
      elementParameter: {
        id: 65,
        locationParameterId: 101,
      },
    },
  ],
};
VEHICLE_EXPERTISES_CONTROL_ELEMENT_TO_REPAIR_MAPPING.prod =
  VEHICLE_EXPERTISES_CONTROL_ELEMENT_TO_REPAIR_MAPPING.uat_aws;
VEHICLE_EXPERTISES_CONTROL_ELEMENT_TO_REPAIR_MAPPING.dev_aws =
  VEHICLE_EXPERTISES_CONTROL_ELEMENT_TO_REPAIR_MAPPING.development;
