import 'app/helpers/schema-validation.helper';

import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { ConnectedRouter } from 'connected-react-router';
import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import React, { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { Slide, ToastContainer } from 'react-toastify';
import * as Yup from 'yup';

import theme from '../styles/theme';
import AppRouting from './app-routing';
import AppSpinner from './components/shared/app-spinner/app-spinner';
import AppVersion from './components/shared/app-version/app-version';
import { GlobalContextProvider } from './core/global.context';
import { path } from './core/utils';
import { history, store } from './store';

// this ContentWrapper is here because useTranslation have to be called inside <Suspense>
const ContentWrapper = ({ children }) => {
  const { t } = useTranslation();

  Yup.setLocale({
    mixed: {
      notType: t('common_validation_typeError'),
    },
  });

  return <>{children}</>;
};

ContentWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

ContentWrapper.defaultProps = {
  children: React.Fragment,
};

export const App = () => {
  useEffect(() => {
    const interval = setInterval(() => {
      if (
        isNil(document.getElementById('mainSidebarRoot')) ||
        isNil(document.getElementById('listContainer'))
      ) {
        return;
      }

      // region calculate windowWidth & windowHeight
      const tempElement = document.createElement('div');
      tempElement.style.width = '100%';
      tempElement.style.height = '100%';
      tempElement.style.zIndex = '-99999';
      tempElement.style.position = 'absolute';
      document.getElementsByTagName('body')[0].appendChild(tempElement);

      const tempElement2 = document.createElement('div');
      tempElement.style.width = '100vw';
      tempElement.style.height = '100vh';
      tempElement.style.zIndex = '-99999';
      tempElement.style.position = 'absolute';
      document.getElementsByTagName('body')[0].appendChild(tempElement2);

      // const browsersTollbarHeight =
      //   tempElement2.offsetHeight - // take it's height because it is set to 100vh
      //   tempElement.offsetHeight; // this ones height is 100%
      const windowWidth = tempElement.offsetWidth;
      const windowHeight = tempElement.offsetHeight;

      // alert(`width te: ${tempElement.offsetWidth}, te2: ${tempElement2.offsetWidth}, `);
      // alert(`heigth te: ${tempElement.offsetHeight}, te2: ${tempElement2.offsetHeight}, `);

      document.getElementsByTagName('body')[0].removeChild(tempElement);
      document.getElementsByTagName('body')[0].removeChild(tempElement2);
      // endregion calculate windowWidth & windowHeight

      document.getElementById('listContainer').childNodes[0].style.height = `${
        windowHeight -
        document.getElementById('tablePaginationRoot').offsetHeight
      }px`;
      document.getElementById('tablePaginationRoot').style.width = `${
        windowWidth - document.getElementById('mainSidebarRoot').offsetWidth
      }px`;

      let footerContentWidth = 0;
      document.getElementById('columnsFinder').style.display = 'block'; // show it for a while to calculate width
      forEach(
        document.getElementById('tablePaginationRoot').childNodes,
        (child) => {
          footerContentWidth += child.offsetWidth;
        }
      );

      if (
        document.getElementById('tablePaginationRoot').offsetWidth <
        footerContentWidth
      ) {
        document.getElementById('columnsFinder').style.display = 'none';
      } else {
        document.getElementById('columnsFinder').style.display = 'block';
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Suspense fallback={<AppSpinner />}>
          <Provider store={store}>
            <ConnectedRouter history={history} basename={path('')}>
              <GlobalContextProvider>
                <ContentWrapper>
                  <AppRouting />
                  <AppVersion />
                  <ToastContainer
                    autoClose={4000}
                    closeButton={false}
                    hideProgressBar
                    limit={1}
                    newestOnTop
                    pauseOnHover
                    position='top-center'
                    transition={Slide}
                  />
                </ContentWrapper>
              </GlobalContextProvider>
            </ConnectedRouter>
          </Provider>
        </Suspense>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
