import { ApiCore } from '../_utilities/core';

const segments = new ApiCore({
  url: '/vehicle/segments',
});

const segment = new ApiCore({
  url: '/vehicle/segments/:id',
});

const segmentByCode = new ApiCore({
  url: '/vehicle/segments/:code/data',
});

const segmentsCodes = new ApiCore({
  url: '/vehicle/segments/codes',
});

const segmentsVehicleTypes = new ApiCore({
  url: '/vehicle/segments/vehicle-types',
});

export default {
  getSegments: (queryString) => segments.query({ queryString }).read(),
  getSegment: (id) => segment.query({ urlParams: { id } }).read(),
  getSegmentByCode: (code) =>
    segmentByCode.query({ urlParams: { code } }).read(),
  createSegment: (data) => segments.create(data),
  updateSegment: (id, data) =>
    segment.query({ urlParams: { id } }).update(data),
  deleteSegment: (id) => segment.query({ urlParams: { id } }).delete(),
  getCodes: (queryString) => segmentsCodes.query({ queryString }).read(),
  getVehicleTypes: (queryString) =>
    segmentsVehicleTypes.query({ queryString }).read(),
};
