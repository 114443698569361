import { Popper } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const AutocompletePopper = ({ maxWidth, ...rest }) => (
  <Popper {...rest} style={{ maxWidth }} placement='bottom-start' />
);

AutocompletePopper.propTypes = {
  maxWidth: PropTypes.number,
};

AutocompletePopper.defaultProps = {
  maxWidth: 300,
};

export default AutocompletePopper;
