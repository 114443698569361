import { ApiCore } from '../_utilities/core';

const numberingSchemas = new ApiCore({
  url: '/finance/numbering-schemas',
});

const numberingSchema = new ApiCore({
  url: '/finance/numbering-schemas/:id',
});

export default {
  getNumberingSchemas: (queryString) =>
    numberingSchemas.query({ queryString }).read(),
  getNumberingSchema: (id) =>
    numberingSchema.query({ urlParams: { id } }).read(),
  createNumberingSchema: (data) => numberingSchemas.create(data),
  updateNumberingSchema: (id, data) =>
    numberingSchema.query({ urlParams: { id } }).update(data),
  deleteNumberingSchema: (id) =>
    numberingSchema.query({ urlParams: { id } }).delete(),
  getNumberingSchemaItems: (queryString) =>
    new ApiCore({
      url: '/finance/numbering-schemas/items',
    })
      .query({ queryString })
      .read(),
};
