import { saveAs } from 'file-saver';
import camelCase from 'lodash/camelCase';
import kebabCase from 'lodash/kebabCase';
import split from 'lodash/split';

import { ApiCore } from '../_utilities/core';

const files = new ApiCore({
  url: '/storage/files/:moduleName/:objectId',
});

const docsType = new ApiCore({
  url: '/storage/files/:moduleName/:objectId/:type',
});

const docsFileId = new ApiCore({
  url: '/storage/files/:moduleName/:objectId/:type/:fileId',
  responseType: 'blob',
});

const csbFileImg = new ApiCore({
  url: '/storage/files/:moduleName/:objectId/other/Image',
  responseType: 'blob',
});

const qRCodeImage = new ApiCore({
  url: '/storage/files/:moduleName/:objectId/other/QRCode',
  responseType: 'blob',
});

const schedulesExport = new ApiCore({
  url: '/vehicle/schedules/:scheduleType/export',
  responseType: 'blob',
});

export default {
  /**
   * This is not API function, it is helper function, but it is highly related with API functions below,
   * so I put it in this file.
   * Call it to handle browser's "saveAs"
   *
   * @param {Object} res Object provided for catch() by below API methods, the one returned by handleResponse() in \app\core\api\_utilities\response.js
   * @param {string} fallBackFileName Optional, if provided this name will be used if name will not be found in headers
   */
  handleSaveAs: (res, fallBackFileName) => {
    const resp = res.responseObject || res;

    const fileName =
      resp &&
      resp.headers &&
      resp.headers['content-disposition'] && // content-disposition contains someting like: "attachment; filename=fiszka.xlsx; filename*=UTF-8''fiszka.xlsx"
      split(resp.headers['content-disposition'], '; ')[1] &&
      split(split(resp.headers['content-disposition'], '; ')[1], '=')[1];

    function removeUnderscores(str) {
      if (str && str.startsWith('"')) {
        str = str.slice(1);
      }
      if (str && str.endsWith('"')) {
        str = str.slice(0, -1);
      }
      return str;
    }

    const fileNameNoQoute = removeUnderscores(fileName);

    saveAs(res, fileNameNoQoute || fallBackFileName || `downloadedFile.ext`);
  },
  getFiles: (moduleName, objectId) =>
    files
      .query({ urlParams: { moduleName: camelCase(moduleName), objectId } })
      .read(),
  createDocument: (moduleName, objectId, type, data) =>
    docsType
      .query({
        urlParams: {
          moduleName: camelCase(moduleName),
          objectId,
          type: kebabCase(type),
        },
      })
      .create(data),
  updateDocument: (moduleName, objectId, type, data) =>
    docsType
      .query({
        urlParams: {
          moduleName: camelCase(moduleName),
          objectId,
          type: kebabCase(type),
        },
      })
      .update(data),
  deleteDocument: (moduleName, objectId, type, fileId) =>
    docsFileId
      .query({
        urlParams: {
          moduleName: camelCase(moduleName),
          objectId,
          type: kebabCase(type),
          fileId,
        },
      })
      .delete(),
  getDocument: (moduleName, objectId, type, fileId) =>
    docsFileId
      .query({
        urlParams: {
          moduleName: camelCase(moduleName),
          objectId,
          type: kebabCase(type),
          fileId,
        },
      })
      .read(),
  getTableExport: (apiNamespace, moduleName, queryString) =>
    new ApiCore({
      url: `/${apiNamespace}/${moduleName}/export`,
      responseType: 'blob',
    })
      .query({ queryString })
      .read(),
  getGenerateRegistrationPdf: (apiNamespace, moduleName, fileId) =>
    new ApiCore({
      url: `/${apiNamespace}/${moduleName}/pdf/:fileId`,
      responseType: 'blob',
    })
      .query({
        urlParams: {
          fileId,
        },
      })
      .read(),
  getGenerateExpertisePdf: (id, fileId) =>
    new ApiCore({
      url: `/expertise/vehicle-expertises/:id/printout/:fileId`,
      responseType: 'blob',
    })
      .query({
        urlParams: {
          id,
          fileId,
        },
      })
      .read(),
  getGenerateExportRciPdf: (fileId) =>
    new ApiCore({
      url: `/finance/documents/exports/RCI/:fileId/result`,
      responseType: 'blob',
    })
      .query({
        urlParams: {
          fileId,
        },
      })
      .read(),
  getGenerateExpertisePhotosPdf: (expertiseId, fileId) =>
    new ApiCore({
      url: `/expertise/photos/:expertiseId/printout/:fileId`,
      responseType: 'blob',
    })
      .query({
        urlParams: {
          expertiseId,
          fileId,
        },
      })
      .read(),
  getExpertiseWorkSheetPdf: (id, fileId) =>
    new ApiCore({
      url: `/expertise/vehicle-expertises/:id/work-sheet/:fileId`,
      responseType: 'blob',
    })
      .query({
        urlParams: {
          id,
          fileId,
        },
      })
      .read(),
  getGeneratedFleetReportExport: (fileId) =>
    new ApiCore({
      url: `/vehicle/fleetraports/:fileId`,
      responseType: 'blob',
    })
      .query({
        urlParams: {
          fileId,
        },
      })
      .read(),
  getGenerateVehiclePackagePdf: (id, fileId) =>
    new ApiCore({
      url: `/offer/vehicle-packages/:id/printout/:fileId`,
      responseType: 'blob',
    })
      .query({
        urlParams: {
          id,
          fileId,
        },
      })
      .read(),
  getGenerateFinancePdf: (id) =>
    new ApiCore({
      url: `/finance/documents/:id/attachments/printout`,
      responseType: 'blob',
    })
      .query({
        urlParams: {
          id,
        },
      })
      .read(),
  getGenerateFinancePdfName: (id) =>
    new ApiCore({
      url: `/finance/documents/:id/attachments/printout`,
    })
      .query({
        urlParams: {
          id,
        },
      })
      .read(),
  getCsbImg: (moduleName, objectId) =>
    csbFileImg
      .query({
        urlParams: {
          moduleName: camelCase(moduleName),
          objectId,
        },
      })
      .read(),
  getQRCodeImageUrl: (moduleName, objectId) =>
    qRCodeImage
      .query({
        urlParams: {
          moduleName: camelCase(moduleName),
          objectId,
        },
      })
      .read(),
  getSchedulesExport: (queryString, scheduleType) =>
    schedulesExport
      .query({
        queryString,
        urlParams: { scheduleType },
      })
      .read(),
  getStorageDetailsSummaryExport: (queryString) =>
    new ApiCore({
      url: '/report/storage-details/summary/export',
      responseType: 'blob',
    })
      .query({
        queryString,
      })
      .read(),
  getExpertisePhotosZIP: (queryString, expertiseId) =>
    new ApiCore({
      url: '/expertise/photos/:expertiseId/zip',
      responseType: 'blob',
    })
      .query({
        queryString,
        urlParams: { expertiseId },
      })
      .read(),
};
