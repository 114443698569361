import { getUnixTime } from 'date-fns';
import jwtDecode from 'jwt-decode';
import flatMap from 'lodash/flatMap';
import map from 'lodash/map';
import pullAt from 'lodash/pullAt';
import trim from 'lodash/trim';
import words from 'lodash/words';

const parsePermissions = (permissions) =>
  flatMap(permissions, (module) => {
    const perms = words(module, /\[.*?\]/g);
    // eslint-disable-next-line lodash/prefer-immutable-method
    const name = pullAt(perms, [0]);
    return map(perms, (perm) => `[${trim(name, '[]')}:${trim(perm, '[]')}]`);
  });

export const parseJWT = (token) => {
  const requiredFields = [
    'aud',
    'exp',
    'iss',
    'permission',
    'profileName',
    'sessionId',
    'userFullName',
    'userId',
    'userName',
  ];
  let tokenData = null;
  let testRequiredFields = [false];

  try {
    tokenData = jwtDecode(token);
    testRequiredFields = map(requiredFields, (field) =>
      tokenData.hasOwnProperty(field)
    );
  } catch (error) {
    console.error(
      'Error while parsing JWT token',
      requiredFields,
      testRequiredFields,
      error
    );
    tokenData = null;
  }

  return {
    ...tokenData,
    permission: parsePermissions(tokenData?.permission),
  };
};

export const isTokenExpired = (token) => {
  const tokenData = parseJWT(token);
  let result = true;

  if (tokenData && tokenData.exp) {
    const currentDate = getUnixTime(new Date());
    const expirationDate = tokenData.exp;
    result = expirationDate < currentDate;
  }

  return result;
};
