import { ApiCore } from '../_utilities/core';

const additionalEquipments = new ApiCore({
  url: '/vehicle/additional-equipments',
});

const additionalEquipmentsItems = new ApiCore({
  url: '/vehicle/additional-equipments/items',
});

const additionalEquipment = new ApiCore({
  url: '/vehicle/additional-equipments/:id',
});

export default {
  getAdditionalEquipments: (queryString) =>
    additionalEquipments.query({ queryString }).read(),
  getAdditionalEquipment: (id) =>
    additionalEquipment.query({ urlParams: { id } }).read(),
  createAdditionalEquipment: (data) => additionalEquipments.create(data),
  updateAdditionalEquipment: (id, data) =>
    additionalEquipment.query({ urlParams: { id } }).update(data),
  deleteAdditionalEquipment: (id) =>
    additionalEquipment.query({ urlParams: { id } }).delete(),
  getAdditionalEquipmentsItems: () => additionalEquipmentsItems.query().read(),
};
