import { ApiCore, useRequest } from '../_utilities/core';

const storageDays = new ApiCore({
  url: '/report/storage-details',
});

const storageDaysExport = new ApiCore({
  url: '/report/storage-details/export',
});

export default {
  getStorageDetails: (queryString) => storageDays.query({ queryString }).read(),
  getStorageDetailsExport: (queryString) =>
    storageDaysExport.query({ queryString }).read(),

  /**
   *
   * @returns {Array} Returns following array:
   * ```
   * [
   *    {
   *       data: response, // the response object
   *       isLoading: true, // indicates if it is still loading
   *       error: err, // err object from .catch(err),
   *    },
   *    doRequest(queryString) // method that executes the request
   * ]
   * ```
   */
  useStorageDetailsSummaryRequest: () =>
    useRequest((queryString, isProgress) =>
      new ApiCore({
        url: `/report/storage-details/summary${isProgress ? '/progress' : ''}`,
      })
        .query({
          queryString,
        })
        .read()
    ),
};
