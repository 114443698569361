import * as React from 'react';
function SvgFile(props) {
  return (
    <svg width={21} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <g fill='none' fillRule='evenodd'>
        <path d='M-1150-183H290v800h-1440z' />
        <path
          d='M14.875 7.998C13.979 6.822 13.208 5.935 12.25 5v2.998h2.625zm0 1.002h-3.5V5h-5.25v14h8.75V9zm.875-1.282V20H5.25V4h7.232c1.265 1.2 2.404 2.471 3.268 3.718z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
export default SvgFile;
