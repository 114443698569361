import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import persistState from 'redux-localstorage';
import thunkMiddleware from 'redux-thunk';

import i18n from '../i18n';
import * as api from './core/api';
import * as axiosProvider from './core/api/_utilities/provider';
import { handleErrorWithPromise } from './core/api/_utilities/response';
import { path } from './core/utils';
import { createReducers } from './reducers';

export const history = createBrowserHistory({
  basename: path('/'),
});

const serialize = (subset) => {
  if (process.env.NODE_ENV === 'production') {
    return btoa(JSON.stringify(subset));
  }

  return JSON.stringify(subset);
};

const deserialize = (serializedData) => {
  if (process.env.NODE_ENV === 'production') {
    // atob of null (empty localstorage value) is not a valid json to parse
    return JSON.parse(atob(serializedData || btoa('{}')));
  }

  return JSON.parse(serializedData);
};

export const store = createStore(
  createReducers(history),
  compose(
    applyMiddleware(
      routerMiddleware(history),
      thunkMiddleware.withExtraArgument({
        api,
        history,
        i18n,
        handleErrorWithPromise,
      })
    ),
    persistState(['authData'], {
      serialize,
      deserialize,
    }),
    ...(window.__REDUX_DEVTOOLS_EXTENSION__
      ? [window.__REDUX_DEVTOOLS_EXTENSION__()]
      : [])
  )
);

axiosProvider.init({ store, history, i18n });
