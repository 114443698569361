import { ApiCore } from '../_utilities/core';

const registrationOffices = new ApiCore({
  url: '/registration/registration-offices',
});

const registrationOffice = new ApiCore({
  url: '/registration/registration-offices/:id',
});

export default {
  getRegistrationOffices: (queryString) =>
    registrationOffices.query({ queryString }).read(),
  getRegistrationOffice: (id) =>
    registrationOffice.query({ urlParams: { id } }).read(),
  createRegistrationOffice: (data) => registrationOffices.create(data),
  updateRegistrationOffice: (id, data) =>
    registrationOffice.query({ urlParams: { id } }).update(data),
  deleteRegistrationOffice: (id) =>
    registrationOffice.query({ urlParams: { id } }).delete(),
};
