const drawerWidth = 220;
const drawerWidthMin = 60;

export default ({ transitions, commonStyles }) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: transitions.create('width', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  },
  drawerClose: {
    transition: transitions.create('width', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: drawerWidthMin,
  },
  mainMenu: {
    flex: 1,
    overflow: 'hidden',
  },
  menuAppSpinner: {
    position: 'relative',
  },
  userMenu: {
    flex: 0,
  },
  ...commonStyles.autoComplete,
});
