import { ApiCore } from '../_utilities/core';

const vehicleInspections = new ApiCore({
  url: '/inspection/vehicle-inspections',
});

const vehicleInspectionDetails = new ApiCore({
  url: '/inspection/vehicle-inspections/details',
});

const beginVehicleInspections = new ApiCore({
  url: '/inspection/vehicle-inspections/begin',
});

const saveVehicleInspections = new ApiCore({
  url: '/inspection/vehicle-inspections',
});

const completeVehicleInspections = new ApiCore({
  url: '/inspection/vehicle-inspections/complete',
});

export default {
  getVehicleInspections: (queryString) =>
    vehicleInspections.query({ queryString }).read(),
  getVehicleInspectionDetails: (queryString) =>
    vehicleInspectionDetails.query({ queryString }).read(),
  beginVehicleInspections: (data) => beginVehicleInspections.update(data),
  saveVehicleInspections: (data) => saveVehicleInspections.update(data),
  completeVehicleInspections: (data) => completeVehicleInspections.update(data),
};
