export default {
  primary: {
    main: '#FFCE33',
    mainDarker: '#CBA000',
    constrastText: '#000',
    light: '#FFD865',
    disabled: '#FFE7A3',
  },
  secondary: {
    main: '#1F2532',
    contrastText: '#FFF',
    light: '#545861',
    disabled: '#999B9F',
  },
  error: {
    main: '#E91630',
    light: '#FF2F48',
    contrastText: '#FFF',
  },
  warning: {
    main: '#F7A84B',
    light: '#F9BF7B',
    contrastText: '#FFF',
  },
  info: {
    main: '#2196F3',
    contrastText: '#FFF',
  },
  success: {
    main: '#50E3AC',
    light: '#7BEAC0',
    contrastText: '#FFF',
  },
  gray: {
    50: '#F3F5F6',
    100: '#E6E6EC',
    200: '#CCCCCC',
    250: '#777777',
    300: '#444444',
    400: '#333333',
    disabledInputBackground: 'rgba(0, 0, 0, 0.02)',
  },
  text: {
    primary: '#333',
    secondary: '#FFF',
  },
  divider: '#E6E6EC',
  background: {
    default: '#F3F5F6',
  },
  transparentBlue: {
    100: `rgba(31, 37, 50,5%)`,
  },
  transparentBlack: {
    100: `rgba(0,0,0,5%)`,
    200: `rgba(0,0,0,25%)`, // almost the same as gray[200]
    900: `rgba(0,0,0,75%)`,
  },
};
