import * as actions from 'app/actions/selected-rows.actions';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';
import toLower from 'lodash/toLower';
import uniqBy from 'lodash/uniqBy';

const uniqueRowsById = (arr) => uniqBy(arr, ['id']);

export const initialState = {};

export function selectedRowsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case actions.INSERT_TABLE: {
      const { tableName } = payload;

      if (isUndefined(state[tableName])) {
        state[toLower(tableName)] = [];
      }

      return { ...state };
    }
    case actions.HANDLE_ROW_IN_TABLE: {
      const { tableName, selectedRow } = payload;

      if (!isUndefined(state[tableName])) {
        const row = find(state[tableName], (o) =>
          isEqual(o.id, selectedRow.id)
        );
        if (!isUndefined(row)) {
          state[tableName] = filter(
            state[tableName],
            (o) => !isEqual(o.id, selectedRow.id)
          );
        } else {
          state[tableName].push(selectedRow);
        }
      } else {
        state[tableName] = [selectedRow];
      }

      return { ...state };
    }
    case actions.INSERT_ROW_TO_TABLE: {
      const { tableName, selectedRow } = payload;

      if (!isUndefined(state[tableName])) {
        state[tableName] = filter(
          state[tableName],
          (o) => !isEqual(o.id, selectedRow.id)
        );
      } else {
        state[tableName] = [selectedRow];
      }

      return { ...state };
    }
    case actions.REMOVE_ROW_FROM_TABLE: {
      const { tableName, selectedRow } = payload;

      if (!isUndefined(state[tableName])) {
        state[tableName] = filter(
          state[tableName],
          (o) => !isEqual(o.id, selectedRow.id)
        );
      }

      return { ...state };
    }
    case actions.HANDLE_ALL_ROWS_IN_TABLE: {
      const { tableName, selectedRows } = payload;

      if (!isEmpty(state[tableName])) {
        state[tableName] = [];
      } else {
        state[tableName] = [...selectedRows];
      }

      return { ...state };
    }
    case actions.INSERT_ALL_ROWS_TO_TABLE: {
      const { tableName, selectedRows } = payload;

      state[tableName] = uniqueRowsById([...state[tableName], ...selectedRows]);

      return { ...state };
    }
    case actions.REMOVE_ALL_ROWS_FROM_TABLE: {
      const { tableName } = payload;

      state[tableName] = [];

      return { ...state };
    }
    default: {
      return { ...state };
    }
  }
}
