import * as React from 'react';
function SvgColor(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M17.989 20.033c0-.528-.312-1.368-1.008-2.879-.695 1.44-1.007 2.327-1.007 2.879 0 .72.48 1.031 1.007 1.031.528 0 1.008-.288 1.008-1.031zm-3 .071c0-.767.576-2.302 1.56-4.101h.864c.96 1.87 1.56 3.358 1.56 4.101 0 1.056-.936 1.896-1.992 1.896-1.08 0-1.991-.84-1.991-1.896zM10.577 9.165c-.6 0-.935.336-.935.96 0 .6.335.912.935.912.624 0 .936-.312.936-.912 0-.624-.312-.96-.936-.96zm-1.895.96c0-.84.552-1.535 1.32-1.775V3h.982v5.302a1.833 1.833 0 011.488 1.823c0 1.032-.84 1.871-1.895 1.871-1.031 0-1.895-.84-1.895-1.871zm3.286-4.63l1.992-.791 3.406 9.02-.744.503h-3.214l-2.927 1.104c-2.639 1.055-4.15.311-5.182-2.543l-.12-.312c-1.007-2.639-.287-4.222 2.255-5.278l1.56-.647v1.055l-1.224.504c-1.991.815-2.423 1.87-1.68 4.054l.12.336c.744 2.183 1.92 2.759 3.983 1.919l2.903-1.127h3.118L13.431 5.95l-1.464.576V5.495z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgColor;
