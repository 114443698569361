import * as React from 'react';
function SvgRenaultEmblemBlack(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 716.6 850.4'
      {...props}
    >
      <path d='M488.3 425.2L336.6 708.7h-43.3L141.7 425.2l151.6-283.5h43.3L185.1 425.2l129.9 243 129.9-243-75.8-141.7 21.7-40.5 97.5 182.2zm-65-283.5H380L228.4 425.2l97.5 182.2 21.7-40.5-75.8-141.8 129.9-243 129.9 243L380 708.7h43.3l151.6-283.5-151.6-283.5z' />
    </svg>
  );
}
export default SvgRenaultEmblemBlack;
