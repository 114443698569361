import * as React from 'react';
function SvgPower(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M11.52 12.003h.96V4h-.96v8.003zm2.158-5.846l.288-.959C17.082 5.99 19 8.648 19 11.98 19 15.957 16.267 19 12 19c-4.267 0-7-3.043-7-7.02 0-3.331 1.918-5.99 5.035-6.782l.287.959c-2.685.67-4.315 2.947-4.315 5.822 0 3.427 2.325 6.014 5.993 6.014s5.993-2.587 5.993-6.014c0-2.875-1.63-5.151-4.315-5.822z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgPower;
