import * as React from 'react';
function SvgChevronUpDown(props) {
  return (
    <svg
      width={24}
      height={24}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M16.055 8.63l-.82.58a41.285 41.285 0 00-3.24-4.225 35.358 35.358 0 00-3.215 4.2l-.84-.555A39.168 39.168 0 0111.555 4h.915c1.309 1.452 2.507 3 3.585 4.63zm-4.5 11.355a38.833 38.833 0 01-3.6-4.635l.84-.55A35.774 35.774 0 0012.01 19a41.28 41.28 0 003.24-4.225l.805.575a38.837 38.837 0 01-3.6 4.635h-.9z'
        fill='currentColor'
      />
    </svg>
  );
}
export default SvgChevronUpDown;
