import * as React from 'react';
function SvgHome(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M9 19v-6h5v6h3v-7.208c-1.92-2.256-3.557-3.96-5.5-5.76-1.943 1.8-3.581 3.504-5.5 5.76V19h3zm9 1h-4.989v-6.024H9.99L10 20H5v-8.544C6.775 9.32 8.478 7.496 11.093 5h.815c2.614 2.496 4.317 4.32 6.092 6.456V20z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgHome;
