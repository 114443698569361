// TODO: find better way to pass refreshToken/logout actions
import { refreshAccessToken } from 'app/actions/auth.actions';
import { setLocalStorage } from 'app/core/local-storage-handler';
import axios, { CancelToken } from 'axios';
import endsWith from 'lodash/endsWith';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import replace from 'lodash/replace';
import toLower from 'lodash/toLower';
import queryString from 'query-string';

import { handleErrorWithPromise, handleResponse } from './response';

const baseURL = replace(process.env.REACT_APP_API_URL, /\/+$/, '');
const headers = {
  'Content-Type': 'application/json',
};
let currentStore;
let currentHistory;

export const init = ({ store, history }) => {
  currentStore = store;
  currentHistory = history;
};

const getAuthHeaderContent = (token) => `Bearer ${token}`;

const axiosProvider = axios.create({
  baseURL,
  headers,
});

axiosProvider.interceptors.request.use(
  (config) => {
    const { authData } = currentStore.getState();

    if (authData.isLoggedIn && authData.accessToken) {
      config.headers.Authorization = getAuthHeaderContent(authData.accessToken);
    }
    return config;
  },
  (error) => Promise.reject(error),
  { synchronous: true }
);

axiosProvider.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    const { pathname } = currentHistory.location;
    // no error.response
    if (!error.response && !endsWith(pathname, '/login')) {
      return Promise.reject(error);
    }

    // if auth/tokens (PUT -> refreshToken) returns 401
    if (
      toLower(originalRequest.method) === 'put' &&
      endsWith(originalRequest.url, '/auth/tokens') &&
      !endsWith(pathname, '/login')
    ) {
      const parsedParams = queryString.parse(currentHistory.location.search);

      window.localStorage.removeItem('redux'); // remove/forget refreshToken just right after we notice it is not valid, clear refreshToken from localStorage (redux)

      error.refreshTokenFailedTokenExpiredOnBackEndReturnUrl = `${
        isNil(parsedParams.redirectUrl)
          ? `${currentHistory.location.pathname}`
          : `${parsedParams.redirectUrl}`
      }`;
      return Promise.reject(error);
    }

    // other error statuses
    if (!isEqual(error.response.status, 401) && !endsWith(pathname, '/login')) {
      return Promise.reject(error);
    }

    // if log in fails on /login page (for ex.: bad credentials)
    if (
      toLower(originalRequest.method) === 'post' &&
      endsWith(originalRequest.url, '/auth/tokens') &&
      endsWith(pathname, '/login') &&
      isEqual(error.response.status, 401)
    ) {
      return Promise.reject(error);
    }

    // refresh token

    return currentStore
      .dispatch(refreshAccessToken())
      .then(() => axiosProvider(originalRequest))
      .catch((err) => {
        if (
          !endsWith(pathname, '/login') &&
          !err.refreshTokenFailedTokenExpiredOnBackEndReturnUrl // and it is not after refresh failure
        ) {
          setLocalStorage('actionReload', 1);

          const parsedParams = queryString.parse(
            currentHistory.location.search
          );
          currentHistory.push({
            pathname: '/login',
            search: isNil(parsedParams.redirectUrl)
              ? `?redirectUrl=${currentHistory.location.pathname}`
              : `?redirectUrl=${parsedParams.redirectUrl}`,
          });

          window.location.reload();
        }
        return Promise.reject(err);
      });
  }
);

const request = (config) => {
  const source = CancelToken.source();

  return axiosProvider
    .request({
      ...config,
      cancelToken: source.token,
    })
    .then(handleResponse)
    .catch(handleErrorWithPromise());
};

export const apiProvider = {
  request,
};

export default axiosProvider;
