import { ApiCore } from '../_utilities/core';

const profiles = new ApiCore({
  url: '/auth/profiles',
});

const profile = new ApiCore({
  url: '/auth/profiles/:id',
});

const types = new ApiCore({
  url: '/auth/profiles/types',
});

const scopes = new ApiCore({
  url: '/auth/profiles/scopes',
});

const profilesNames = new ApiCore({
  url: '/auth/profiles/names',
});

export default {
  getProfiles: (queryString) => profiles.query({ queryString }).read(),
  getProfile: (id) => profile.query({ urlParams: { id } }).read(),
  createProfile: (data) => profiles.create(data),
  updateProfile: (id, data) =>
    profile.query({ urlParams: { id } }).update(data),
  deleteProfile: (id) => profile.query({ urlParams: { id } }).delete(),
  getTypes: () => types.read(),
  getScopes: () => scopes.read(),
  getNames: (queryString) => profilesNames.query({ queryString }).read(),
};
