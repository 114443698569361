import * as React from 'react';
function SvgCarServices(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M4.99 18.29l-1 .744-.6-.745 1-.745a5.025 5.025 0 013.265-1.2c1.705 0 3.29.795 5.784.84l5.955-4.08c.24-.164.335-.31.335-.5a.54.54 0 00-.55-.55.775.775 0 00-.46.166l-2.65 1.8a1.5 1.5 0 01-1.44.934h-3.5v-.96h3.5c.41 0 .555-.17.555-.53s-.145-.524-.555-.524H8.21c-1.634 0-2.304.215-3.434 1.1l-.79.624-.575-.764.79-.625c1.32-1.035 2.28-1.295 4.035-1.295h6.38a1.53 1.53 0 011.42.885l2.279-1.5a1.3 1.3 0 01.815-.265 1.5 1.5 0 011.56 1.56c0 .5-.265.89-.96 1.37l-6 4.15c-2.595 0-4.25-.866-6-.866a3.925 3.925 0 00-2.74.975zm1-9.29a1.57 1.57 0 013 0h5a1.57 1.57 0 012.999 0h.385c.525 0 .645-.095.645-.5a3.145 3.145 0 00-.36-1.225 1.085 1.085 0 00-.745-.5l-1.68-.435c-.625-.165-1.44-.935-2.47-1.345-.795-.31-1.5-.43-3.144-.43-.955.015-1.909.087-2.855.215l-.915-.55v-.24c1.3-.225 2.615-.362 3.935-.41a8.934 8.934 0 013.404.5c1.13.43 1.8 1.175 2.28 1.32l1.6.5c.566.11 1.07.428 1.415.89.299.59.47 1.235.5 1.895 0 .865-.745 1.32-1.73 1.32h-.285a1.55 1.55 0 01-3 0h-5a1.5 1.5 0 01-1.5 1.155 1.615 1.615 0 01-1.5-1.18h-.044a1.79 1.79 0 01-1.92-1.8v-1.2c0-.5.145-.96 1.125-1.945l.84.46C5.11 6.43 4.94 6.62 4.94 7.1v1c0 .6.29.935 1 .935L5.99 9zm2.21.5a.71.71 0 00-.72-.72.7.7 0 00-.675.72.685.685 0 00.675.72.695.695 0 00.73-.715L8.2 9.5zm8 0a.705.705 0 00-.72-.72.695.695 0 00-.67.72.68.68 0 00.67.72.69.69 0 00.72-.715V9.5z'
        fill='#1D1D1B'
        fillRule='nonzero'
      />
    </svg>
  );
}
export default SvgCarServices;
