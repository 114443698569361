import * as React from 'react';
function SvgCancelSmall(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M7 15.252l3.764-3.764L7 7.724 7.724 7l3.764 3.764L15.252 7l.724.7-3.764 3.788L16 15.276l-.723.724-3.79-3.789-3.763 3.765z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgCancelSmall;
