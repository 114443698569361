import i18next from 'i18next';
import compact from 'lodash/compact';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import omitBy from 'lodash/omitBy';
import truncate from 'lodash/truncate';

import { checkPermissions } from './acl.utils';
import { getTextWidth } from './getTextWidth.utils';
import { getAcronym } from './utils';

export const mapACLNavigation = (
  menuTree = [],
  menuVars = {},
  menuIcons = {},
  userPermissions = []
) =>
  compact(
    map(menuTree, (item) => {
      const permitted = checkPermissions(
        userPermissions,
        item.allowedPermissions
      );

      if (permitted) {
        let truncatenatedText = i18next.t(item.text, menuVars);
        let currentItemTextWidth = getTextWidth(
          truncatenatedText,
          '400 .875rem Read' // 'bold 12pt arial'
        );
        let truncateTo = 20;
        while (currentItemTextWidth > 120) {
          truncatenatedText = truncate(truncatenatedText, {
            length: truncateTo--,
          });
          currentItemTextWidth = getTextWidth(
            truncatenatedText,
            '400 .875rem Read' // 'bold 12pt arial'
          );
        }

        return omitBy(
          {
            ...item,
            acronym: item.acronym
              ? getAcronym(i18next.t(item.text, menuVars))
              : null,
            children: item.children
              ? mapACLNavigation(
                  item.children,
                  menuVars,
                  menuIcons,
                  userPermissions
                )
              : null,
            Icon: menuIcons[item.Icon] ? menuIcons[item.Icon] : null,
            text: truncatenatedText,
            tooltip: i18next.t(item.text, menuVars),
            subtext: item.subtext ? i18next.t(item.subtext, menuVars) : null,
          },
          isNil
        );
      }
      return undefined;
    })
  );
