import { ApiCore } from '../_utilities/core';

const settlements = new ApiCore({
  url: '/finance/settlements',
});

const settlement = new ApiCore({
  url: '/finance/settlements/:id',
});

export default {
  getSettlements: (queryString) => settlements.query({ queryString }).read(),
  getSettlement: (id) => settlement.query({ urlParams: { id } }).read(),
  createSettlement: (data) => settlements.create(data),
  updateSettlement: (id, data) =>
    settlement.query({ urlParams: { id } }).update(data),
  deleteSettlement: (id) => settlement.query({ urlParams: { id } }).delete(),
  getRequiredAttributesItems: () =>
    new ApiCore({
      url: '/finance/settlements/required-attributes',
    })
      .query()
      .read(),
  getTypesByType: (type) =>
    new ApiCore({
      url: '/finance/settlements/:type/items',
    })
      .query({ urlParams: { type } })
      .read(),
};
