import * as React from 'react';
function SvgArrowDown(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <g fill='none' fillRule='evenodd'>
        <path d='M-228-93h1440v800H-228z' />
        <path
          d='M11.097 19.5c-2.258-2.793-3.98-5.166-6.237-8.611l.814-.605c1.723 2.7 3.165 4.77 5.33 7.61V4h1v13.94c2.165-2.838 3.608-4.91 5.33-7.61l.815.559c-2.258 3.445-3.98 5.818-6.237 8.611h-.815z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
export default SvgArrowDown;
