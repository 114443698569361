import { ApiCore } from '../_utilities/core';

const auth = new ApiCore({
  url: '/auth/tokens',
});

export default {
  createToken: ({ username, password }) => auth.create({ username, password }),
  deleteToken: () => auth.delete(),
  updateToken: (refreshToken) => auth.update({ refreshToken }),
  getVehicleCostCenter: (code) => (queryString) =>
    new ApiCore({
      url: '/auth/system-settings/:code/values',
    })
      .query({ queryString, urlParams: { code } })
      .read(),
};
