import * as React from 'react';
function SvgLock(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M11.988 12.54c-.697 0-.986.36-.986.935 0 .648.289.96.986.96.625 0 .986-.312.986-.96 0-.576-.36-.935-.986-.935zM9.992 7.118V8.99h3.992V7.118c0-1.439-.866-2.11-2.068-2.11-1.178 0-1.924.695-1.924 2.11zm1.996 9.308v-1.008h-.024c-1.226 0-1.996-.744-1.996-1.943 0-1.175.77-1.919 1.996-1.919 1.203 0 1.996.744 1.996 1.92 0 .815-.36 1.414-.986 1.727v2.206H10.81v-.983h1.179zM8.116 9.997c-.745 0-1.13.263-1.13 1.175v7.82H16.99v-7.82c0-.912-.385-1.175-1.13-1.175H8.116zM18 11.005V20H6v-8.852c0-1.271.433-2.158 2.14-2.158h.842V6.998C8.982 5.08 10.064 4 11.916 4c1.876 0 3.078 1.055 3.078 2.998V8.99h.842c1.467 0 2.164.743 2.164 2.015z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgLock;
