import * as React from 'react';
function SvgConfirm(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M11.66 18h-.983A60.76 60.76 0 016 10.565l.887-.528a62.614 62.614 0 004.246 6.812c2.374-4.054 4.245-7.627 6.26-11.849l.935.456C16.17 9.989 14.251 13.635 11.661 18'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgConfirm;
