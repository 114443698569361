const MOBILE_MAX_WIDTH = 746;

// have to be larger then 1200 (.MuiDrawer-paper) because otherwise left Main Sidebar Menu hides Debugger
// have to be less then 1300 (#presentation.MuiPopover-root) because otherwise calendar hides behind sidebar
export const SIDEBAR_Z_INDEX = 1250;
export const SIDEBAR_OPEN_MASK_Z_INDEX = 400;

export default ({ palette, scrolls }) => ({
  root: {
    position: 'relative',
    height: '100%',
    backgroundColor: palette.common.white,
    borderLeft: `1px solid ${palette.gray[200]}`,
    gridArea: 'sidebar',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100vh',
    minWidth: '148px', // to show AppSpinner if sidebar content is loading and there is only AppSpinner as content
    overflowY: 'auto',
    ...scrolls.tableScroll,
    boxSizing: 'border-box',
    zIndex: SIDEBAR_Z_INDEX,

    // [`@media (max-width: ${MOBILE_MAX_WIDTH}px)`]: { 2nd approach to sidebar fullWidth on mobile
    //   width: '100%',
    //   // '& div:nth-child(2)': {
    //   //   width: '100%', // it cannot be %, it have to be px
    //   // },
    // },

    [`@media (max-width: ${MOBILE_MAX_WIDTH}px)`]: {
      borderLeft: 0,
    },
  },
  // "Dodaj element z lokalizacją" teraz źle wygląda
  // sidebarContentContainer: { 2nd approach to sidebar fullWidth on mobile
  //   width: '100%',
  //   height: '100%',

  //   '& > div': {
  //     height: '100%',
  //   },

  //   [`@media (max-width: ${MOBILE_MAX_WIDTH}px)`]: { 2nd approach to sidebar fullWidth on mobile
  //     // I assume there is only one child!
  //     '& div:nth-child(1)': {
  //       width: '100%', // it cannot be %, it have to be px
  //     },
  //   },
  // },

  sidebarRootContainer: {
    [`@media (max-width: ${MOBILE_MAX_WIDTH}px)`]: {
      width: '100%',
      zIndex: SIDEBAR_Z_INDEX,
      backgroundColor: '#FFF',

      '& #sidebarRoot': {
        margin: '0 auto',
        width: '372px',
      },
    },
  },
});
