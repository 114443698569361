import { ApiCore } from '../_utilities/core';

const expertiseAndRepairCosts = new ApiCore({
  url: '/report/expertise-and-repair-costs',
});

const expertiseAndRepairCostsExport = new ApiCore({
  url: '/report/expertise-and-repair-costs/export',
});

const expertiseAndRepairCostsSummary = new ApiCore({
  url: '/report/expertise-and-repair-costs/summary',
});

export default {
  getExpertiseAndRepairCosts: (queryString) =>
    expertiseAndRepairCosts.query({ queryString }).read(),
  getExpertiseAndRepairCostsExport: (queryString) =>
    expertiseAndRepairCostsExport.query({ queryString }).read(),
  getExpertiseAndRepairCostsSummary: (queryString) =>
    expertiseAndRepairCostsSummary.query({ queryString }).read(),
};
