import * as React from 'react';
function SvgVisibilityHide(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M16.108 6l.784.62-9 11.38-.784-.62 9-11.38zm.384 2.737c1.204.686 2.373 1.657 3.508 2.913v.8c-2.5 2.708-5.167 4.06-8 4.056-.52 0-1.034-.047-1.542-.138l.757-.956a3.5 3.5 0 003.924-4.962l.668-.846.075.12A4.48 4.48 0 0116.5 12c0 .97-.307 1.87-.83 2.605 1.037-.525 2.056-1.282 3.058-2.276l.272-.277v-.011c-1.028-1.091-2.072-1.926-3.137-2.51l.63-.794zM12 7.5c.518 0 1.03.046 1.537.139l-.751.95a3.5 3.5 0 00-.594-.084L12 8.5a3.5 3.5 0 00-3.139 5.05l-.669.846A4.469 4.469 0 017.5 12c0-.949.293-1.829.795-2.554l-.011.006c-1.026.54-2.033 1.31-3.024 2.319L5 12.04v.012c1.022 1.057 2.061 1.868 3.12 2.437l-.628.796C6.294 14.615 5.13 13.67 4 12.45v-.8c2.408-2.665 4.972-4.047 7.69-4.144L12 7.5z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgVisibilityHide;
