import * as React from 'react';
function SvgKey(props) {
  return (
    <svg width={468} height={468} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M152.55 222.75c-6.19 0-11.522-3.676-13.931-8.965-3.3-1.25-6.798-2-10.4-2.218L126 211.5h-4.5c-19.28 0-35.02 15.156-35.956 34.203L85.5 247.5v4.5c0 19.28 15.156 35.02 34.203 35.956l1.797.044h4.5c13.503 0 25.498-7.498 31.651-18.819-26.559-2.129-43.75-8.443-54.158-19.298l.007-2.383.05-1.343c.45-6.115 3.956-11.38 8.997-14.276 7.179 13.634 24.32 19.488 61.096 20.07L180 252l29.828-.013c47.371-.426 59.315-11.793 60.122-40.747l.035-1.724L270 207c0-31.433-9.905-43.646-54.815-44.892l-2.683-.061L207 162h-27l-6.683.053-6.273.16c-26.318.933-41.459 4.993-49.803 13.415a71.52 71.52 0 00-23.75 5.523c8.15-24.91 31.806-36.657 83.19-37.136L180 144h27c57.559 0 78.01 17.208 80.688 54.001L396 198v18l-108.267.007c-2.462 35.79-22.69 53.349-77.644 53.976l-33.16.008c-7.256 20.578-26.447 35.062-48.72 35.964L126 306h-4.5c-29.146 0-52.898-23.09-53.963-51.976L67.5 252v-4.5c0-29.146 23.09-52.898 51.976-53.963l2.024-.037h4.5c5.382 0 10.65.791 15.67 2.31a15.184 15.184 0 0110.88-4.56h.9c8.45 0 15.3 6.85 15.3 15.3v.9c0 8.234-6.504 14.948-14.654 15.287l-.212.287-.397-.274h-.937z'
        fill='#000'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgKey;
