import { ApiCore, useDoRequest, useRequest } from '../_utilities/core';

const vehicleExpertises = new ApiCore({
  url: '/expertise/vehicle-expertises',
});

const vehicleExpertise = new ApiCore({
  url: '/expertise/vehicle-expertises/:id',
});

const completeVehicleExpertise = new ApiCore({
  url: '/expertise/vehicle-expertises/:id/complete',
});

const getVehicleExpertisesAvailabilities = new ApiCore({
  url: '/expertise/vehicle-expertises/:id/availabilities',
});

const createVehicleExpertisesTake = new ApiCore({
  url: '/expertise/vehicle-expertises/:id/take',
});

const createVehicleExpertisesTakeBack = new ApiCore({
  url: '/expertise/vehicle-expertises/:id/take-back',
});

const changeVehicleExpertisesAvailability = new ApiCore({
  url: '/expertise/vehicle-expertises/:id/availability',
});

const payers = new ApiCore({
  url: '/expertise/vehicle-expertises/payers',
});

const photos = new ApiCore({
  url: '/expertise/vehicle-expertises/:id/photos',
});

const photo = new ApiCore({
  url: '/expertise/vehicle-expertises/:id/photos/:photoId',
  responseType: 'blob',
});

const createRepairOrder = new ApiCore({
  url: '/expertise/vehicle-expertises/repair/order',
});

const createLeave = new ApiCore({
  url: '/expertise/vehicle-expertises/:id/leave',
});

const createRepairSkipped = new ApiCore({
  url: '/expertise/vehicle-expertises/repair/skip',
});

const createRepairStart = new ApiCore({
  url: '/expertise/vehicle-expertises/:id/repair/start',
});

const createRepairCancel = new ApiCore({
  url: '/expertise/vehicle-expertises/:id/repair/cancel',
});

const transfer = new ApiCore({
  url: '/expertise/vehicle-expertises/:id/transfer',
});

const transferReject = new ApiCore({
  url: '/expertise/vehicle-expertises/:id/transfer/reject',
});

const transferNewDeclaration = new ApiCore({
  url: '/expertise/vehicle-expertises/:id/transfer/new-declaration',
});

const expertisePrintout = new ApiCore({
  url: '/expertise/vehicle-expertises/:id/printout',
});

const expertisePhotosPrintout = new ApiCore({
  url: '/expertise/photos/:expertiseId/printout',
});

const expertiseWorkSheet = new ApiCore({
  url: '/expertise/vehicle-expertises/:id/work-sheet',
});

export default {
  getVehicleExpertises: (queryString) =>
    vehicleExpertises.query({ queryString }).read(),
  getPayers: () => payers.read(),
  getVehicleExpertise: (id) =>
    vehicleExpertise.query({ urlParams: { id } }).read(),
  getPhotos: (id) => photos.query({ urlParams: { id } }).read(),
  getPhoto: (id, photoId) => photo.query({ urlParams: { id, photoId } }).read(),
  updatePhoto: (id, photoId, data) =>
    photo.query({ urlParams: { id, photoId } }).update(data),
  sendPhoto: (id, data) => photos.query({ urlParams: { id } }).create(data),
  updateVehicleExpertise: (id, data) =>
    vehicleExpertise.query({ urlParams: { id } }).update(data),
  completeVehicleExpertise: (id, data) =>
    completeVehicleExpertise.query({ urlParams: { id } }).update(data),
  createVehicleExpertisesTake: (id, data) =>
    createVehicleExpertisesTake.query({ urlParams: { id } }).create(data),
  createVehicleExpertisesTakeBack: (id) =>
    createVehicleExpertisesTakeBack.query({ urlParams: { id } }).create(),

  /**
   *
   * @returns {Array} Returns following array:
   * ```
   * [
   *    data: response, // the response object
   *    isLoading: true, // indicates if it is still loading
   *    error: err, // err object from .catch(err),
   * ]
   * ```
   */
  useGetVehicleExpertisesAvailabilitiesDoRequest: (id) =>
    useDoRequest(() =>
      getVehicleExpertisesAvailabilities.query({ urlParams: { id } }).read()
    ),
  updateVehicleExpertisesAvailability: (id, data) =>
    changeVehicleExpertisesAvailability
      .query({ urlParams: { id } })
      .update(data),
  createRepairOrder: (queryString, data) =>
    createRepairOrder.query({ queryString }).create(data),
  createLeave: (id, data) =>
    createLeave.query({ urlParams: { id } }).create(data),
  createRepairSkipped: (queryString) =>
    createRepairSkipped.query({ queryString }).create(),
  createRepairStart: (id) =>
    createRepairStart.query({ urlParams: { id } }).create(),
  createRepairCancel: (id) =>
    createRepairCancel.query({ urlParams: { id } }).create(),

  /**
   *
   * @returns {Array} Returns following array:
   * ```
   * [
   *    data: response, // the response object
   *    isLoading: true, // indicates if it is still loading
   *    error: err, // err object from .catch(err),
   * ]
   * ```
   */
  useGetTransferDoRequest: (id) =>
    useDoRequest(() => transfer.query({ urlParams: { id } }).read()),
  useGetTransferNewDeclarationDoRequest: (id) =>
    useDoRequest(() =>
      transferNewDeclaration.query({ urlParams: { id } }).read()
    ),

  /**
   *
   * @returns {Array} Returns following array:
   * ```
   * [
   *    {
   *       data: response, // the response object
   *       isLoading: true, // indicates if it is still loading
   *       error: err, // err object from .catch(err),
   *    },
   *    doRequest(queryString, scheduleType) // method that executes the request
   *    // scheduleType - the type of shedule to "ask" for
   * ]
   * ```
   */
  useCreateTransferRequest: () =>
    useRequest((id, data) =>
      transfer.query({ urlParams: { id } }).create(data)
    ),
  useUpdateTransferRequest: () =>
    useRequest((id, data) =>
      transfer.query({ urlParams: { id } }).update(data)
    ),
  createTransferReject: (id) =>
    transferReject.query({ urlParams: { id } }).create(),
  createExpertisePrintoutPdf: (id) =>
    expertisePrintout.query({ urlParams: { id } }).create(),
  createExpertisePhotosPrintoutPdf: (expertiseId, queryString) =>
    expertisePhotosPrintout
      .query({ urlParams: { expertiseId }, queryString })
      .create(),
  createExpertiseWorkSheetPdf: (id) =>
    expertiseWorkSheet.query({ urlParams: { id } }).create(),
};
