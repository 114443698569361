import { CircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import cx from 'classnames';
import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './app-spinner.jss';

function AppSpinner({ classes, className, size }) {
  return (
    <div className={cx(classes.root, className)}>
      <CircularProgress thickness={7.2} size={size} />
    </div>
  );
}

AppSpinner.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  size: PropTypes.number,
};

AppSpinner.defaultProps = {
  classes: {},
  className: null,
  size: 100,
};

export default flow([withStyles(styles)])(AppSpinner);
