import * as actionsWithTypes from '../actions';

const initialState = {
  isLoggedIn: false,
  accessToken: null,
  refreshToken: null,
};

export function authDataReducer(state = initialState, action) {
  switch (action.type) {
    case actionsWithTypes.REFRESH_TOKEN_SUCCESS:
    case actionsWithTypes.LOGIN_SUCCESS: {
      const { accessToken, refreshToken } = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        accessToken,
        refreshToken,
      };
    }
    case actionsWithTypes.UPDATE_SWITCH_VIEW_TOKEN: {
      const { accessToken } = action.payload;
      return {
        ...state,
        accessToken,
      };
    }
    case actionsWithTypes.LOGIN_ERROR:
    case actionsWithTypes.LOGOUT: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
