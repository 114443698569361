/**
 * Sample usage:
 *   getTextWidth('Some text', 'bold 12pt arial');
 *   getTextWidth(text, getCanvasFontSize(myEl)); // where myEl is DOM element
 *
 * @param {*} text Text to calculate its width
 * @param {*} font In format: 'bold 12pt arial'
 * @returns width in pixels
 */
export const getTextWidth = (text, font) => {
  // re-use canvas object for better performance
  const canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
};

const getCssStyle = (element, prop) =>
  window.getComputedStyle(element, null).getPropertyValue(prop);

export const getCanvasFontSize = (el) => {
  const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
  const fontSize = getCssStyle(el, 'font-size') || '16px';
  const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman';

  return `${fontWeight} ${fontSize} ${fontFamily}`;
};
