import { ApiCore } from '../_utilities/core';

const documents = new ApiCore({
  url: '/finance/documents',
});

const document = new ApiCore({
  url: '/finance/documents/:id',
});

const documentBB = new ApiCore({
  url: '/finance/documents/buyback/:id',
});

const documentSN = new ApiCore({
  url: '/finance/documents/resale/:id',
});

const formBB = new ApiCore({
  url: '/finance/documents/buyback/:id',
});

const formSN = new ApiCore({
  url: '/finance/documents/resale/:id',
});

const newFormSN = new ApiCore({
  url: '/finance/documents/resale',
});

const products = new ApiCore({
  url: '/finance/products/items',
});

const relatedData = new ApiCore({
  url: '/finance/products/:id/related-data',
});

const rows = new ApiCore({
  url: '/finance/documents/rows/calculate',
});

const calculate = new ApiCore({
  url: '/finance/documents/calculate',
});

const resaleTypes = new ApiCore({
  url: '/finance/documents/Resale/types?correction=false',
});

const newDocument = new ApiCore({
  url: '/finance/documents/resale/initial/:type/:vehicleId',
});

const newOrder = new ApiCore({
  url: '/finance/documents/resale/initial/:type',
});

const corrected = new ApiCore({
  url: 'finance/documents/resale/initial/correction/:correctionId',
});

const costCenter = new ApiCore({
  url: '/auth/system-settings/VehicleCostCenter/values',
});

const currency = new ApiCore({
  url: '/finance/documents/currency-rate/calculate',
});

export default {
  getDocuments: (queryString) => documents.query({ queryString }).read(),
  getDocumentBB: (id) => documentBB.query({ urlParams: { id } }).read(),
  getDocumentSN: (id) => documentSN.query({ urlParams: { id } }).read(),
  getResaleTypes: () => resaleTypes.read(),
  getVehicleCostCenter: () => costCenter.read(),
  getInitialData: (type, vehicleId) =>
    newDocument.query({ urlParams: { type, vehicleId } }).read(),
  getInitialOrder: (queryString) =>
    newOrder
      .query({ queryString, urlParams: { type: queryString.type } })
      .read(),
  getInitialDataForCorrection: (correctionId) =>
    corrected.query({ urlParams: { correctionId } }).read(),
  getProducts: () => products.read(),
  getRelatedData: (id) => relatedData.query({ urlParams: { id } }).read(),
  createDocument: (data) => documents.create(data),
  updateDocumentBB: (id, data) =>
    formBB.query({ urlParams: { id } }).update(data),
  updateDocumentSN: (id, data) =>
    formSN.query({ urlParams: { id } }).update(data),
  createDocumentSN: (data) => newFormSN.query().create(data),
  deleteDocument: (id) => document.query({ urlParams: { id } }).delete(),
  calculateRows: (data) => rows.create(data),
  calculateDoc: (data) => calculate.create(data),
  getDocumentTypes: (documentClass) => (queryString) =>
    new ApiCore({
      url: '/finance/documents/:class/types',
    })
      .query({ queryString, urlParams: { class: documentClass } })
      .read(),
  getGenerateExportRciPdf: (data) =>
    new ApiCore({
      url: `/finance/documents/exports/RCI`,
      responseType: 'blob',
    }).create(data),
  getGenerateAccountingPdf: (data) =>
    new ApiCore({
      url: '/finance/documents/exports/Accounting',
      responseType: 'blob',
    }).create(data),
  getGenerateFinanceMergePdf: (data) =>
    new ApiCore({
      url: '/finance/documents/attachments/merge/Printout',
    }).create(data),
  getFinancePdf: (fileId) =>
    new ApiCore({
      url: `/finance/documents/attachments/merge/:fileId/Printout`,
      responseType: 'blob',
    })
      .query({
        urlParams: {
          fileId,
        },
      })
      .read(),
  calculateCurrency: (data) => currency.create(data),
};
