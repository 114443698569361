import { mainMenu } from 'app/navigations';
import compact from 'lodash/compact';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import some from 'lodash/some';

const getAvailablePath = (availablePaths) => {
  const menuPaths = compact(
    flatMap(mainMenu, (mmOption) => [
      mmOption.path,
      ...map(mmOption.children, 'path'),
    ])
  );

  // find 1st in menuPaths
  const homeHref = find(menuPaths, (p) =>
    some(availablePaths, (ap) => isEqual(ap.path, p))
  );
  if (homeHref !== undefined) {
    return homeHref;
  }

  return '/';
};

export default getAvailablePath;
