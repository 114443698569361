import { ApiCore } from '../_utilities/core';

const repairCosts = new ApiCore({
  url: '/report/repair-costs',
});

const repairCostsExport = new ApiCore({
  url: '/report/repair-costs/export',
});

const repairCostsSummary = new ApiCore({
  url: '/report/repair-costs/summary',
});

export default {
  getRepairCosts: (queryString) => repairCosts.query({ queryString }).read(),
  getRepairCostsExport: (queryString) =>
    repairCostsExport.query({ queryString }).read(),
  getRepairCostsSummary: (queryString) =>
    repairCostsSummary.query({ queryString }).read(),
};
