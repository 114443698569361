import { PROLONG_LOCK_VEHICLE_INTERVAL } from 'app/core/constants';
import isNil from 'lodash/isNil';
import join from 'lodash/join';
import sortBy from 'lodash/sortBy';

import { ApiCore } from '../_utilities/core';

const activeVehicles = new ApiCore({
  url: '/vehicle/active-vehicles',
});

const activeVehicle = new ApiCore({
  url: '/vehicle/active-vehicles/:id',
});

const activeVehicleLock = new ApiCore({
  url: '/vehicle/active-vehicles/lock',
});

const activeVehiclesGrouped = new ApiCore({
  url: '/vehicle/active-vehicles/grouped',
});

const activeVehicleUnlock = new ApiCore({
  url: '/vehicle/active-vehicles/unlock',
});

const activeVehicleInspection = new ApiCore({
  url: '/vehicle/active-vehicles/inspection/:type',
});

const activeVehiclesPlannedItems = new ApiCore({
  url: '/vehicle/active-vehicles/planned/items',
});

const activeVehiclesVehiclesOrder = new ApiCore({
  url: '/vehicle/active-vehicles/:id/vehicles/order',
});

const createLeave = new ApiCore({
  url: '/vehicle/active-vehicles/leave',
});

const activeVehicleOptions = new ApiCore({
  url: '/vehicle/active-vehicles/:id/options',
});

const lockActiveVehicleIntervalsList = {};

const activeVehiclesApi = {
  getActiveVehicles: (queryString) =>
    activeVehicles.query({ queryString }).read(),
  getActiveVehicle: (id) => activeVehicle.query({ urlParams: { id } }).read(),
  getActiveVehiclesGrouped: (queryString) =>
    activeVehiclesGrouped.query({ queryString }).read(),
  updateActiveVehiclesGrouped: (data) => activeVehiclesGrouped.update(data),
  createActiveVehicle: (data) => activeVehicles.create(data),
  updateActiveVehicle: (id, data) =>
    activeVehicle.query({ urlParams: { id } }).update(data),
  deleteActiveVehicle: (queryString) =>
    activeVehicles.query({ queryString }).delete(),
  /**
   *
   * @param {*} queryString
   * @param {Function} vehicleIsAlreadyLockedAction action/function that will be executed if car/cars are locked by other user
   * @param {Boolean} withProlong Set to true to start interval that will prolong lock every XX seconds
   * @returns
   */
  lockActiveVehicle: (
    queryString,
    vehicleIsAlreadyLockedAction = undefined,
    withProlong = true
  ) =>
    activeVehicleLock
      .query({ queryString })
      .patch()
      .catch((err) => {
        if (isNil(vehicleIsAlreadyLockedAction)) {
          throw new Error(
            `MakoError: vehicleIsAlreadyLockedAction is required!`
          );
        }
        if (
          err?.response?.data?.code === 'VehicleIsAlreadyLockedByOtherModifier'
        ) {
          vehicleIsAlreadyLockedAction(err);
        }
        throw err;
      })
      .then((res) => {
        if (withProlong) {
          const key = join(sortBy(queryString.id), ',');
          lockActiveVehicleIntervalsList[key] = setInterval(() => {
            activeVehiclesApi.lockActiveVehicle(
              queryString,
              vehicleIsAlreadyLockedAction,
              false
            );
          }, PROLONG_LOCK_VEHICLE_INTERVAL);
        }
        return res;
      }),
  unlockActiveVehicle: (queryString) => {
    // clear prolonging interval
    const key = join(sortBy(queryString.id), ',');
    clearInterval(lockActiveVehicleIntervalsList[key]);

    return activeVehicleUnlock.query({ queryString }).patch();
  },
  createActiveVehicleInspection: (type, queryString, data) =>
    activeVehicleInspection
      .query({ urlParams: { type }, queryString })
      .create(data),
  createActiveVehicleTransport: (type, queryString, data) => {
    let urlPart = '';
    switch (type) {
      case 'newCarTransport':
        urlPart = 'transport/order';
        break;
      case 'resale':
        urlPart = 'resale';
        break;
      case 'usedCarTransport':
        urlPart = 'resale/transport/order';
        break;
      default:
        urlPart = null;
        break;
    }
    return new ApiCore({
      url: `/vehicle/active-vehicles/${urlPart}`,
    })
      .query({ urlParams: { urlPart }, queryString })
      .create(data);
  },
  getPlannedItems: () => activeVehiclesPlannedItems.query().read(),
  createOrderVehicles: (id, data) =>
    activeVehiclesVehiclesOrder.query({ urlParams: { id } }).create(data),
  createLeave: (queryString, data) =>
    createLeave.query({ queryString }).create(data),
  getVehicleOptions: (id) =>
    activeVehicleOptions.query({ urlParams: { id } }).read(),
  getLeaveComment: (queryString) =>
    new ApiCore({
      url: '/vehicle/active-vehicles/leave',
    })
      .query({ queryString })
      .read(),
  createResaleReleaseOrder: (queryString, data) =>
    new ApiCore({
      url: '/vehicle/active-vehicles/resale/release/order',
    })
      .query({ queryString })
      .create(data),
  getTransportDeliveryAddresses: (queryString) =>
    new ApiCore({
      url: '/vehicle/active-vehicles/transport/delivery-addresses',
    })
      .query({ queryString })
      .read(),
  getResaleTransportDeliveryAddresses: (queryString) =>
    new ApiCore({
      url: '/vehicle/active-vehicles/resale/transport/delivery-addresses',
    })
      .query({ queryString })
      .read(),
};

export default activeVehiclesApi;
