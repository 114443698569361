import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
  Collapse,
  List,
  ListItemIcon,
  ListItemText,
  Popover,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import cx from 'classnames';
import flow from 'lodash/flow';
import isFunction from 'lodash/isFunction';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './sidebar-menu.jss';
import MenuItemContent from './sidebar-menu-item-content';

function MenuItem({
  acronym,
  classes,
  children,
  fullWidth,
  Icon,
  id,
  path,
  onClick,
  subtext,
  text,
  tooltip,
}) {
  // state
  const [expandableOpen, setExpandableOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  // menu item click handlers
  const handleExpandableClick = () => {
    setExpandableOpen(!expandableOpen);
  };
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // helper vars
  const popoverOpen = Boolean(anchorEl);
  const isExpandable = children && children.length > 0;

  // close all submenus on fullWidth change
  React.useEffect(() => {
    if (fullWidth) {
      setAnchorEl(null);
    } else {
      setExpandableOpen(false);
    }
  }, [fullWidth]);

  // ListItem
  const MenuItemRoot = (
    <MenuItemContent
      tooltip={tooltip}
      fullWidth={fullWidth}
      id={id}
      path={path}
      onClick={
        onClick || (fullWidth ? handleExpandableClick : handlePopoverOpen)
      }
    >
      <div
        className={cx(classes.button, {
          [classes.inset]: fullWidth && !isExpandable,
        })}
      >
        {fullWidth && (
          <>
            {isExpandable &&
              (expandableOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />)}
            <ListItemText
              primary={text}
              secondary={subtext || null}
              secondaryTypographyProps={{
                variant: 'caption',
                color: 'secondary',
              }}
            />
          </>
        )}

        {(Icon || acronym) && (
          <ListItemIcon className={cx(classes.icon)}>
            {isFunction(Icon) ? !!Icon && <Icon /> : !!Icon && <>{Icon}</>}
            {!!acronym && (
              <div className={cx(classes.icon, classes.acronym)}>{acronym}</div>
            )}
          </ListItemIcon>
        )}
      </div>
    </MenuItemContent>
  );

  // ListItemChildren
  const MenuItemExpandable = isExpandable ? (
    <Collapse in={expandableOpen} timeout='auto' unmountOnExit>
      <List component='div' disablePadding>
        {map(children, (item, index) => (
          <MenuItem classes={classes} key={index} {...item} />
        ))}
      </List>
    </Collapse>
  ) : null;

  const MenuItemPopover = (
    <Popover
      classes={{ paper: cx(classes.popover) }}
      id={id}
      open={popoverOpen}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      elevation={4}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <List
        classes={{ root: cx(classes.popoverList) }}
        component='div'
        disablePadding
      >
        {map(children, (item, index) => (
          <MenuItem classes={classes} key={index} {...item} />
        ))}
      </List>
    </Popover>
  );

  const MenuItemChildren = fullWidth ? MenuItemExpandable : MenuItemPopover;

  // className={classes.navItem,expandableOpen ? cx(classes.expanded) : undefined}>
  return (
    <div className={cx(classes.navItem, expandableOpen && classes.expanded)}>
      {MenuItemRoot}
      {MenuItemChildren}
    </div>
  );
}

MenuItem.propTypes = {
  acronym: PropTypes.string,
  classes: PropTypes.object,
  children: PropTypes.array,
  fullWidth: PropTypes.bool,
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  id: PropTypes.string,
  path: PropTypes.string,
  onClick: PropTypes.func,
  subtext: PropTypes.string,
  text: PropTypes.string,
  tooltip: PropTypes.string,
};

MenuItem.defaultProps = {
  acronym: null,
  classes: {},
  children: null,
  fullWidth: true,
  Icon: null,
  id: null,
  path: null,
  onClick: null,
  subtext: null,
  text: '',
  tooltip: '',
};

export default flow(withStyles(styles))(MenuItem);
