import LuxonUtils from '@date-io/luxon';
import { Box, styled, TextField } from '@mui/material';
import {
  DatePicker as KeyboardDatePickerMui,
  DateTimePicker as KeyboardDateTimePickerMui,
  LocalizationProvider,
  StaticDatePicker as StaticKeyboardDatePickerMui,
  StaticDateTimePicker as StaticKeyboardDateTimePickerMui,
} from '@mui/x-date-pickers';
import { Calendar, ChevronLeft, ChevronRight } from 'app/components/icons';
import cx from 'classnames';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import uniqueId from 'lodash/uniqueId';
import {
  DatePicker as KeyboardDatePickerRff,
  DateTimePicker as KeyboardDateTimePickerRff,
  showErrorOnBlur,
} from 'mui-rff';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

const DatePickerComponent = ({
  className,
  label,
  type,
  useMuiDatePicker,
  datePickerProps,
  handleDate,
  disabled,
  hideErrorsLabel,
}) => {
  const { i18n } = useTranslation();
  const luxon = new LuxonUtils();

  let dateFormat;
  let dateFormatToPOST;
  switch (type) {
    case 'datetime':
      dateFormat = DatePickerComponent.DATETIME_FORMAT;
      dateFormatToPOST = DatePickerComponent.DATETIME_FORMAT_TO_POST;
      break;
    case 'date':
    default:
      dateFormat = DatePickerComponent.DATE_FORMAT;
      dateFormatToPOST = DatePickerComponent.DATE_FORMAT_TO_POST;
  }

  const isEnvWhereShowError =
    process.env.REACT_APP_ENV === 'development' ||
    process.env.REACT_APP_ENV === 'dev_aws' ||
    process.env.REACT_APP_ENV === 'qa_aws';

  const [initialUseMuiDatePicker, notUsed_] = React.useState(useMuiDatePicker);
  if (initialUseMuiDatePicker !== useMuiDatePicker && isEnvWhereShowError) {
    throw new Error(
      'MakoLab error: useMuiDatePicker cannot be changed between re-renders, ' +
        "because of react-hooks/rules-of-hooks (Don't call Hooks inside conditions). " +
        'useForm() is used here conditionally basing on useMuiDatePicker!'
    );
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const form = !useMuiDatePicker ? useForm() : null; // yes, we use hook conditionally, but we are ensuring earlier that useMuiDatePicker doesn't change between re-renders, so it should be OK.
  if (
    !useMuiDatePicker &&
    form.mutators.changeValue === undefined &&
    isEnvWhereShowError
  ) {
    throw new Error(
      'MakoLab error: DatePicker requires "form.mutators.changeValue" to be implemented!'
    );
  }

  const handleChange = (value) => {
    form?.mutators?.changeValue &&
      form.mutators.changeValue(
        datePickerProps.name,
        value?.toFormat(dateFormatToPOST)
      );
    !isNil(handleDate) && handleDate(value, value?.toFormat(dateFormatToPOST));
  };

  let onChange = disabled ? null : handleChange;

  if (
    !useMuiDatePicker && // in form
    isNil(handleDate) && // handleDate not passed
    isNil(form?.mutators?.changeValue) // we don't have changeValue
  ) {
    // force it to be null if:
    // - in form
    // - handleDate not passed in
    // - and changeValue has not been set in form.mutators -> use default form behaviour
    // this IF is fall back for PROD env. where JS errors will not be thrown,
    // we want to maintain old (default form behaviour) - date sometimes will be set badly,
    // with time Zone (+2:00), but at least we will not have white sreen (JS error)
    onChange = disabled || isNil(handleDate) ? null : handleDate;
  }

  if (useMuiDatePicker && isNil(onChange)) {
    // there have to be some onChange function if pure Mui date picker is used
    onChange = noop;
  }

  let DatePickerComp;
  switch (datePickerProps.variant) {
    case 'static': {
      switch (type) {
        case 'datetime':
          DatePickerComp = useMuiDatePicker
            ? StaticKeyboardDateTimePickerMuiStyled
            : () => (
                <div>
                  Mako: StaticKeyboardDateTimePickerRffStyled is not supported
                </div>
              );
          break;
        case 'date':
        default:
          DatePickerComp = useMuiDatePicker
            ? StaticKeyboardDatePickerMuiStyled
            : () => (
                <div>
                  Mako: StaticKeyboardDatePickerRffStyled is not supported
                </div>
              );
      }
      break;
    }
    case 'inline':
    default: {
      switch (type) {
        case 'datetime':
          DatePickerComp = useMuiDatePicker
            ? KeyboardDateTimePickerMuiStyled
            : KeyboardDateTimePickerRffStyled;
          break;
        case 'date':
        default:
          DatePickerComp = useMuiDatePicker
            ? KeyboardDatePickerMuiStyled
            : KeyboardDatePickerRffStyled;
      }
    }
  }
  const renderDatePicker = () => (
    <DatePickerComp
      autoOk
      ampm={false}
      disableToolbar={false}
      {...(isNil(onChange)
        ? {}
        : {
            onChange,
          })}
      {...datePickerProps}
      inputFormat={dateFormat}
      renderInput={(props) => <TextField {...props} />}
      label={label}
      disabled={disabled}
      className={cx(className)}
      InputAdornmentProps={{
        position: 'end',
        ...datePickerProps.InputAdornmentProps,
      }}
      components={{
        OpenPickerIcon: Calendar,
        LeftArrowIcon: ChevronLeft,
        RightArrowIcon: ChevronRight,
      }}
      PopperProps={{
        ...datePickerProps.PopperProps,
        sx: {
          '& .MuiPaper-root': {
            boxShadow: `rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px`,
          },
        },
      }}
      InputProps={{
        ...datePickerProps.InputProps,
      }}
      showError={hideErrorsLabel ? noop : showErrorOnBlur}
    />
  );

  return (
    <LocalizationProvider
      dateAdapter={LuxonUtils}
      adapterLocale={i18n.languages[0]}
    >
      {datePickerProps.variant === 'static' ? (
        <Box
          sx={{
            paddingTop: '8px',
          }}
        >
          <TextField
            variant='standard'
            id={uniqueId('datepicker_')}
            label={label}
            value={luxon.formatByString(
              luxon.date(datePickerProps.value),
              'yyyy-MM-dd' // this format is required by HTML input field
            )}
            onChange={handleDate}
            type='date'
            disabled={disabled}
            showError={hideErrorsLabel ? noop : showErrorOnBlur}
          />
          {renderDatePicker()}
        </Box>
      ) : (
        renderDatePicker()
      )}
    </LocalizationProvider>
  );
};

DatePickerComponent.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  type: PropTypes.string,
  useMuiDatePicker: PropTypes.bool,
  disabled: PropTypes.bool,
  handleDate: PropTypes.func,
  datePickerProps: PropTypes.shape({
    variant: PropTypes.oneOf(['dialog', 'inline', 'static']),
    name: PropTypes.string,
    value: PropTypes.string,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    InputAdornmentProps: PropTypes.object,
    PopperProps: PropTypes.object,
    InputProps: PropTypes.object,
  }),
  hideErrorsLabel: PropTypes.bool,
};

DatePickerComponent.defaultProps = {
  className: '',
  label: '',
  type: 'date',
  useMuiDatePicker: false,
  disabled: false,
  handleDate: null,
  datePickerProps: {
    variant: 'inline',
    name: null,
    value: '',
    minDate: '',
    maxDate: '',
    InputAdornmentProps: {},
    PopperProps: {},
    InputProps: {},
  },
  hideErrorsLabel: false,
};

DatePickerComponent.DATE_FORMAT = 'dd.MM.yyyy';
DatePickerComponent.DATE_FORMAT_TO_POST = 'yyyy-MM-dd';
DatePickerComponent.DATETIME_FORMAT = 'dd.MM.yyyy HH:mm';
DatePickerComponent.DATETIME_FORMAT_TO_POST = "yyyy-MM-dd'T'HH:mm:ss";

export default DatePickerComponent;

const commonDatePickerCompStyle = `
  & .MuiInputAdornment-root .MuiButtonBase-root {
    margin-right: 0px;
    margin-bottom: 7px;
  }
  & .MuiPickersToolbar-root { // top toolbar
    display: none;
  }
  & .MuiDialogActions-root { // bottom buttons
    display: none;
  }
`;

const KeyboardDateTimePickerMuiStyled = styled(KeyboardDateTimePickerMui)`
  ${commonDatePickerCompStyle}
`;
const KeyboardDateTimePickerRffStyled = styled(KeyboardDateTimePickerRff)`
  ${commonDatePickerCompStyle}
`;
const KeyboardDatePickerMuiStyled = styled(KeyboardDatePickerMui)`
  ${commonDatePickerCompStyle}
`;
const KeyboardDatePickerRffStyled = styled(KeyboardDatePickerRff)`
  ${commonDatePickerCompStyle}
`;

const StaticKeyboardDateTimePickerMuiStyled = styled(
  StaticKeyboardDateTimePickerMui
)`
  ${commonDatePickerCompStyle}
`;
const StaticKeyboardDatePickerMuiStyled = styled(StaticKeyboardDatePickerMui)`
  ${commonDatePickerCompStyle}
`;
