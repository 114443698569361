import * as React from 'react';
function SvgPrint(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M9 19h6v-1H9v1zm0-2h6v-1H9v1zm0-2h6v-1H9v1zm-1 5h8v-7H8v7zm-1 1h10v-9H7v9zM17.016 3.986H6.984v3.008H6V3h12v3.994h-.984V3.986zm2.28 4.043c1.152 0 1.704.553 1.704 1.708v6.281h-3v-.987h2.016v-5.1c0-.65-.24-.915-.864-.915H4.848c-.624 0-.864.264-.864.914v5.101h1.992v.987H3v-6.28c0-1.156.552-1.709 1.704-1.709h14.592z'
        fill='#FFF'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgPrint;
