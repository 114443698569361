import * as React from 'react';
function SvgReorder(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M20 14v1H4v-1h16zm0-5v1H4V9h16z'
        fill='#CCC'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgReorder;
