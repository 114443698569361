export default (theme) => ({
  root: {
    padding: 0,
    minHeight: 60,
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    borderRadius: 0,
  },
  icon: {
    padding: 19,
  },
  message: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  action: {
    margin: 0,
    padding: `15px 19px`,
  },

  outlinedError: {
    color: theme.palette.text.primary,
    '& .MuiAlert-icon': {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
    },
  },

  outlinedInfo: {
    color: theme.palette.text.primary,
    '& .MuiAlert-icon': {
      color: theme.palette.info.contrastText,
      backgroundColor: theme.palette.info.main,
    },
  },

  outlinedSuccess: {
    color: theme.palette.text.primary,
    '& .MuiAlert-icon': {
      color: theme.palette.success.contrastText,
      backgroundColor: theme.palette.success.main,
    },
  },

  outlinedWarning: {
    color: theme.palette.text.primary,
    '& .MuiAlert-icon': {
      color: theme.palette.warning.contrastText,
      backgroundColor: theme.palette.warning.main,
    },
  },
});
