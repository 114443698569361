import * as React from 'react';
function SvgSidebar(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <g fill='none' fillRule='evenodd'>
        <rect
          stroke='currentColor'
          x={3.5}
          y={5.5}
          width={17}
          height={13}
          rx={2}
        />
        <path
          fill='currentColor'
          d='M13 5h1v5.5h-1zM13 13.5h1V19h-1zM18.505 12.466c-.89.721-1.442 1.178-2.788 2.043l-.457-.793c.914-.577 1.346-.889 1.851-1.25H13v-.889h4.183a27.665 27.665 0 00-1.923-1.298l.457-.794c1.346.866 1.899 1.323 2.788 2.044v.937z'
        />
      </g>
    </svg>
  );
}
export default SvgSidebar;
