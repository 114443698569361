import * as React from 'react';
function SvgEdit(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M7 20.012h13v-1H7v1zm-.625-7.427a52.676 52.676 0 013.046 3.07L18.177 6.9 15.13 3.83l-8.755 8.755zm.096 4.27c.72-.12 1.44-.264 2.23-.48-.959-1.08-1.895-2.015-3.021-3.07-.24.815-.409 1.535-.529 2.254.48.432.864.816 1.32 1.296zM4 17.215c.192-1.68.36-2.567.887-4.486L15.13 2.39l4.438 4.51L9.3 17.167c-1.847.503-2.782.671-4.51.84L4 17.213z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgEdit;
