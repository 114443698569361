import * as React from 'react';
function SvgMenu(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M6 17h12v-1H6v1zm0-5h12v-1H6v1zm0-5h12V6H6v1z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgMenu;
