import includes from 'lodash/includes';
import isNil from 'lodash/isNil';

import { isTokenExpired, parseJWT } from '../core/jwt.utils';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export function loginSuccess(payload) {
  return {
    type: LOGIN_SUCCESS,
    payload,
  };
}

export const UPDATE_SWITCH_VIEW_TOKEN = 'UPDATE_SWITCH_VIEW_TOKEN';
export function updateSwitchViewToken(payload = null) {
  return (dispatch, getState_, { api: { Auth_ } }) =>
    dispatch({
      type: UPDATE_SWITCH_VIEW_TOKEN,
      payload,
    });
}

export const LOGIN_ERROR = 'LOGIN_ERROR';
export function loginError(payload) {
  return {
    type: LOGIN_ERROR,
    payload,
  };
}

export const LOGIN = 'LOGIN';
export function login({ username, password }) {
  return (dispatch, getState, { api: { Auth }, handleErrorWithPromise }) =>
    Auth.createToken({ username, password })
      .then((res) => {
        const { refreshToken, token: accessToken } = res;
        const tokenData = parseJWT(accessToken);
        if (accessToken && tokenData) {
          const response = {
            accessToken,
            refreshToken,
            tokenData,
          };
          dispatch(loginSuccess(response));
          return response;
        }
        throw new Error('Invalid credentials!');
      })
      .catch(
        handleErrorWithPromise((err) => {
          dispatch(loginError(err));
        })
      );
}

export const LOGOUT = 'LOGOUT';
export function logoutSuccess(payload) {
  return {
    type: LOGOUT,
    payload,
  };
}

export function logout(payload = null) {
  return (dispatch, getState_, { api: { Auth_ } }) =>
    Promise.resolve().then((res) => {
      dispatch(logoutSuccess(payload));
      return res;
    });
}

/**
 * Logout executed on user demand (by purpose)
 */
export function logoutPurposely(payload = null) {
  return (dispatch, getState, { api: { Auth } }) =>
    Auth.deleteToken()
      .then((res) => {
        dispatch(logoutSuccess(payload));
        return res;
      })
      .catch(() => {
        dispatch(logoutSuccess(payload));
      });
}

export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export function refreshTokenSuccess(payload) {
  return {
    type: REFRESH_TOKEN_SUCCESS,
    payload,
  };
}

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export function refreshAccessToken() {
  return (dispatch, getState, { api: { Auth }, handleErrorWithPromise }) => {
    const { authData } = getState();
    if (isNil(authData.refreshToken)) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        // we are in refreshAccessToken() and threre is no refreshToken so .reject()
        // it is here to prevent calling endpoint with refreshToken: null which results in 422 response
        message:
          '~ file: auth.actions.js ~ line 80  authData.refreshToken is null',
        name: '',
      });
    }
    return Auth.updateToken(authData.refreshToken)
      .then((res) => {
        const { refreshToken, token: accessToken } = res;
        const tokenData = parseJWT(accessToken);

        if (accessToken && tokenData) {
          const response = {
            accessToken,
            refreshToken,
            tokenData,
          };
          dispatch(refreshTokenSuccess(response));
          return response;
        }
        throw new Error('Invalid credentials!');
      })
      .catch(
        handleErrorWithPromise((err) => {
          if (
            err.refreshTokenFailedTokenExpiredOnBackEndReturnUrl &&
            !includes(window.location.href, '/logout') // and we are not on logout already (prevent loop)
          ) {
            window.location.href = `/logout?redirectUrl=${encodeURIComponent(
              err.refreshTokenFailedTokenExpiredOnBackEndReturnUrl
            )}`;
            throw err;
          }
          dispatch(loginError(err));
        })
      );
  };
}

export const VALIDATE_ACCESS_TOKEN = 'VALIDATE_ACCESS_TOKEN';
export function validateAccessToken() {
  return (dispatch, getState) => {
    const {
      authData: { accessToken, refreshToken, isLoggedIn },
    } = getState();
    let error = null;

    if (isLoggedIn) {
      if (isTokenExpired(accessToken)) {
        dispatch(refreshAccessToken());
      }

      return new Promise((resolve) => {
        const tokenData = parseJWT(accessToken);
        const response = {
          accessToken,
          refreshToken,
          tokenData,
        };
        dispatch(loginSuccess(response));
        resolve(response);
      });
    }
    return new Promise((resolve, reject) => {
      error = new Error('Unauthorized');
      dispatch(loginError(error));
      reject(error);
    });
  };
}
