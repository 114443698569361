import * as React from 'react';
function SvgSpareParts(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M18.825 5.224C19.521 4.384 20.241 4 20.937 4c.408 0 .768.072 1.128.264l-.36.888c-.264-.096-.48-.168-.696-.168-.48 0-.84.144-1.608 1.056l-1.752 2.112-.72-.648 1.896-2.28zm-.792 12.889c0-.6-.432-1.128-1.08-1.128-.648 0-1.08.528-1.08 1.128 0 .624.432 1.104 1.08 1.104.648 0 1.08-.48 1.08-1.104zm.984 0c0 1.392-1.104 2.112-2.064 2.112s-2.064-.72-2.064-2.112c0-1.176.96-2.112 2.064-2.112s2.064.936 2.064 2.112zm-10.873 0c0-.6-.432-1.128-1.08-1.128-.648 0-1.08.528-1.08 1.128 0 .624.432 1.104 1.08 1.104.648 0 1.08-.48 1.08-1.104zm.984 0c0 1.392-1.104 2.112-2.064 2.112S5 19.505 5 18.113C5 16.937 5.96 16 7.064 16s2.064.936 2.064 2.112zm7.537-7.296c-.648-.145-1.512-1.153-2.976-1.729-.96-.336-2.256-.528-3.96-.528-1.033 0-2.305.096-3.41.24l-1.08-.552-.023-.288c.024 0 2.808-.384 4.776-.384 1.56 0 3.073.216 4.129.624 1.344.504 2.4 1.56 2.76 1.632l2.016.456c.768.168 1.248.432 1.512.912.36.624.6 1.609.6 2.28 0 1.033-.696 1.512-1.776 1.512l-1.2.008v-.871c0-.6-.432-1.128-1.08-1.128-.648 0-1.08.527-1.08 1.128V15H8.144v-.871c0-.6-.432-1.128-1.08-1.128-.648 0-1.08.527-1.08 1.128v.851h-.648C4.208 14.86 3 14.2 3 12.785V11.44c0-.648.296-.985 1.688-2.4l.864.48C4.256 10.84 4 11.055 4 11.608v1.176c0 .575.376.984 1.024 1.08.12-1.056 1.032-1.848 2.04-1.848 1.104 0 2.064.936 2.064 1.991h5.76c.073-1.128 1.01-1.991 2.065-1.991 1.104 0 2.064.936 2.064 1.991h.168c.72 0 .84-.143.84-.624 0-.648-.264-1.392-.456-1.655-.168-.24-.336-.36-.888-.48l-2.016-.432zM2 4c1.776 0 2.424.312 3.144 1.32l.456.648-.816.576-.504-.768c-.384-.6-.864-.816-2.28-.816V4z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgSpareParts;
