export default ({ palette }) => ({
  tableScroll: {
    /* For Firefox */
    scrollbarColor: `${palette.gray[250]} ${palette.gray[100]}`,
    scrollbarWidth: 'thin',

    /* For Chrome */
    '&:-webkit-scrollbar': {
      backgroundColor: palette.gray[100],
    },
    '&::-webkit-scrollbar:horizontal': {
      height: 15,
      backgroundColor: palette.gray[100],
    },
    '&::-webkit-scrollbar:vertical': {
      width: 15,
    },
    '&::-webkit-scrollbar-thumb': {
      width: 15,
      backgroundColor: palette.gray[250],
    },
  },
  fixedTableNone: {
    /* For Firefox */
    scrollbarWidth: 'none',

    /* For Chrome */
    '&::-webkit-scrollbar:vertical': {
      width: 0,
    },
  },
});
