export default ({ palette }) => ({
  root: {
    position: 'absolute',
    top: 5,
    right: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    zIndex: 9999,
    cursor: 'move',

    '& .MuiTypography-caption': {
      fontSize: '.625rem',
    },
  },
  theme: {
    padding: `6px 8px 4px`,
    height: 50,
    color: palette.common.white,
    background: palette.transparentBlack[900],
  },
  performanceBtn: {
    background: 'transparent',
    padding: 0,
    border: 'none',
    color: palette.warning.main,
    cursor: 'pointer',
    fontSize: '.625rem',
  },
});
