import { ApiCore } from '../_utilities/core';

const repairs = new ApiCore({
  url: '/expertise/repairs',
});

const repair = new ApiCore({
  url: '/expertise/repairs/:id',
});

const elementParameters = new ApiCore({
  url: '/expertise/repairs/element-parameters',
});

const items = new ApiCore({
  url: '/expertise/repairs/items',
});

const selectedItems = new ApiCore({
  url: '/expertise/repairs/element-parameter/:elementParameterId/items',
});

export default {
  getRepairs: (queryString) => repairs.query({ queryString }).read(),
  getRepair: (id) => repair.query({ urlParams: { id } }).read(),
  getElementParameters: () => elementParameters.read(),
  getItems: () => items.read(),
  getSelectedItems: (
    elementParameterId,
    locationParameterId,
    modelName,
    centerId
  ) =>
    selectedItems
      .query({
        urlParams: { elementParameterId },
        queryString: {
          locationParameterId,
          modelName,
          centerId,
        },
      })
      .read(),
  createRepair: (data) => repairs.create(data),
  updateRepair: (id, data) => repair.query({ urlParams: { id } }).update(data),
  deleteRepair: (id) => repair.query({ urlParams: { id } }).delete(),
};
