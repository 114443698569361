import { ApiCore } from '../_utilities/core';

const performanceTable = new ApiCore({
  url: '/auth/test',
});

export default {
  getPerformanceTable: (queryString) =>
    performanceTable.query({ queryString }).read(),
};
