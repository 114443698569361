import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  APP_VERSION_SUFIX,
  REACT_APP_ENV,
  REACT_APP_VERSION,
} from 'app/core/constants';
import {
  getLocalStorage,
  setLocalStorage,
} from 'app/core/local-storage-handler';
import { getLocalStorageAttributeTitle } from 'app/helpers/table.helpers';
import cx from 'classnames';
import flow from 'lodash/flow';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import React, { useLayoutEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import styles from './app-version.jss';

const DEFAULT_Y = 0;
const DEFAULT_X = 0;

function AppVersion({ classes }) {
  const { t } = useTranslation();
  const h = useHistory();
  const [defaultPosition, setDefaultPosition] = useState({
    x: getLocalStorage(
      getLocalStorageAttributeTitle('x', 0, APP_VERSION_SUFIX),
      DEFAULT_X
    ),
    y: getLocalStorage(
      getLocalStorageAttributeTitle('y', 0, APP_VERSION_SUFIX),
      DEFAULT_Y
    ),
  });

  const dontShowAppVersion =
    REACT_APP_ENV === 'prod' || REACT_APP_ENV === 'uat_aws';

  useLayoutEffect(() => {
    if (dontShowAppVersion) {
      return;
    }

    setTimeout(() => {
      // it is inside setTimeout because just right after initialization getBoundingClientRect() returns "0" for .right and .bottom props

      const draggableAppVersionBounding = document
        .getElementById('draggableAppVersion')
        ?.getBoundingClientRect();

      if (isNil(draggableAppVersionBounding)) {
        // it sometimes happened on page reload that getElementById('draggableAppVersion') was undefined
        // since it happend only on page reload I assume we can ignore the following code execution
        return;
      }

      // region calculate windowWidth & windowHeight
      const tempElement = document.createElement('div');
      tempElement.style.width = '100%';
      tempElement.style.height = '100%';
      tempElement.style.zIndex = '-99999';
      tempElement.style.position = 'absolute';
      document.getElementsByTagName('body')[0].appendChild(tempElement);

      const tempElement2 = document.createElement('div');
      tempElement.style.width = '100vw';
      tempElement.style.height = '100vh';
      tempElement.style.zIndex = '-99999';
      tempElement.style.position = 'absolute';
      document.getElementsByTagName('body')[0].appendChild(tempElement2);

      // const browsersTollbarHeight =
      //   tempElement2.offsetHeight - // take it's height because it is set to 100vh
      //   tempElement.offsetHeight; // this ones height is 100%
      const windowWidth = tempElement.offsetWidth;
      const windowHeight = tempElement.offsetHeight;

      // alert(`width te: ${tempElement.offsetWidth}, te2: ${tempElement2.offsetWidth}, `);
      // alert(`heigth te: ${tempElement.offsetHeight}, te2: ${tempElement2.offsetHeight}, `);

      document.getElementsByTagName('body')[0].removeChild(tempElement);
      document.getElementsByTagName('body')[0].removeChild(tempElement2);
      // endregion calculate windowWidth & windowHeight

      // alert(`${windowWidth} ${windowHeight} ${draggableAppVersionBounding.right} ${draggableAppVersionBounding.bottom}`);
      if (draggableAppVersionBounding.right > windowWidth) {
        setDefaultPosition({
          ...defaultPosition,
          x: 50,
        });
      }
      if (draggableAppVersionBounding.bottom > windowHeight) {
        setDefaultPosition({
          ...defaultPosition,
          y: windowHeight - draggableAppVersionBounding.height - 10,
        });
      }
    }, 500);
  }, [defaultPosition.x, defaultPosition.y]);

  if (dontShowAppVersion) {
    return null;
  }

  const goToPerformance = () => {
    h.push('/auth/test');
  };

  return (
    <Draggable
      position={defaultPosition}
      onStop={(e, data) => {
        const { x, y } = data;

        setLocalStorage(
          getLocalStorageAttributeTitle('x', 0, APP_VERSION_SUFIX),
          x
        );

        setLocalStorage(
          getLocalStorageAttributeTitle('y', 0, APP_VERSION_SUFIX),
          y
        );

        setDefaultPosition({ x, y });
      }}
    >
      <div
        id='draggableAppVersion'
        className={cx(classes.root, classes.theme)}
        onDoubleClick={() => {
          setLocalStorage(
            getLocalStorageAttributeTitle('x', 0, APP_VERSION_SUFIX),
            DEFAULT_X
          );

          setLocalStorage(
            getLocalStorageAttributeTitle('y', 0, APP_VERSION_SUFIX),
            DEFAULT_Y
          );

          setDefaultPosition({ x: DEFAULT_X, y: DEFAULT_Y });
        }}
      >
        <Typography variant='caption'>
          {process.env.REACT_APP_ENV === 'qa_aws' ? (
            <span>VLM</span>
          ) : (
            <span>{t('app_name')}</span>
          )}
          <br />
          <span>{t('app_env', { env: REACT_APP_ENV })}</span>
          {' | '}
          <span>{t('app_version', { version: REACT_APP_VERSION })}</span>
          <br />
          <button
            onClick={goToPerformance}
            type='button'
            className={cx(classes.performanceBtn)}
          >
            {t('common_buttons_testTable')}
          </button>
        </Typography>
      </div>
    </Draggable>
  );
}

AppVersion.propTypes = {
  classes: PropTypes.object,
};

AppVersion.defaultProps = {
  classes: {},
};

export default flow(withStyles(styles))(AppVersion);
