import { ApiCore } from '../_utilities/core';

const registrationCertificateImport = new ApiCore({
  url: '/interop/registration-certificate-imports/:id',
});
const registrationCertificateImportItems = new ApiCore({
  url: '/interop/registration-certificate-imports/:importId/items',
});
const handleRegistrationCertificateImportItems = new ApiCore({
  url: '/interop/registration-certificate-imports/:importId/items/:id',
});
const startRegistrationCertificateImport = new ApiCore({
  url: '/interop/registration-certificate-imports/:id/start',
});
const getRegistrationCertificateImportItem = new ApiCore({
  url: '/interop/registration-certificate-imports/:importId/items/:id',
  responseType: 'blob',
});

export default {
  getRegistrationCertificateImports: (queryString) =>
    new ApiCore({
      url: '/interop/registration-certificate-imports',
    })
      .query({ queryString })
      .read(),
  createRegistrationCertificateImports: () =>
    new ApiCore({
      url: '/interop/registration-certificate-imports',
    }).create(),
  deleteRegistrationCertificateImports: (queryString) =>
    new ApiCore({
      url: '/interop/registration-certificate-imports',
    })
      .query({ queryString })
      .delete(),
  getRegistrationCertificateImport: (id) =>
    registrationCertificateImport.query({ urlParams: { id } }).read(),
  uploadItem: (importId, data) =>
    registrationCertificateImportItems
      .query({ urlParams: { importId } })
      .create(data),
  deleteItem: (importId, id) =>
    handleRegistrationCertificateImportItems
      .query({ urlParams: { importId, id } })
      .delete(),
  updateItem: (importId, id, data) =>
    handleRegistrationCertificateImportItems
      .query({ urlParams: { importId, id } })
      .update(data),
  startRegistrationCertificateImport: (id) =>
    startRegistrationCertificateImport.query({ urlParams: { id } }).create(),
  getItem: (importId, id) =>
    getRegistrationCertificateImportItem
      .query({ urlParams: { importId, id } })
      .read(),
  deleteFileToImport: (importId, id) =>
    new ApiCore({
      url: '/interop/registration-certificate-imports/:importId/items/:id',
    })
      .query({ urlParams: { importId, id } })
      .delete(),
};
