import { ApiCore } from '../_utilities/core';

const paymentTerms = new ApiCore({
  url: '/finance/payment-terms',
});

const paymentTerm = new ApiCore({
  url: '/finance/payment-terms/:id',
});

export default {
  getPaymentTerms: (queryString) => paymentTerms.query({ queryString }).read(),
  getPaymentTerm: (id) => paymentTerm.query({ urlParams: { id } }).read(),
  createPaymentTerm: (data) => paymentTerms.create(data),
  updatePaymentTerm: (id, data) =>
    paymentTerm.query({ urlParams: { id } }).update(data),
  deletePaymentTerm: (id) => paymentTerm.query({ urlParams: { id } }).delete(),
  getPaymentTermsItems: () =>
    new ApiCore({
      url: '/finance/payment-terms/items',
    })
      .query()
      .read(),
};
