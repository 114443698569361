import { List } from '@mui/material';
import { withStyles } from '@mui/styles';
import CustomScrollbars from 'app/components/shared/custom-scrollbars/custom-scrollbars';
import cx from 'classnames';
import flow from 'lodash/flow';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './sidebar-menu.jss';
import SidebarMenuItem from './sidebar-menu-item';

function SidebarMenu({ classes, disableScrollbars, menuItems, open }) {
  return (
    <div className={cx(classes.root)}>
      <CustomScrollbars
        disableScrollbars={disableScrollbars}
        hideTracksWhenNotNeeded
      >
        <List classes={{ root: classes.root }} component='nav' disablePadding>
          {map(menuItems, (item, index) => (
            <SidebarMenuItem key={index} fullWidth={open} {...item} />
          ))}
        </List>
      </CustomScrollbars>
    </div>
  );
}

SidebarMenu.propTypes = {
  classes: PropTypes.object,
  disableScrollbars: PropTypes.bool,
  menuItems: PropTypes.array.isRequired,
  open: PropTypes.bool,
};

SidebarMenu.defaultProps = {
  classes: {},
  disableScrollbars: false,
  open: false,
};

export default flow(withStyles(styles))(SidebarMenu);
