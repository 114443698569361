import { ApiCore } from '../_utilities/core';

const documentImport = new ApiCore({
  url: '/interop/document-imports/:id',
});
const documentImportItems = new ApiCore({
  url: '/interop/document-imports/:importId/items',
});
const handleDocumentImportItems = new ApiCore({
  url: '/interop/document-imports/:importId/items/:id',
});
const startDocumentImport = new ApiCore({
  url: '/interop/document-imports/:id/start',
});
const getDocumentImportItem = new ApiCore({
  url: '/interop/document-imports/:importId/items/:id',
  responseType: 'blob',
});

export default {
  getDocumentImports: (queryString) =>
    new ApiCore({
      url: '/interop/document-imports',
    })
      .query({ queryString })
      .read(),
  createDocumentImports: () =>
    new ApiCore({
      url: '/interop/document-imports',
    }).create(),
  deleteDocumentImports: (queryString) =>
    new ApiCore({
      url: '/interop/document-imports',
    })
      .query({ queryString })
      .delete(),
  getDocumentImport: (id) => documentImport.query({ urlParams: { id } }).read(),
  uploadItem: (importId, data) =>
    documentImportItems.query({ urlParams: { importId } }).create(data),
  deleteItem: (importId, id) =>
    handleDocumentImportItems.query({ urlParams: { importId, id } }).delete(),
  updateItem: (importId, id, data) =>
    handleDocumentImportItems
      .query({ urlParams: { importId, id } })
      .update(data),
  startDocumentImport: (id) =>
    startDocumentImport.query({ urlParams: { id } }).create(),
  getItem: (importId, id) =>
    getDocumentImportItem.query({ urlParams: { importId, id } }).read(),
  deleteFileToImport: (importId, id) =>
    new ApiCore({
      url: '/interop/document-imports/:importId/items/:id',
    })
      .query({ urlParams: { importId, id } })
      .delete(),
};
