import * as React from 'react';
function SvgArrowUp(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M11.912 4.5c2.257 2.793 3.98 5.166 6.237 8.61l-.814.56c-1.723-2.7-3.166-4.772-5.33-7.61V20h-1V6.106c-2.165 2.839-3.608 4.91-5.33 7.61l-.815-.605C7.117 9.666 8.84 7.293 11.097 4.5h.815z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgArrowUp;
