import * as React from 'react';
function SvgInformation(props) {
  return (
    <svg width={24} height={25} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M13.293 7.652c0 .577-.264.794-.793.794s-.793-.217-.793-.794c0-.577.264-.794.793-.794s.793.217.793.794zM10.05 9.48h2.932v5.053H15v.939h-4.951v-.939h1.946v-4.09h-1.946V9.48zM12.5 19.634c.385-.65.673-1.323.986-2.094h4.639c.577 0 .89-.312.89-.842V6.304c0-.553-.313-.842-.866-.842H6.827c-.505 0-.842.314-.842.819v10.417c0 .506.337.842.842.842h4.687c.313.77.601 1.444.986 2.094zm1.659-1.131c-.337.722-.721 1.443-1.13 1.997h-1.058c-.408-.554-.793-1.275-1.13-1.997H6.708C5.673 18.503 5 17.829 5 16.795V6.185C5 5.173 5.697 4.5 6.707 4.5h11.586c1.13 0 1.707.577 1.707 1.708v10.587c0 1.082-.673 1.708-1.755 1.708H14.16z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgInformation;
