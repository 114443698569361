import { ApiCore } from '../_utilities/core';

const organisations = new ApiCore({
  url: '/auth/organisations',
});

const organisation = new ApiCore({
  url: '/auth/organisations/:id',
});

const types = new ApiCore({
  url: '/auth/organisations/types',
});

const statuses = new ApiCore({
  url: '/auth/organisations/statuses',
});

const dealersAndRetails = new ApiCore({
  url: '/auth/organisations/dealers-and-retails',
});

const organisationsNames = new ApiCore({
  url: '/auth/organisations/names',
});

const organisationsNamesByType = new ApiCore({
  url: '/auth/organisations/:type/names',
});

export default {
  getOrganisations: (queryString) =>
    organisations.query({ queryString }).read(),
  getOrganisation: (id) => organisation.query({ urlParams: { id } }).read(),
  createOrganisation: (data) => organisations.create(data),
  updateOrganisation: (id, data) =>
    organisation.query({ urlParams: { id } }).update(data),
  deleteOrganisation: (id) =>
    organisation.query({ urlParams: { id } }).delete(),
  getTypes: () => types.read(),
  getStatuses: () => statuses.read(),
  getAllDealersAndRetails: () => dealersAndRetails.read(),
  getNames: (queryString) => organisationsNames.query({ queryString }).read(),
  getNamesByType: (type) => (queryString) =>
    organisationsNamesByType.query({ queryString, urlParams: { type } }).read(),
  getAttributesItems: () =>
    new ApiCore({
      url: '/auth/organisations/units/attributes',
    })
      .query()
      .read(),
  getInvoicingItems: () =>
    new ApiCore({
      url: '/auth/organisations/units/Invoicing',
    })
      .query()
      .read(),
  getAttributeItems: (attribute) =>
    new ApiCore({
      url: '/auth/organisations/units/:attribute',
    })
      .query({ urlParams: { attribute } })
      .read(),
  getSystemAccounts: (id) => () =>
    new ApiCore({
      url: '/auth/organisations/:id/system-accounts',
    })
      .query({ urlParams: { id } })
      .read(),
};
