import * as React from 'react';
function SvgAdd(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M11 12H5v-1h6V5h1v6h6v1h-6v6h-1z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgAdd;
