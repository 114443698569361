import { ApiCore } from '../_utilities/core';

const settings = new ApiCore({
  url: '/auth/users/settings',
});

export default {
  getSettings: () => settings.read(),
  updateSettings: (data) => settings.update(data),
};
