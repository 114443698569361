import * as React from 'react';
function SvgBell(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M13.55 16.01h-3.072c.072.719.624 1.055 1.537 1.055.912 0 1.463-.336 1.535-1.056zm-3.192-5.373l-.984-.216.457-1.703c.335-1.247.936-1.703 2.255-1.703v.983c-.84 0-1.08.144-1.296.96l-.431 1.679zM8.704 8.934l-.817 2.95c-.336 1.247-.624 1.942-1.44 3.142h11.113c-.816-1.2-1.104-1.895-1.44-3.142l-.816-2.95c-.6-2.159-1.465-2.95-3.289-2.95s-2.736.791-3.311 2.95zm8.4 2.806c.432 1.487.72 2.062 1.752 3.286v.983h-4.296C14.439 17.28 13.503 18 12.015 18c-1.489 0-2.425-.72-2.545-1.99H5.15v-.984c1.032-1.224 1.32-1.799 1.752-3.286l.816-2.998C8.438 6.127 9.686 5 12.014 5c2.329 0 3.553 1.055 4.273 3.742l.816 2.998z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgBell;
