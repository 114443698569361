export default ({ palette }) => [
  'none',
  '0 1px 5px 0 rgba(0,0,0,0.2)',
  '1px 2px 10px 2px rgb(0, 0, 0, 0.2)',
  '0 1px 15px 0 rgb(0, 0, 0, 0.2)',
  '0 1px 20px 0 rgba(0,0,0,0.2)',
  '2px 0px 4px 2px rgba(0,0,0,0.1)',
  `0px -2px 15px 5px ${palette.gray[200]}`,
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
];
