export default {
  offset: {
    xs: 3,
    md: 5,
    lg: 10,
  },
  margins: {
    xs: 2,
    s: 4,
    md: 8,
    lg: 16,
    xl: 32,
  },
  button: {
    medium: 40,
    small: 30,
  },
  cell: {
    width: 120,
    maxWidth: 320,
    height: 40,
    gutter: 8,
  },
  form: {
    container: {
      width: 320,
    },
    gutter: 20,
    inputWrapper: {
      gutter: 6,
      error: 15,
    },
  },
  icon: 24,
  logo: 40,
  scrollbar: 15,
  toolbar: {
    regular: 60,
    dense: 40,
    gutter: 10,
  },
  filterPopover: {
    rootHeight: 200,
    footerHeight: 60,
  },
  textFilter: {
    listRootHeight: 300,
  },
  filterSpinerSquare: 200,
  sliderThumbSquare: 16,
  filterText: {
    paddingTop: 8,
    inputsSeparatorRoot: {
      paddingLeftRight: 7,
      paddingTop: 12,
    },
  },
  table: {
    offset: 2,
    columnFound: 3,
  },
  csb: {
    gutterBottom: 15,
  },
  sidebar: {
    border: 1,
    width: 320 + (15 - 6), // 15px is the new scroll width, 6px is the old
  },
  manageList: {
    menuItemWidth: 320,
    itemHeight: 40,
    footer: {
      height: 60,
      gutter: 10,
    },
    iconButton: {
      padding: '0',
      margin: '0 3px',
      borderRadius: 0,
    },
  },
};
