// WARNING!
// This file is generated automatically by 'makolab-routes' npm script, please DO NOT edit it!
// If you want to add new component to routes-map, create a new file
// with it's name matching: 'COMPONENT_TYPE.page.js' in 'src/components' directory,
// where 'COMPONENT_TYPE' is any kebab-case name

import React from 'react';

export const routesMap = {
  AuthOffDaysCreatePage: React.lazy(() => import('./app/pages/auth/off-days-create.page.js')),
  AuthOffDaysUpdatePage: React.lazy(() => import('./app/pages/auth/off-days-update.page.js')),
  AuthOffDaysPage: React.lazy(() => import('./app/pages/auth/off-days.page.js')),
  AuthOrganisationsCreatePage: React.lazy(() => import('./app/pages/auth/organisations-create.page.js')),
  AuthOrganisationsUpdatePage: React.lazy(() => import('./app/pages/auth/organisations-update.page.js')),
  AuthOrganisationsPage: React.lazy(() => import('./app/pages/auth/organisations.page.js')),
  AuthProfilesCreatePage: React.lazy(() => import('./app/pages/auth/profiles-create.page.js')),
  AuthProfilesUpdatePage: React.lazy(() => import('./app/pages/auth/profiles-update.page.js')),
  AuthProfilesPage: React.lazy(() => import('./app/pages/auth/profiles.page.js')),
  AuthUsersCreatePage: React.lazy(() => import('./app/pages/auth/users-create.page.js')),
  AuthUsersUpdatePage: React.lazy(() => import('./app/pages/auth/users-update.page.js')),
  AuthUsersPage: React.lazy(() => import('./app/pages/auth/users.page.js')),
  DashboardPage: React.lazy(() => import('./app/pages/dashboard.page.js')),
  ExpertiseDamagesCreatePage: React.lazy(() => import('./app/pages/expertise/damages-create.page.js')),
  ExpertiseDamagesUpdatePage: React.lazy(() => import('./app/pages/expertise/damages-update.page.js')),
  ExpertiseDamagesPage: React.lazy(() => import('./app/pages/expertise/damages.page.js')),
  ExpertiseParametersCreatePage: React.lazy(() => import('./app/pages/expertise/parameters-create.page.js')),
  ExpertiseParametersUpdatePage: React.lazy(() => import('./app/pages/expertise/parameters-update.page.js')),
  ExpertiseParametersPage: React.lazy(() => import('./app/pages/expertise/parameters.page.js')),
  ExpertisePriceListsCreatePage: React.lazy(() => import('./app/pages/expertise/price-lists-create.page.js')),
  ExpertisePriceListsUpdatePage: React.lazy(() => import('./app/pages/expertise/price-lists-update.page.js')),
  ExpertisePriceListsPage: React.lazy(() => import('./app/pages/expertise/price-lists.page.js')),
  ExpertiseRepairsCreatePage: React.lazy(() => import('./app/pages/expertise/repairs-create.page.js')),
  ExpertiseRepairsUpdatePage: React.lazy(() => import('./app/pages/expertise/repairs-update.page.js')),
  ExpertiseRepairsPage: React.lazy(() => import('./app/pages/expertise/repairs.page.js')),
  ExpertiseVehicleExpertisesTakeCreatePage: React.lazy(() => import('./app/pages/expertise/vehicle-expertises-take-create.page.js')),
  ExpertiseVehicleExpertisesUpdatePage: React.lazy(() => import('./app/pages/expertise/vehicle-expertises-update.page.js')),
  ExpertiseVehicleExpertisesPage: React.lazy(() => import('./app/pages/expertise/vehicle-expertises.page.js')),
  FinanceCurrenciesCreatePage: React.lazy(() => import('./app/pages/finance/currencies-create.page.js')),
  FinanceCurrenciesUpdatePage: React.lazy(() => import('./app/pages/finance/currencies-update.page.js')),
  FinanceCurrenciesPage: React.lazy(() => import('./app/pages/finance/currencies.page.js')),
  FinanceCurrencyRatesCreatePage: React.lazy(() => import('./app/pages/finance/currency-rates-create.page.js')),
  FinanceCurrencyRatesUpdatePage: React.lazy(() => import('./app/pages/finance/currency-rates-update.page.js')),
  FinanceCurrencyRatesPage: React.lazy(() => import('./app/pages/finance/currency-rates.page.js')),
  FinanceDocumentTemplatesCreatePage: React.lazy(() => import('./app/pages/finance/document-templates-create.page.js')),
  FinanceDocumentTemplatesUpdatePage: React.lazy(() => import('./app/pages/finance/document-templates-update.page.js')),
  FinanceDocumentTemplatesPage: React.lazy(() => import('./app/pages/finance/document-templates.page.js')),
  FinanceDocumentsBuybackUpdatePage: React.lazy(() => import('./app/pages/finance/documents-buyback-update.page.js')),
  FinanceDocumentsCorrectionPage: React.lazy(() => import('./app/pages/finance/documents-correction.page.js')),
  FinanceDocumentsCreatePage: React.lazy(() => import('./app/pages/finance/documents-create.page.js')),
  FinanceDocumentsResaleUpdatePage: React.lazy(() => import('./app/pages/finance/documents-resale-update.page.js')),
  FinanceDocumentsPage: React.lazy(() => import('./app/pages/finance/documents.page.js')),
  FinanceNumberingSchemasCreatePage: React.lazy(() => import('./app/pages/finance/numbering-schemas-create.page.js')),
  FinanceNumberingSchemasUpdatePage: React.lazy(() => import('./app/pages/finance/numbering-schemas-update.page.js')),
  FinanceNumberingSchemasPage: React.lazy(() => import('./app/pages/finance/numbering-schemas.page.js')),
  FinancePaymentMethodsCreatePage: React.lazy(() => import('./app/pages/finance/payment-methods-create.page.js')),
  FinancePaymentMethodsUpdatePage: React.lazy(() => import('./app/pages/finance/payment-methods-update.page.js')),
  FinancePaymentMethodsPage: React.lazy(() => import('./app/pages/finance/payment-methods.page.js')),
  FinancePaymentTermsCreatePage: React.lazy(() => import('./app/pages/finance/payment-terms-create.page.js')),
  FinancePaymentTermsUpdatePage: React.lazy(() => import('./app/pages/finance/payment-terms-update.page.js')),
  FinancePaymentTermsPage: React.lazy(() => import('./app/pages/finance/payment-terms.page.js')),
  FinanceProductsCreatePage: React.lazy(() => import('./app/pages/finance/products-create.page.js')),
  FinanceProductsUpdatePage: React.lazy(() => import('./app/pages/finance/products-update.page.js')),
  FinanceProductsPage: React.lazy(() => import('./app/pages/finance/products.page.js')),
  FinanceSettlementsCreatePage: React.lazy(() => import('./app/pages/finance/settlements-create.page.js')),
  FinanceSettlementsUpdatePage: React.lazy(() => import('./app/pages/finance/settlements-update.page.js')),
  FinanceSettlementsPage: React.lazy(() => import('./app/pages/finance/settlements.page.js')),
  FinanceTaxRatesCreatePage: React.lazy(() => import('./app/pages/finance/tax-rates-create.page.js')),
  FinanceTaxRatesUpdatePage: React.lazy(() => import('./app/pages/finance/tax-rates-update.page.js')),
  FinanceTaxRatesPage: React.lazy(() => import('./app/pages/finance/tax-rates.page.js')),
  FinanceVehiclesUpdatePage: React.lazy(() => import('./app/pages/finance/vehicles-update.page.js')),
  FinanceVehiclesPage: React.lazy(() => import('./app/pages/finance/vehicles.page.js')),
  ForgotPasswordPage: React.lazy(() => import('./app/pages/forgot-password.page.js')),
  InspectionServicesCreatePage: React.lazy(() => import('./app/pages/inspection/services-create.page.js')),
  InspectionServicesUpdatePage: React.lazy(() => import('./app/pages/inspection/services-update.page.js')),
  InspectionServicesPage: React.lazy(() => import('./app/pages/inspection/services.page.js')),
  InspectionVehicleInspectionsUpdatePage: React.lazy(() => import('./app/pages/inspection/vehicle-inspections-update.page.js')),
  InspectionVehicleInspectionsPage: React.lazy(() => import('./app/pages/inspection/vehicle-inspections.page.js')),
  InsuranceExclusionsCreatePage: React.lazy(() => import('./app/pages/insurance/exclusions-create.page.js')),
  InsuranceExclusionsUpdatePage: React.lazy(() => import('./app/pages/insurance/exclusions-update.page.js')),
  InsuranceExclusionsPage: React.lazy(() => import('./app/pages/insurance/exclusions.page.js')),
  InsuranceInsurersCreatePage: React.lazy(() => import('./app/pages/insurance/insurers-create.page.js')),
  InsuranceInsurersUpdatePage: React.lazy(() => import('./app/pages/insurance/insurers-update.page.js')),
  InsuranceInsurersPage: React.lazy(() => import('./app/pages/insurance/insurers.page.js')),
  InsurancePoliciesPage: React.lazy(() => import('./app/pages/insurance/policies.page.js')),
  InteropAnnualPolicyImportsCreatePage: React.lazy(() => import('./app/pages/interop/annual-policy-imports-create.page.js')),
  InteropAnnualPolicyImportsDetailsPage: React.lazy(() => import('./app/pages/interop/annual-policy-imports-details.page.js')),
  InteropAnnualPolicyImportsEditPage: React.lazy(() => import('./app/pages/interop/annual-policy-imports-edit.page.js')),
  InteropAnnualPolicyImportsPage: React.lazy(() => import('./app/pages/interop/annual-policy-imports.page.js')),
  InteropDocumentImportsCreatePage: React.lazy(() => import('./app/pages/interop/document-imports-create.page.js')),
  InteropDocumentImportsDetailsPage: React.lazy(() => import('./app/pages/interop/document-imports-details.page.js')),
  InteropDocumentImportsEditPage: React.lazy(() => import('./app/pages/interop/document-imports-edit.page.js')),
  InteropDocumentImportsPage: React.lazy(() => import('./app/pages/interop/document-imports.page.js')),
  InteropInvoiceImportsCreatePage: React.lazy(() => import('./app/pages/interop/invoice-imports-create.page.js')),
  InteropInvoiceImportsDetailsPage: React.lazy(() => import('./app/pages/interop/invoice-imports-details.page.js')),
  InteropInvoiceImportsEditPage: React.lazy(() => import('./app/pages/interop/invoice-imports-edit.page.js')),
  InteropInvoiceImportsPage: React.lazy(() => import('./app/pages/interop/invoice-imports.page.js')),
  InteropRegistrationCertificateImportsCreatePage: React.lazy(() => import('./app/pages/interop/registration-certificate-imports-create.page.js')),
  InteropRegistrationCertificateImportsDetailsPage: React.lazy(() => import('./app/pages/interop/registration-certificate-imports-details.page.js')),
  InteropRegistrationCertificateImportsEditPage: React.lazy(() => import('./app/pages/interop/registration-certificate-imports-edit.page.js')),
  InteropRegistrationCertificateImportsPage: React.lazy(() => import('./app/pages/interop/registration-certificate-imports.page.js')),
  InteropRegistrationDataImportsCreatePage: React.lazy(() => import('./app/pages/interop/registration-data-imports-create.page.js')),
  InteropRegistrationDataImportsDetailsPage: React.lazy(() => import('./app/pages/interop/registration-data-imports-details.page.js')),
  InteropRegistrationDataImportsEditPage: React.lazy(() => import('./app/pages/interop/registration-data-imports-edit.page.js')),
  InteropRegistrationDataImportsPage: React.lazy(() => import('./app/pages/interop/registration-data-imports.page.js')),
  InteropStockVehiclesPage: React.lazy(() => import('./app/pages/interop/stock-vehicles.page.js')),
  LoginPage: React.lazy(() => import('./app/pages/login.page.js')),
  LogoutPurposelyPage: React.lazy(() => import('./app/pages/logout-purposely.page.js')),
  LogoutPage: React.lazy(() => import('./app/pages/logout.page.js')),
  NoAccessPage: React.lazy(() => import('./app/pages/no-access.page.js')),
  NotFoundPage: React.lazy(() => import('./app/pages/not-found.page.js')),
  OfferOrganisationGroupsCreatePage: React.lazy(() => import('./app/pages/offer/organisation-groups-create.page.js')),
  OfferOrganisationGroupsUpdatePage: React.lazy(() => import('./app/pages/offer/organisation-groups-update.page.js')),
  OfferOrganisationGroupsPage: React.lazy(() => import('./app/pages/offer/organisation-groups.page.js')),
  OfferSalesActionsCreatePage: React.lazy(() => import('./app/pages/offer/sales-actions-create.page.js')),
  OfferSalesActionsUpdatePage: React.lazy(() => import('./app/pages/offer/sales-actions-update.page.js')),
  OfferSalesActionsPage: React.lazy(() => import('./app/pages/offer/sales-actions.page.js')),
  OfferVehiclePackagesUpdatePage: React.lazy(() => import('./app/pages/offer/vehicle-packages-update.page.js')),
  OfferVehiclePackagesPage: React.lazy(() => import('./app/pages/offer/vehicle-packages.page.js')),
  RegistrationRegistrationOfficesCreatePage: React.lazy(() => import('./app/pages/registration/registration-offices-create.page.js')),
  RegistrationRegistrationOfficesUpdatePage: React.lazy(() => import('./app/pages/registration/registration-offices-update.page.js')),
  RegistrationRegistrationOfficesPage: React.lazy(() => import('./app/pages/registration/registration-offices.page.js')),
  RegistrationStatementBuybackedsPage: React.lazy(() => import('./app/pages/registration/statement-buybackeds.page.js')),
  RegistrationStatementResoldsPage: React.lazy(() => import('./app/pages/registration/statement-resolds.page.js')),
  ReportExpertiseAndRepairCostsPage: React.lazy(() => import('./app/pages/report/expertise-and-repair-costs.page.js')),
  ReportExpertiseCostsPage: React.lazy(() => import('./app/pages/report/expertise-costs.page.js')),
  ReportRepairCostsPage: React.lazy(() => import('./app/pages/report/repair-costs.page.js')),
  ReportStorageDaysPage: React.lazy(() => import('./app/pages/report/storage-days.page.js')),
  ReportStorageDetailsPage: React.lazy(() => import('./app/pages/report/storage-details.page.js')),
  ReportStoredVehiclesPage: React.lazy(() => import('./app/pages/report/stored-vehicles.page.js')),
  ReportTakenVehiclesPage: React.lazy(() => import('./app/pages/report/taken-vehicles.page.js')),
  ResetPasswordPage: React.lazy(() => import('./app/pages/reset-password.page.js')),
  SettingsUpdatePage: React.lazy(() => import('./app/pages/settings-update.page.js')),
  TestPerformanceCreatePage: React.lazy(() => import('./app/pages/test/performance-create.page.js')),
  TestPerformancePage: React.lazy(() => import('./app/pages/test/performance.page.js')),
  VehicleActiveVehiclesCreatePage: React.lazy(() => import('./app/pages/vehicle/active-vehicles-create.page.js')),
  VehicleActiveVehiclesGroupEditPage: React.lazy(() => import('./app/pages/vehicle/active-vehicles-group-edit.page.js')),
  VehicleActiveVehiclesUpdatePage: React.lazy(() => import('./app/pages/vehicle/active-vehicles-update.page.js')),
  VehicleActiveVehiclesPage: React.lazy(() => import('./app/pages/vehicle/active-vehicles.page.js')),
  VehicleAdditionalCostsCreatePage: React.lazy(() => import('./app/pages/vehicle/additional-costs-create.page.js')),
  VehicleAdditionalCostsUpdatePage: React.lazy(() => import('./app/pages/vehicle/additional-costs-update.page.js')),
  VehicleAdditionalCostsPage: React.lazy(() => import('./app/pages/vehicle/additional-costs.page.js')),
  VehicleAdditionalEquipmentsCreatePage: React.lazy(() => import('./app/pages/vehicle/additional-equipments-create.page.js')),
  VehicleAdditionalEquipmentsUpdatePage: React.lazy(() => import('./app/pages/vehicle/additional-equipments-update.page.js')),
  VehicleAdditionalEquipmentsPage: React.lazy(() => import('./app/pages/vehicle/additional-equipments.page.js')),
  VehicleCommonContentsCreatePage: React.lazy(() => import('./app/pages/vehicle/common-contents-create.page.js')),
  VehicleCommonContentsUpdatePage: React.lazy(() => import('./app/pages/vehicle/common-contents-update.page.js')),
  VehicleCommonContentsPage: React.lazy(() => import('./app/pages/vehicle/common-contents.page.js')),
  VehicleDepreciationsCreatePage: React.lazy(() => import('./app/pages/vehicle/depreciations-create.page.js')),
  VehicleDepreciationsUpdatePage: React.lazy(() => import('./app/pages/vehicle/depreciations-update.page.js')),
  VehicleDepreciationsPage: React.lazy(() => import('./app/pages/vehicle/depreciations.page.js')),
  VehicleDesignedVehiclesCreatePage: React.lazy(() => import('./app/pages/vehicle/designed-vehicles-create.page.js')),
  VehicleDesignedVehiclesUpdatePage: React.lazy(() => import('./app/pages/vehicle/designed-vehicles-update.page.js')),
  VehicleDesignedVehiclesPage: React.lazy(() => import('./app/pages/vehicle/designed-vehicles.page.js')),
  VehicleModelsCreatePage: React.lazy(() => import('./app/pages/vehicle/models-create.page.js')),
  VehicleModelsUpdatePage: React.lazy(() => import('./app/pages/vehicle/models-update.page.js')),
  VehicleModelsPage: React.lazy(() => import('./app/pages/vehicle/models.page.js')),
  VehicleOffersCreatePage: React.lazy(() => import('./app/pages/vehicle/offers-create.page.js')),
  VehicleOffersUpdatePage: React.lazy(() => import('./app/pages/vehicle/offers-update.page.js')),
  VehicleOffersPage: React.lazy(() => import('./app/pages/vehicle/offers.page.js')),
  VehicleSegmentsCreatePage: React.lazy(() => import('./app/pages/vehicle/segments-create.page.js')),
  VehicleSegmentsUpdatePage: React.lazy(() => import('./app/pages/vehicle/segments-update.page.js')),
  VehicleSegmentsPage: React.lazy(() => import('./app/pages/vehicle/segments.page.js'))
};
