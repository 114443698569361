import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';

import { Views } from './api';
import ErrorHandler from './error-handler';

const GlobalContext = createContext({});

export const GlobalContextProvider = ({ children }) => {
  const [sidebarViews, setSidebarViews] = useState({
    isOpen: false,
    isSidebarRendered: false,
    viewsList: [],
    isLoading: false,
  });

  const loadDataSidebarViews = () => {
    setSidebarViews((prevSidebarViews) => ({
      ...prevSidebarViews,
      isLoading: true,
    }));

    Views.getCurrentUserViews()
      .then((res) => {
        setSidebarViews((prevSidebarViews) => ({
          ...prevSidebarViews,
          isLoading: false,
          viewsList: res.items,
        }));
        return undefined;
      })
      .catch((err) => ErrorHandler(err));
  };

  return (
    <GlobalContext.Provider
      value={{
        sidebarViews: {
          ...sidebarViews,
          loadData: loadDataSidebarViews,
        },
        setSidebarViews,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

GlobalContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

GlobalContextProvider.defaultProps = {
  children: {},
};

export default GlobalContext;
