import { ApiCore } from '../_utilities/core';

const currencyRates = new ApiCore({
  url: '/finance/currency-rates',
});

const currencyRate = new ApiCore({
  url: '/finance/currency-rates/:id',
});

export default {
  getCurrencyRates: (queryString) =>
    currencyRates.query({ queryString }).read(),
  getCurrencyRate: (id) => currencyRate.query({ urlParams: { id } }).read(),
  createCurrencyRate: (data) => currencyRates.create(data),
  updateCurrencyRate: (id, data) =>
    currencyRate.query({ urlParams: { id } }).update(data),
  deleteCurrencyRate: (id) =>
    currencyRate.query({ urlParams: { id } }).delete(),
  getCurrencies: (queryString) =>
    new ApiCore({
      url: '/finance/currencies/items',
    })
      .query({ queryString })
      .read(),
};
