export default (theme) => ({
  root: {
    height: 60,
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  open: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(4),
    justifyContent: 'space-between',
  },
  appName: {
    paddingLeft: theme.spacing(5),
    display: 'inline-flex',
    flex: 1,
    fontSize: '1rem',
    lineHeight: 1.5,
  },
});
