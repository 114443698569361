import { ApiCore } from '../_utilities/core';

const depreciations = new ApiCore({
  url: '/vehicle/depreciations',
});

const depreciation = new ApiCore({
  url: '/vehicle/depreciations/:id',
});

const depreciationsNames = new ApiCore({
  url: '/vehicle/depreciations/names',
});

const depreciationsByModel = new ApiCore({
  url: '/vehicle/depreciations/items/:modelCode',
});

export default {
  getDepreciations: (queryString) =>
    depreciations.query({ queryString }).read(),
  getDepreciation: (id) => depreciation.query({ urlParams: { id } }).read(),
  createDepreciation: (data) => depreciations.create(data),
  updateDepreciation: (id, data) =>
    depreciation.query({ urlParams: { id } }).update(data),
  deleteDepreciation: (id) =>
    depreciation.query({ urlParams: { id } }).delete(),
  getNames: (queryString) => depreciationsNames.query({ queryString }).read(),
  getDepreciationsByModel: (modelCode) =>
    depreciationsByModel.query({ urlParams: { modelCode } }).read(),
};
