export const INSERT_TABLE = 'INSERT_TABLE';
export const insertTable = (tableName) => (dispatch) =>
  dispatch({ type: INSERT_TABLE, payload: { tableName } });

export const HANDLE_ROW_IN_TABLE = 'HANDLE_ROW_IN_TABLE';
export const handleRowInTable = (tableName, selectedRow) => (dispatch) =>
  dispatch({ type: HANDLE_ROW_IN_TABLE, payload: { tableName, selectedRow } });

export const INSERT_ROW_TO_TABLE = 'INSERT_ROW_TO_TABLE';
export const insertRowToTable = (tableName, selectedRow) => (dispatch) =>
  dispatch({ type: INSERT_ROW_TO_TABLE, payload: { tableName, selectedRow } });

export const REMOVE_ROW_FROM_TABLE = 'REMOVE_ROW_FROM_TABLE';
export const removeRowFromTable = (tableName, selectedRow) => (dispatch) =>
  dispatch({
    type: REMOVE_ROW_FROM_TABLE,
    payload: { tableName, selectedRow },
  });

export const HANDLE_ALL_ROWS_IN_TABLE = 'HANDLE_ALL_ROWS_IN_TABLE';
export const handleAllRowsInTable = (tableName, selectedRows) => (dispatch) =>
  dispatch({
    type: HANDLE_ALL_ROWS_IN_TABLE,
    payload: { tableName, selectedRows },
  });

export const INSERT_ALL_ROWS_TO_TABLE = 'INSERT_ALL_ROWS_TO_TABLE';
export const insertAllRowsToTable = (tableName, selectedRows) => (dispatch) =>
  dispatch({
    type: INSERT_ALL_ROWS_TO_TABLE,
    payload: { tableName, selectedRows },
  });

export const REMOVE_ALL_ROWS_FROM_TABLE = 'REMOVE_ALL_ROWS_FROM_TABLE';
export const removeAllRowsFromTable = (tableName, selectedRows) => (dispatch) =>
  dispatch({
    type: REMOVE_ALL_ROWS_FROM_TABLE,
    payload: { tableName, selectedRows },
  });
