import { ApiCore } from '../_utilities/core';

const takenVehicles = new ApiCore({
  url: '/report/taken-vehicles',
});

const takenVehiclesExport = new ApiCore({
  url: '/report/taken-vehicles/export',
});

const takenVehiclesSummary = new ApiCore({
  url: '/report/taken-vehicles/summary',
});

export default {
  getTakenVehicles: (queryString) =>
    takenVehicles.query({ queryString }).read(),
  getTakenVehiclesExport: (queryString) =>
    takenVehiclesExport.query({ queryString }).read(),
  getTakenVehiclesSummary: (queryString) =>
    takenVehiclesSummary.query({ queryString }).read(),
};
