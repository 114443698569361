import { ApiCore } from '../_utilities/core';

const commonContents = new ApiCore({
  url: '/vehicle/common-contents',
});

const commonContent = new ApiCore({
  url: '/vehicle/common-contents/:id',
});

export default {
  getCommonContents: (queryString) =>
    commonContents.query({ queryString }).read(),
  getCommonContent: (id) => commonContent.query({ urlParams: { id } }).read(),
  createCommonContent: (data) => commonContents.create(data),
  updateCommonContent: (id, data) =>
    commonContent.query({ urlParams: { id } }).update(data),
  deleteCommonContent: (id) =>
    commonContent.query({ urlParams: { id } }).delete(),
};
