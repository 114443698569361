import { IconButton, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Cancel, Menu } from 'app/components/icons';
import { REACT_APP_VERSION } from 'app/core/constants';
import cx from 'classnames';
import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './sidebar-toolbar.jss';

function SidebarToolbar({
  classes,
  handleDrawerClose,
  handleDrawerOpen,
  open,
}) {
  const { t } = useTranslation();
  return (
    <div className={cx(classes.root, { [classes.open]: open })}>
      {open ? (
        <>
          <Typography
            className={cx(classes.name)}
            component='span'
            variant='h5'
          >
            {process.env.REACT_APP_ENV === 'qa_aws' ? (
              <span>VLM</span>
            ) : (
              <span>{t('app_name')}</span>
            )}
            <span
              style={{
                fontSize: 'xx-small',
                paddingLeft: 10,
              }}
            >
              (v{REACT_APP_VERSION})
            </span>
          </Typography>
          <IconButton onClick={handleDrawerClose} size='large'>
            <Cancel />
          </IconButton>
        </>
      ) : (
        <IconButton onClick={handleDrawerOpen} size='large'>
          <Menu />
        </IconButton>
      )}
    </div>
  );
}

SidebarToolbar.propTypes = {
  classes: PropTypes.object,
  handleDrawerClose: PropTypes.func.isRequired,
  handleDrawerOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

SidebarToolbar.defaultProps = {
  classes: {},
};

export default flow(withStyles(styles))(SidebarToolbar);
