import { ApiCore } from '../_utilities/core';

const statementBuybackeds = new ApiCore({
  url: '/registration/buybacked-statements',
});

const statementBuybackedGrouped = new ApiCore({
  url: '/registration/buybacked-statements/grouped',
});

const statementBuybackedPdf = new ApiCore({
  url: '/registration/buybacked-statements/pdf',
});

export default {
  getStatementBuybackeds: (queryString) =>
    statementBuybackeds.query({ queryString }).read(),
  statementBuybackedGrouped: (data) => statementBuybackedGrouped.update(data),
  createBuybackedPdf: (data) => statementBuybackedPdf.create(data),
};
