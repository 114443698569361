import { ApiCore } from '../_utilities/core';

const parameters = new ApiCore({
  url: '/expertise/parameters',
});

const parameter = new ApiCore({
  url: '/expertise/parameters/:id',
});

const types = new ApiCore({
  url: '/expertise/parameters/types',
});

const items = new ApiCore({
  url: '/expertise/parameters/items/:type',
});

export default {
  getParameters: (queryString) => parameters.query({ queryString }).read(),
  getParameter: (id) => parameter.query({ urlParams: { id } }).read(),
  createParameter: (data) => parameters.create(data),
  updateParameter: (id, data) =>
    parameter.query({ urlParams: { id } }).update(data),
  deleteParameter: (id) => parameter.query({ urlParams: { id } }).delete(),
  getParameterTypes: () => types.query().read(),
  getParameterItemsByType: (type) =>
    items.query({ urlParams: { type } }).read(),
};
