import camelCase from 'lodash/camelCase';

import { ApiCore } from '../_utilities/core';

const tables = new ApiCore({
  url: '/:namespace/table-settings/tables',
});

const column = new ApiCore({
  url: '/:namespace/table-settings/:tableName/columns/:columnName',
});

const status = new ApiCore({
  url: '/:namespace/:tableName/status',
});

const sidebarInfo = new ApiCore({
  url: '/:namespace/:module/:id',
});

export default {
  getTables: (namespace) => tables.query({ urlParams: { namespace } }).read(),
  getTable: (namespace, moduleName) =>
    new ApiCore({
      url: '/:namespace/table-settings/:tableName',
    })
      .query({ urlParams: { namespace, tableName: camelCase(moduleName) } })
      .read(),
  updateTableColumns: (namespace, moduleName, data) =>
    new ApiCore({
      url: '/:namespace/table-settings/:tableName/columns',
    })
      .query({ urlParams: { namespace, tableName: camelCase(moduleName) } })
      .update(data),
  updateTableFilters: (namespace, moduleName, data) =>
    new ApiCore({
      url: '/:namespace/table-settings/:tableName/filters',
    })
      .query({ urlParams: { namespace, tableName: camelCase(moduleName) } })
      .update(data),
  getColumn: (namespace, tableName, columnName, queryString) =>
    column
      .query({
        urlParams: {
          namespace,
          tableName: camelCase(tableName),
          columnName,
        },
        queryString,
      })
      .read(),
  updateRowStatus: (namespace, tableName, queryString, data) =>
    status
      .query({
        urlParams: {
          namespace,
          tableName,
        },
        queryString,
      })
      .update(data),
  getSidebarInfo: (namespace, module, id) =>
    sidebarInfo
      .query({
        urlParams: {
          namespace,
          module,
          id,
        },
      })
      .read(),
};
