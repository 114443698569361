import * as React from 'react';
function SvgCancel(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M6 17.252l5.764-5.764L6 5.724 6.724 5l5.764 5.764L18.252 5l.724.7-5.764 5.788L19 17.276l-.723.724-5.79-5.789-5.763 5.765z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgCancel;
