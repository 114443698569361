const ICON_SIZE = 48;

export default ({ palette }) => ({
  root: {
    width: '100%',
  },
  item: {
    padding: 6,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  active: {
    '&>div': {
      backgroundColor: palette.common.white,
    },
  },
  inset: {
    paddingLeft: 20,
  },
  button: {
    height: 48,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    '&:hover': {
      backgroundColor: palette.common.white,
    },
  },
  icon: {
    display: 'flex',
    width: ICON_SIZE,
    height: ICON_SIZE,
    justifyContent: 'center',
    alignItems: 'center',
  },
  acronym: {
    borderRadius: '50%',
    color: palette.common.black,
    backgroundColor: palette.common.white,
    fontFamily: 'Read-Bold',
    fontSize: '.875rem',
  },
  popover: {
    boxShadow: `0px -2px 15px 5px ${palette.gray[200]}`,
  },
  popoverList: {
    paddingRight: 6,
  },
  navItem: {
    '&:first-child': {
      borderTop: 0,
    },
  },
  expanded: {
    borderTop: `1px solid ${palette.gray[100]}`,
    borderBottom: `1px solid ${palette.gray[100]}`,
  },
});
