import * as React from 'react';
function SvgCalendar(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M15.8 16.012c0 .528-.216.769-.77.769-.528 0-.769-.24-.769-.77 0-.552.24-.768.77-.768.553 0 .77.216.77.769zm0-2.98c0 .53-.216.77-.77.77-.528 0-.769-.24-.769-.77 0-.552.24-.768.77-.768.553 0 .77.216.77.769zm-3.03 2.98c0 .528-.217.769-.77.769-.53 0-.77-.24-.77-.77 0-.552.24-.768.77-.768.553 0 .77.216.77.769zm0-2.98c0 .53-.217.77-.77.77-.53 0-.77-.24-.77-.77 0-.552.24-.768.77-.768.553 0 .77.216.77.769zm-3.031 2.98c0 .528-.217.769-.77.769-.529 0-.77-.24-.77-.77 0-.552.241-.768.77-.768.553 0 .77.216.77.769zm0-2.98c0 .53-.217.77-.77.77-.529 0-.77-.24-.77-.77 0-.552.241-.768.77-.768.553 0 .77.216.77.769zM17.136 19c.625 0 .864-.263.864-.886V10H6v8.114c0 .623.24.886.864.886h10.272zM6.864 7C6.24 7 6 7.256 6 7.86V9h12V7.86c0-.604-.24-.86-.864-.86H6.864zM19 18.294C19 19.447 18.446 20 17.292 20H6.708C5.553 20 5 19.447 5 18.294V7.7c0-1.154.553-1.706 1.708-1.706L8 6V4h1v2h6V4h1v2l1.292-.006C18.446 5.994 19 6.546 19 7.7v10.594z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgCalendar;
