import { ApiCore } from '../_utilities/core';

const salesActions = new ApiCore({
  url: '/offer/sales-actions',
});

const salesAction = new ApiCore({
  url: '/offer/sales-actions/:id',
});

const recipients = new ApiCore({
  url: '/offer/sales-actions/recipients',
});

const availableVehicles = new ApiCore({
  url: '/offer/sales-actions/available-vehicles',
});

const availableVehiclesSummary = new ApiCore({
  url: '/offer/sales-actions/available-vehicles/summary',
});

const reservationAccept = new ApiCore({
  url: '/offer/sales-actions/:id/vehicle-packages/:vehiclePackageId/reservation/accept',
});

const vehiclePackage = new ApiCore({
  url: '/offer/sales-actions/:id/vehicle-packages/:vehiclePackageId',
});

export default {
  getSalesActions: (queryString) => salesActions.query({ queryString }).read(),
  getSalesAction: (id) => salesAction.query({ urlParams: { id } }).read(),
  createSalesAction: (data) => salesActions.create(data),
  updateSalesAction: (id, data) =>
    salesAction.query({ urlParams: { id } }).update(data),
  deleteSalesAction: (id) => salesAction.query({ urlParams: { id } }).delete(),
  getRecipients: () => recipients.read(),
  getAvailableVehicles: () => availableVehicles.read(),
  getAvailableVehiclesSummary: () => availableVehiclesSummary.read(),
  createReservationAccept: (id, vehiclePackageId, data) =>
    reservationAccept
      .query({ urlParams: { id, vehiclePackageId } })
      .create(data),
  getVehiclePackage: (id, vehiclePackageId) =>
    vehiclePackage.query({ urlParams: { id, vehiclePackageId } }).read(),
};
