import { ApiCore } from '../_utilities/core';

const designedVehicles = new ApiCore({
  url: '/vehicle/designed-vehicles',
});

const designedVehicle = new ApiCore({
  url: '/vehicle/designed-vehicles/:id',
});

const types = new ApiCore({
  url: '/vehicle/designed-vehicles/types',
});

const items = new ApiCore({
  url: '/vehicle/designed-vehicles/items',
});

export default {
  getDesignedVehicles: (queryString) =>
    designedVehicles.query({ queryString }).read(),
  getDesignedVehicle: (id) =>
    designedVehicle.query({ urlParams: { id } }).read(),
  createDesignedVehicle: (data) => designedVehicles.create(data),
  updateDesignedVehicle: (id, data) =>
    designedVehicle.query({ urlParams: { id } }).update(data),
  deleteDesignedVehicle: (queryString) =>
    designedVehicles.query({ queryString }).delete(),
  getTypes: () => types.read(),
  getItems: () => items.read(),
};
