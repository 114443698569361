/* eslint-disable func-names */
import filter from 'lodash/filter';
import flatMap from 'lodash/flatMap';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import join from 'lodash/join';
import map from 'lodash/map';
import split from 'lodash/split';
import toNumber from 'lodash/toNumber';
import toString from 'lodash/toString';
import uniqWith from 'lodash/uniqWith';
import * as Yup from 'yup';

Yup.addMethod(Yup.number, 'maxDigits', function (max, message) {
  return this.test({
    message,
    name: 'maxDigits',
    params: { max },
    test: (value) =>
      new Promise((resolve) => {
        setTimeout(() => {
          const [before_, after] = split(toString(value), '.');
          return resolve(isNil(after) || after?.length <= max);
        });
      }),
  });
});

/**
 * doValdte - true to execute this validation
 */
Yup.addMethod(Yup.string, 'minStringNumber', function (min, doValdte, message) {
  return this.test({
    message,
    name: 'minStringNumber',
    params: { min },
    test: (value) =>
      new Promise((resolve) => {
        setTimeout(() => {
          if (!doValdte) return resolve(true);
          if (isEmpty(value)) return resolve(false);
          return resolve(min <= toNumber(value));
        });
      }),
  });
});

Yup.addMethod(Yup.array, 'diffrentCentersValues', function (message) {
  return this.test(`centers`, message, function (value) {
    const { path, createError } = this;
    const centers = flatMap(value, 'centers');
    const groupped = groupBy(centers, 'name');
    const result = uniqWith(
      flatten(filter(groupped, (n) => n.length > 1)),
      isEqual
    );
    const msg = join(map(result, 'name'), ', ');

    return (
      isEmpty(result) ||
      createError({
        path,
        message,
        params: { centers: msg },
      })
    );
  });
});
