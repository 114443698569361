import { ApiCore } from '../_utilities/core';

const registrationDataImport = new ApiCore({
  url: '/interop/registration-data-imports/:id',
});
const registrationDataImportItems = new ApiCore({
  url: '/interop/registration-data-imports/:importId/file',
});
const startRegistrationDataImport = new ApiCore({
  url: '/interop/registration-data-imports/:id/start',
});
const getRegistrationDataImportItem = new ApiCore({
  url: '/interop/registration-data-imports/:importId/file', // /:id',
  responseType: 'blob',
});

export default {
  getRegistrationDataImports: (queryString) =>
    new ApiCore({
      url: '/interop/registration-data-imports',
    })
      .query({ queryString })
      .read(),
  createRegistrationDataImports: () =>
    new ApiCore({
      url: '/interop/registration-data-imports',
    }).create(),
  deleteRegistrationDataImports: (queryString) =>
    new ApiCore({
      url: '/interop/registration-data-imports',
    })
      .query({ queryString })
      .delete(),
  getRegistrationDataImport: (id) =>
    registrationDataImport.query({ urlParams: { id } }).read(),
  uploadItem: (importId, data) =>
    registrationDataImportItems.query({ urlParams: { importId } }).create(data),
  // deleteItem: (importId, id) =>
  //   new ApiCore({
  //     url: '/interop/registration-data-imports/:importId/items/:id',
  //   })
  //     .query({ urlParams: { importId, id } })
  //     .delete(),
  updateItem: (importId, id, data) =>
    new ApiCore({
      url: '/interop/registration-data-imports/:importId/items/:id',
    })
      .query({ urlParams: { importId, id } })
      .update(data),
  startRegistrationDataImport: (id) =>
    startRegistrationDataImport.query({ urlParams: { id } }).create(),
  getItem: (importId, id) =>
    getRegistrationDataImportItem.query({ urlParams: { importId, id } }).read(),
};
