import { ApiCore } from '../_utilities/core';

const paymentMethods = new ApiCore({
  url: '/finance/payment-methods',
});

// const paymentMethodsItems = new ApiCore({
//   url: '/finance/payment-methods/items',
// });

const paymentMethod = new ApiCore({
  url: '/finance/payment-methods/:id',
});

export default {
  getPaymentMethods: (queryString) =>
    paymentMethods.query({ queryString }).read(),
  getPaymentMethod: (id) => paymentMethod.query({ urlParams: { id } }).read(),
  createPaymentMethod: (data) => paymentMethods.create(data),
  updatePaymentMethod: (id, data) =>
    paymentMethod.query({ urlParams: { id } }).update(data),
  deletePaymentMethod: (id) =>
    paymentMethod.query({ urlParams: { id } }).delete(),
  getPaymentMethodsItems: () =>
    new ApiCore({
      url: '/finance/payment-methods/items',
    })
      .query()
      .read(),
};
