import * as React from 'react';
function SvgImport(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M14 16h2c1.775 0 3-.895 3-2.24 0-1.775-1.08-2.785-2.956-2.735-.19-2-1.39-3.024-3.644-3.024-2.065 0-3.36 1.464-3.5 3.89-1.8-.12-2.93.694-2.93 2.11 0 1.2 1.055 2 2.57 2L11 16v1H8.555C6.49 17 5 15.755 5 14c0-1.655 1.25-2.88 3.05-3a4.28 4.28 0 014.345-4c2.564 0 4.2 1.156 4.534 3.22 1.895.145 3.071 1.5 3.071 3.525S18.415 17 16 17h-2v-1zm-1-4.5V16h-1v-4.5c-.36.5-.64.845-1.215 1.76l-.79-.46c.618-.971 1.3-1.917 2.04-2.8h.934c.74.883 1.422 1.829 2.04 2.8l-.79.46c-.6-.96-.834-1.235-1.219-1.76z'
        fill='#1D1D1B'
        fillRule='nonzero'
      />
    </svg>
  );
}
export default SvgImport;
