import * as React from 'react';
function SvgBox(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M20 5v11.4L17 19H4V8l4.2-3H20zm-9 7h-1V9H5v9h11V9h-5v3zm8-4.96l-2 1.6v9.036l2-1.733V7.04zM13.096 6H8.519L5.718 8h4.619l2.759-2zm5.602 0H14.79l.003.005L12.04 8h4.159l2.5-2z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgBox;
