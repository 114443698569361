export default {
  fontFamily: 'Read',
  h1: {
    fontFamily: 'RenaultLife-Bold',
    fontSize: '2.25rem',
    lineHeight: 1.25,
    letterSpacing: 0,
  },
  h2: {
    fontFamily: 'RenaultLife-Bold',
    fontSize: '1.5rem',
    lineHeight: 1.25,
    letterSpacing: 0,
  },
  h3: {
    fontFamily: 'Read-Bold',
    fontSize: '1.25rem',
    lineHeight: 1.25,
    letterSpacing: 0,
  },
  h4: {
    fontFamily: 'RenaultLife-Bold',
    fontSize: '1rem',
    lineHeight: 1.25,
    letterSpacing: 0,
  },
  h5: {
    fontFamily: 'RenaultLife-Bold',
    fontSize: '.875rem',
    lineHeight: 1.25,
    letterSpacing: 0,
  },
  h6: {
    fontFamily: 'RenaultLife-Bold',
    fontSize: '.7rem',
    lineHeight: 1.25,
    letterSpacing: 0,
  },
  // subtitle1
  // subtitle2
  body1: {
    fontFamily: 'Read',
    fontSize: '.875rem',
    lineHeight: 1.43,
    letterSpacing: 0,
  },
  body2: {
    fontFamily: 'Read',
    fontSize: '.8125rem',
    lineHeight: 1.38,
    letterSpacing: 0,
  },
  button: {
    fontFamily: 'Read-Bold',
    fontSize: '.875rem',
    lineHeight: 1.43,
    letterSpacing: 0,
  },
  caption: {
    fontFamily: 'Read',
    fontSize: '.725rem',
    lineHeight: 1.36,
    letterSpacing: 0,
  },
  // overline
};
